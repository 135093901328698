import React, { useEffect, useState } from 'react'
import Carousel from "react-multi-carousel";
import EventCard from '../component/Tableplan/EventCard';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Loading from '../component/Loading';
import { eventPageLimit } from '../constants/DataPublic';
import { useToast } from '../component/Helpers/ToastContext';
import { convertDateToISO, delay, getCartSession, getErrorMessage, removeSession, resetBookingSesstion, setDateSession, setEventStateSession } from '../component/Helpers/BaseHelper';
import InfiniteScroll from 'react-infinite-scroll-component';
// import ScanVoucher from '../component/img/scan-voucher.svg';
import { useTranslation } from 'react-i18next';
import SelectZoneBar from '../component/SelectZoneBar';
import { UnBlocktable, getEventAll, getEventDate } from '../Services/TableBookService';
import { BlockTableModel } from '../Models/TableBookModel';
import BeachBanner1 from '../component/img/Banner-1.png';
import BeachBanner2 from '../component/img/Banner-2.png';
import BeachBanner3 from '../component/img/Banner-3.png';
import AlertModal from '../component/modal/AlertModal';
import SorasoRefresh from '../component/img/soraso_refresh_79.svg';

const EventCalendar = (props) => {
    const { language, setnavActiveIndex } = props;
    const navigate = useNavigate();
    const { showToast } = useToast();
    const { t, i18n } = useTranslation();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const [eventItems, setEventItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paginationIndex, setPaginationIndex] = useState(0);
    const [pageInfo, setPageInfo] = useState();
    const [eventDateArray, setEventDateArray] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [openBottomPopup, setOpenBottomPopup] = useState({
        date: false,
    });

    const [pageNumber, setPageNumber] = useState(2);

    //scan
    const [openScanModal, setOpenScanModal] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertCallback, setAlertCallback] = useState({});
    const [alertIcon, setAlertIcon] = useState(SorasoRefresh);
    const [alertMessage, setAlertMessage] = useState(t('expire_reservation'));
    const [showCancle, setShowCancle] = useState(true);

    const openConfirmationModal = () => {
        return new Promise((resolve) => {
            setShowAlert(true);

            const handleConfirm = () => {
                resolve(true); // User confirmed the change
            };

            const handleCancel = () => {
                resolve(false); // User canceled the change
            };

            // Set the ConfirmationModal callbacks
            setAlertCallback({ confirm: handleConfirm, cancel: handleCancel });
        });
    };

    const onShowScanModal = () => {
        setOpenScanModal(true);
    }

    const onCloseScanModal = () => {
        setOpenScanModal(false);
    }

    const onSetDate = (date) => {
        date.setHours(7);
        setStartDate(date);
        setPageNumber(2);
        //getEvent();
    }

    useEffect(() => {
        setnavActiveIndex(2);
        getEventCalendar();
        getEvent();
    }, [])

    useEffect(() => {
        if (language !== null) {
            setPageNumber(2);
            getEvent();
        }
    }, [language])

    useEffect(() => {
        if (startDate !== undefined && startDate !== null) {
            getEvent();
        }
    }, [startDate])


    const getEventMore = async () => {
        if (pageInfo) {
            if (pageInfo.TotalPages !== null) {
                if (pageNumber <= pageInfo.TotalPages) {
                    setLoading(true);
                } else {
                    setLoading(false);
                }
            }
            let date = (startDate == null ? "" : convertDateToISO(startDate));
            let params = {
                Keyword: "",
                PageNumber: pageNumber,
                PageLimit: eventPageLimit,
                ArrivalDate: date,
                RequestId: "",
            };
            await delay(1000);
            if (pageNumber <= pageInfo.TotalPages) {
                await getEventAll(params, language ?? 'th')
                    .then(async (response) => {
                        const data = response.Data;

                        if (response.Status === "Ok") {
                            setPageInfo(response.PageInfo);
                            setEventItems(pervEventItem => [...pervEventItem, ...data]);
                            setPageNumber(pageNumber + 1);
                            setLoading(false);
                            return;
                        } else {
                            throw (getErrorMessage(response.Errors));
                        }

                    }).catch((error) => {
                        setLoading(false);
                        console.log(error);
                        showToast(error.message, 'error');
                    });
            }
        }
    }

    const getEvent = async () => {
        console.log("onSetDate", startDate)
        let date = (startDate == null ? "" : convertDateToISO(startDate));
        setLoading(true);
        let params = {
            Keyword: "",
            PageNumber: 1,
            PageLimit: eventPageLimit,
            ArrivalDate: date,
            RequestId: "",
        };
        await delay(1000);

        await getEventAll(params, language ?? 'th')
            .then(async (response) => {
                const data = response.Data;
                if (response.Status === "Ok") {
                    setPageInfo(response.PageInfo);
                    setEventItems(data);
                    // setPageNumber(pageNumber + 1);
                    setLoading(false);
                    return;
                } else {
                    throw (getErrorMessage(response.Errors));
                }
            }).catch((error) => {
                setLoading(false);
                console.log("getEventAll", error);
                showToast(error.message, 'error');
            });
    }

    const onEventClick = async (event) => {
        let myCart = getCartSession();
        if (myCart !== null && myCart.length > 0) {
            console.log('openConfirmationModal');
            await openConfirmationModal()
                .then(async (confirmed) => {
                    if (confirmed) {
                        let date = event.EventDate.split('T')[0];
                        await CallUnblockAllTable();
                        setDateSession(date);
                        resetBookingSesstion();
                        removeSession('CurrentEvent');
                        removeSession('EventTime');
                        removeSession('MyCart');
                        setEventStateSession(event);
                        setShowAlert(false)
                    }
                })
                .catch((error) => {
                    // Handle errors or user cancelation
                    setShowAlert(false);
                });
        } else {
            let date = event.EventDate.split('T')[0];
            setDateSession(date);
            removeSession('CurrentEvent');
            await CallUnblockAllTable();
            setEventStateSession(event);
            navigate("/");
        }
    }

    const onPageClick = (index) => {
        if (index < pageInfo.TotalPages && index > -1) {
            setPaginationIndex(index);
        }
    }

    const buildPageIndex = () => {
        var page = []
        for (let i = 0; i < pageInfo.TotalPages; i++) {
            page.push(<li className="page-item" key={i}><div className={paginationIndex === i ? "page-link page-active" : "page-link"} onClick={() => { onPageClick(i) }}>{i + 1}</div></li>)
        }
        return page;
    }

    const getEventCalendar = async () => {
        setLoading(true);
        await delay(1000);
        try {
            await getEventDate('en')
                .then(data => {
                    // Handle the response data
                    const date = data.Data.map((item) => new Date(item.EventDate));
                    setEventDateArray(date);
                    setLoading(false);
                })
                .catch(error => {
                    // Handle errors
                    console.error('Error from GetEventDate:', error);
                    throw error;
                });

        } catch (error) {
            setLoading(false);
            console.log(error);
            showToast(error.message, 'error');
        }
    }

    const CustomLoader = () => {
        setLoading(true);

    };

    async function CallUnblockAllTable() {
        let myCart = getCartSession();
        if (myCart && myCart.length > 0) {
            setLoading(true);
            const payload = new BlockTableModel(
                myCart.map((e) => {
                    return { TableNo: e.TableNo }
                })
            )
            await delay(1000);
            try {
                await UnBlocktable(payload, language ?? 'EN')
                    .then((response) => {
                        if (response.Success) {
                            const data = response.Result;
                            resetBookingSesstion();
                            removeSession('MyCart');
                            removeSession('CurrentEvent');
                            console.log(data);
                            delay(1500);
                        } else {
                            throw (response.Result.Message);
                        }
                    }).catch((error) => {
                        console.log(error);
                        throw error;
                    })
                setLoading(false);
                navigate("/");
            } catch (error) {
                console.log(error);
                return null;
            }
        }
    }


    return (
        <InfiniteScroll
            dataLength={eventItems.length}
            next={getEventMore}
            hasMore={true}
        >
            <div className='page page-event'>
                <div className='event-container'>
                    <div className='tableplan' style={{ height: 'fit-content' }}>
                        <div className='tableplan-header'>
                            <SelectZoneBar
                                isEventPage={true}
                                isMobile={isTabletOrMobile}
                                eventDateArray={eventDateArray}
                                startDate={startDate}
                                setStartDate={onSetDate}

                                datePickerMobileSize={'30vw'}
                                zoneMobileSize={'30vw'}
                                eventMobile={'30vw'}
                                scanMobileH={isTabletOrMobile ? 25 : 30}
                                openScanModal={onShowScanModal}
                                showModal={openScanModal}
                                onCloseModal={onCloseScanModal}
                                setOpenBottomPopupDate={() => setOpenBottomPopup({ date: true })}
                                onRefreshPlan={(date, event) => {
                                    setEventStateSession(event);
                                    navigate("/");
                                }}
                                language={language}
                            />
                        </div>
                        <div className='tableplan-header'>
                            <Carousel
                                additionalTransfrom={0}
                                autoPlay
                                arrows={false}
                                autoPlaySpeed={5000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={false}
                                focusOnSelect={false}
                                infinite={true}
                                itemClass=""
                                minimumTouchDrag={80}
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                rewind
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 1024
                                        },
                                        items: 1,
                                        partialVisibilityGutter: 40
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 464,
                                            min: 0
                                        },
                                        items: 1,
                                        partialVisibilityGutter: 30
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 1024,
                                            min: 464
                                        },
                                        items: 1,
                                        partialVisibilityGutter: 30
                                    }
                                }}
                            >
                                <img src={BeachBanner1} className='event-container banner-img' />
                                <img src={BeachBanner2} className='event-container banner-img' />
                                <img src={BeachBanner3} className='event-container banner-img' />
                            </Carousel>
                        </div>

                        <div className='event-container center'>
                            <p style={{ fontSize: isTabletOrMobile ? '14px' : '40px', fontWeight: 'bold' }}>{t('event_beach')}</p>
                            <p style={{ fontSize: isTabletOrMobile ? '12px' : '20px' }}>{t('event_beach_desc')}</p>
                        </div>
                        <div className='event-container d-flex justify-content-center'>
                            {eventItems && eventItems.length > 0 &&
                                <div className='row w-100'>
                                    {eventItems.map((item, index) => {
                                        return (
                                            <div className='event-content col-lg-3 col-md-6 col-sm-12'
                                                style={{
                                                    padding: isTabletOrMobile ? '10px' : '',
                                                    marginBottom: !isTabletOrMobile ? '3em' : '0.5rem'
                                                }} key={index}>
                                                <EventCard
                                                    event={item}
                                                    onEventClick={onEventClick}
                                                />
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Loading loading={loading} />
            </div >

            {showAlert && (
                <AlertModal
                    openModal={showAlert}
                    onCloseModal={() => setShowAlert(false)}
                    onConfirm={alertCallback.confirm}
                    isShowCancle={true}
                    isShowClose={true}
                >
                    <div className='d-flex flex-column justify-content-around align-items-center' style={{ height: '100%' }}>
                        <div className='mb-3'>
                            <img src={alertIcon} />
                        </div>
                        {alertIcon == SorasoRefresh ? (
                            <>
                                <div className='' style={{ fontWeight: 'bold' }}>
                                    {t('remove_alert_1')}
                                </div>
                                <div className='' style={{ fontWeight: 'bold' }}>
                                    {t('remove_alert_2')}
                                </div>
                            </>
                        ) : (
                            <p className='' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {alertMessage}
                            </p>
                        )}

                    </div>
                </AlertModal>
            )}

        </InfiniteScroll>

    )
}

export default EventCalendar