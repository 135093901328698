import React, { useEffect, useRef, useState } from 'react';
import fail from '../component/img/paymentfail.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
import SorasoButton from '../component/buttons/SorasoButton';

//Component
const PaymentFail = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 990px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' })

    let myCart = sessionStorage.getItem('MyCart')
    myCart = JSON.parse(myCart)

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    let itCrossCam = sessionStorage.getItem('itCrossCampaign');
    itCrossCam = JSON.parse(itCrossCam);


    const { state } = useLocation();
    const { language } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [keepState, setKeepstate] = useState();

    useEffect(() => {
        console.log("Done: ", user);
        console.log("confirmNo: ", state);
        setKeepstate(state)
    }, [])

    const goHome = () => {
        if (itCrossCam) {
            window.location.href = "/"
        } else {
            navigate("/package/" + myCart[0].Package[0].PackageCode + "/4")
        }
        setKeepstate(null)
    }

    return (
        <div className="page homepage" style={{ minHeight: isTabletOrMobile ? 'auto' : 'calc(100vh - 183px)', marginBottom: isTabletOrMobile ? '' : '6em' }}>
            <div className="card mt-5" style={{
                width: isTabletOrMobile ? '80%' : '50%',
                margin: 'auto',
            }}>
                <div className="card-body" >
                    <h2 className="lh-1" style={{
                        width: '100%',
                        height: '100%',
                        fontWeight: '700',
                        fontSize: '18px',
                        color: '#0e315c'
                    }}>
                        {t('smart_finder')}
                    </h2>
                    <h2 className="lh-1" style={{
                        width: '100%',
                        height: '100%',
                        fontSize: '16px',
                        fontWeight: '500',
                    }}>
                        {user.InvoiceNumber}
                    </h2>
                    <h2 className="lh-1" style={{
                        width: '100%',
                        height: '100%',
                        fontWeight: '700',
                        fontSize: '18px',
                        color: '#0e315c'
                    }}>
                        {user.totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                    </h2>
                </div>
            </div>
            <div style={{ height: '60%', display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                <img src={fail} style={{ height: '80%' }}></img>
            </div>
            <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontWeight: '700', fontSize: '35px', width: '70%', textAlign: 'center', color: 'red' }}>{t('transaction_failed')}</h1>
            </div>
            <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1 style={{ fontWeight: '700', fontSize: '20px', width: '70%', textAlign: 'center', color: 'red' }}>{keepState && (
                    <>
                        ({keepState.ResponseDetail})
                    </>
                )}</h1>
            </div>
            <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem' }}>
                <h5 style={{ fontWeight: '500', fontSize: '16px', width: '70%', textAlign: 'center' }}>{t('payment_fail')}</h5>
            </div>
            <div style={{ height: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4rem' }}>
                {/* <button className="btn btn-main btn-done" onClick={goHome}>{t('back')}</button> */}
                <SorasoButton
                    text={t('back')}
                    style='primary'
                    onClick={goHome}
                />
            </div>
        </div>
    );
};

export default PaymentFail;
