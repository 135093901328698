import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';
import Signup from './Signup';
import Signin from './Signin';
import ChangePass from './ChangePass';
import Forget from './Forget';
import Ticket from './Ticket';
import Passcode from './Passcode';
import SetPasscode from './SetPasscode';
import GuestCheckout from './GuestCheckout';
import Done from './Done';
import VisitorInfo from './VisitorInfo';
import Payment from './Payment';
import PaymentOmise from './PaymentOmise';
import NotFound from './NotFoundCampaign';
import PendingPay from './PendingPay';
import PaymentFail from './PaymentFail';
import PaymentProcessing from './PaymentProcessing';
import PaymentTruewallet from './PaymentTruewallet';
import LandingPage from './LandingPage';
import { apiLink } from '../constants/DataPublic';
import { useGlobalState } from '../global';
import { useTranslation } from "react-i18next";
import EventCalendar from './EventCalendar';
import EventDetail from '../component/Tableplan/EventDetail';
import TablePlanPage from './TablePlanPage';
import { getBookingSession, setBookingSession, setDateSession, getDateSession, getCartSession } from '../component/Helpers/BaseHelper';
// import { createGlobalState } from 'react-hooks-global-state';
// const { useGlobalState } = createGlobalState({ count: 0 });

const Home = () => {
  const { t } = useTranslation();

  // Language
  const [language, setLanguage] = useState(null);
  const [routePath, setRoutePath] = useGlobalState('route');
  const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');
  const [disabledPromo, setDisabledPromo] = useGlobalState('disabledPromo');
  const [loadCampaign, setLoadCampaign] = useGlobalState('loadCampaign');
  const [languageGlobal, setLanguageGlobal] = useGlobalState('language');
  const [template, setTemplate] = useState();
  const [result, setResult] = useState();
  const [ggUser, setGGUser] = useState({});
  const [fbUser, setFBUser] = useState({});
  const [userSignup, setUserSignup] = useState({});
  const [cartItems, setCartItems] = useState(0);
  const [userCode, setUserCode] = useState('');
  const [packageSubTypeCode, setPackageSubTypeCode] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [navActiveIndex, setnavActiveIndex] = useState(1);

  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);

  let Promotion = sessionStorage.getItem('Promo');
  Promotion = JSON.parse(Promotion);

  let sesRoute = sessionStorage.getItem('Route');
  sesRoute = JSON.parse(sesRoute);

  let sessionShowModal = sessionStorage.getItem('showModal')
  sessionShowModal = JSON.parse(sessionShowModal)

  const dateSession = getDateSession()

  //Show Popup ---------------
  // useEffect(() => {
  //   console.log("sessionShowModal", sessionShowModal)
  //   setShowModal(!!sessionShowModal ? false : true)
  //   sessionStorage.setItem('showModal', JSON.stringify('showed'));
  // }, []);

  useEffect(() => {
    console.log("Home cartItems", cartItems)
  }, [cartItems])

  useEffect(() => {

    let booking = getBookingSession();
    if (booking === null) {
      booking = { bookingId: 0 }
      setBookingSession(booking);
    }

    if (!dateSession || dateSession === "") {
      setDateSession(new Date());
    }

    // getAllPackage()
    let sestoRoute = ['signin', 'pass', 'forget', 'changepw', 'signup', 'setpw', 'event', 'package', 'guestCheckout',
      'done', 'visitorInfo', 'payment', 'pending', 'paymentfail', 'paymentProcess', 'test', 'table'];
    if (!sestoRoute.includes(window.location.pathname.split('/')[1])) {
      if (sesRoute != window.location.pathname.split('/')[1]) {
        // sessionStorage.setItem('MyCart', JSON.stringify([]));
        sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: '', promoCode: '', havePro: false, packageType: '' }));
        sessionStorage.setItem('Route', JSON.stringify(window.location.pathname.split('/')[1]));
      } else {
        Promotion = Promotion === null ? { discountPromo: '', promoCode: '', havePro: false, packageType: '' } : Promotion;
        sessionStorage.setItem('Promo', JSON.stringify(Promotion));
        sessionStorage.setItem('Route', JSON.stringify(window.location.pathname.split('/')[1]));
      }
    }

    if (myCart != null && myCart.length > 0) {
      myCart = sessionStorage.getItem('MyCart');
      myCart = JSON.parse(myCart);
      let count = getCountCart(myCart);
      setCartItems(count);
      // console.log("getCountCart()", count)
    }

    // console.log("window.location.pathname.split('/')[1]", window.location.pathname.split('/')[1])
    // console.log("window.location.pathname.split('/')[1]", routePath)
  }, []);

  const userLogout = (logout) => {
    if (logout) {
      setGGUser({});
      setFBUser({});
      setUserSignup({});
      setCartItems(0);
    }
  };

  const addToHeader = (headtag) => {
    // if (document.getElementById('googleHeadTag') != null) {
    //   document.getElementById('googleHeadTag').remove();
    // }

    // if (headtag) {
    //   var script = document.createElement('script');
    //   script.id = 'googleHeadTag';
    //   script.text = headtag.replace('</script>', '').replace('<script>', '');
    //   document.head.appendChild(script);
    // } else {
    //   var script = document.createElement('script');
    //   script.id = 'googleBodyTag';
    //   script.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5VKXHTW');"
    //   // script.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5QSB3C5');"
    //   document.head.appendChild(script);
    // }
  };

  const addToBody = (bodytag) => {
    // if (document.getElementById('googleBodyTag') != null) {
    //   document.getElementById('googleBodyTag').remove();
    // }

    // if (bodytag) {
    //   var noscript = document.createElement('noscript');
    //   noscript.id = 'googleBodyTag';
    //   noscript.innerHTML = bodytag.replace('<noscript>', '').replace('</noscript>', '');
    //   document.body.appendChild(noscript);
    // } else {
    //   var noscript = document.createElement('noscript');
    //   noscript.id = 'googleBodyTag';
    //   noscript.text = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-5VKXHTW'height='0' width='0' style='display:none;visibility:hidden'></iframe>"
    //   // noscript.text = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-5QSB3C5'height='0' width='0' style='display:none;visibility:hidden'></iframe>"
    //   document.body.appendChild(noscript);
    // }
  };

  useEffect(() => {
    setDisabledPromo(false)
    let checkRoute = ['', 'signin', 'pass', 'forget', 'changepw', 'signup', 'setpw', 'package', 'guestCheckout', 'done',
      'visitorInfo', 'payment', 'pending', 'paymentfail', 'paymentOmise', 'paymentProcess', 'event', 'table'];
    try {
      if (!checkRoute.includes(window.location.pathname.split('/')[1])) {
        // getCampaign();
        window.location.href = '/';
      } else {
        addToHeader(null);
        addToBody(null);
        setLoadCampaign(true)
      }
    } catch {
      // console.log("addToBody")
    }

    // console.log("routePath", routePath)
    // console.log("addToBody")
  }, []);


  async function getCampaign() {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    setRoutePath('');
    setLoadCampaign(false)
    // /{culture}/api/v1/Campaign/Info
    if (window.location.pathname.split('/')[1] != 'notfound') {
      const res = await fetch(apiLink + '/afcl/Package/CampaignInfo' + '?prefix=' + window.location.pathname.split('/')[1], requestOptions);
      res
        .json()
        .then((res) => {
          if (res['Result']['IsCampaignExpired'] != null) {
            setResult(res['Result']);
            setTemplate(res['Result']['Template']);
            addToHeader(res['Result']['GoogleTagHead']);
            addToBody(res['Result']['GoogleTagBody']);
            if (res['Result']['IsCampaignExpired'] == true) {
              setInactiveCam(true)
            }
            console.log("window.location.pathname.split('/'", window.location.pathname.split('/')[1])
            setRoutePath('/' + window.location.pathname.split('/')[1]);
            // if (window.location.pathname.split('/')[1].toLocaleLowerCase() == '16two') {
            //   setDisabledPromo(true)
            // }
            setLoadCampaign(true)
          } else {
            window.location.href = '/';
            window.location.href = '/notfound';
          }
        })
        .catch((error) => {
          console.log(error);
          // alert('Error: API /afcl/Package/Banner ', error);
        });
    }

  }

  const getGGUser = (ggUser) => {
    console.log('ggUser', ggUser)
    setGGUser(ggUser);
  };
  const getFBUser = (fbUser) => {
    setFBUser(fbUser);
  };

  const getUserSignup = (userSignup) => {
    setUserSignup(userSignup);
  };

  const getLanguage = (lang) => {
    setLanguage(lang);
    setLanguageGlobal(lang);
  };

  const getCartItem = (cartQty) => {
    setCartItems(cartQty);
  };

  const getPackageSubTypeCode = (subTypeCode) => {
    setPackageSubTypeCode(subTypeCode);
  };

  async function getUserCart(userCode, language) {
    if (userCode != '' && userCode != undefined) {
      const requestOptions = {
        method: 'GET',
        headers: { 'accept-language': language },
      };
      await fetch(apiLink + '/afcl/Package/Cart/CheckOut/V2/' + userCode, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          if (items['Success'] === true) {
            // Cart ใหม่
            let myCart = sessionStorage.getItem('MyCart');
            myCart = JSON.parse(myCart);

            // Cart ที่ Save ไว้ก่อนหน้า
            let allCart = items['Result']['Order'];

            let user = sessionStorage.getItem('UserLogin');
            user = JSON.parse(user);
            user.confirmationNo = allCart.ConfirmationNo;
            sessionStorage.setItem('UserLogin', JSON.stringify(user));

            // รวม Cart
            let saveCart = [];
            allCart.Packages.forEach((e) => {
              if (e.ItemCode === '') {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                      return c.PackageCode === e.PackageCode;
                    });
                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: '',
                    ItemName: '',
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: '',
                    TotalPrice: e.Qty * e.PackagePrice,
                    VisitDate: e.VisitDate === null ? '' : e.VisitDate,
                    VisitTime: '',
                    VisitLocation: '',
                    step: 1,
                    status: 'normal',
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              } else {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                      return c.PackageCode === e.PackageCode && c.ItemCode === e.ItemCode;
                    });
                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: e.ItemCode,
                    ItemName: e.ItemName,
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: e.PriceUnit,
                    TotalPrice: parseInt(e.Qty) * parseInt(e.ItemPrice),
                    VisitDate: e.VisitDate,
                    VisitTime: e.VisitTime,
                    VisitLocation: e.VisitLocation,
                    step: 1,
                    status: 'normal',
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              }
            });
            myCart = myCart === null || myCart.length === 0 ? [...saveCart] : [...myCart, ...saveCart];
            // console.log("getCountCart()", getCountCart())
            let count = getCountCart(myCart);
            setCartItems(count);
            sessionStorage.setItem('MyCart', JSON.stringify(myCart));
          } else {
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
        });
    }
  }

  useEffect(() => {
    if (userCode != '') {
      // getUserCart(userCode, language);
    } else setCartItems(0);
  }, [userCode]);


  const getCountCart = () => {
    let count = 0;
    if (myCart !== null || myCart?.length > 0) {
      myCart.forEach((e) => {
        e.Package?.forEach((x) => {
          count += (x.Qty);
        })
      })
      return count
    } else {
      return 0
    }
  }

  return (
    <div>
      {/* "homepage": "/afcl", */}
      {/* <Router basename={'/'}> */}
      <Router basename={'/' + routePath}>
        <Navbar getLanguage={getLanguage} userLogout={userLogout} setUserCode={setUserCode} navActiveIndex={navActiveIndex} setnavActiveIndex={setnavActiveIndex} />
        <Routes>
          <Route path={'/'} element={<LandingPage language={language} getPackageSubTypeCode={getPackageSubTypeCode} getCartItem={getCartItem} setnavActiveIndex={setnavActiveIndex} />} exact />
          <Route path={'/event'} element={<EventCalendar language={language} setnavActiveIndex={setnavActiveIndex} />} />
          {/* <Route path={'/event/details'} element={<EventDetail language={language} />} /> */}
          <Route path='/notfound' element={<NotFound language={language} getPackageSubTypeCode={getPackageSubTypeCode} />} />
          <Route path='/pending' element={<PendingPay language={language} getPackageSubTypeCode={getPackageSubTypeCode} />} />
          <Route path='/paymentfail' element={<PaymentFail language={language} getPackageSubTypeCode={getPackageSubTypeCode} />} />
          <Route path='/paymentProcess' element={<PaymentProcessing language={language} getPackageSubTypeCode={getPackageSubTypeCode} />} />
          <Route path="/signin" element={<Signin language={language} getGGUser={getGGUser} getFBUser={getFBUser} setUserCode={setUserCode} />} />
          <Route path="/pass" element={<Passcode language={language} setUserCode={setUserCode} />} />
          <Route path="/forget" element={<Forget language={language} />} />
          <Route path="/changepw" element={<ChangePass language={language} />} />
          <Route path="/signup" element={<Signup getUserSignup={getUserSignup} language={language} ggUser={ggUser} fbUser={fbUser} />} />
          <Route path="/setpw" element={<SetPasscode userSignup={userSignup} language={language} />} />
          <Route path="/package/:packageCode/:step" element={<Ticket language={language} packageSubTypeCode={packageSubTypeCode} getCartItem={getCartItem} />} />
          <Route path="/guestCheckout" element={<GuestCheckout language={language} getGGUser={getGGUser} getFBUser={getFBUser} setUserCode={setUserCode} />} />
          <Route path="/done" element={<Done language={language} getCartItem={getCartItem} />} />
          <Route path="/visitorInfo" element={<VisitorInfo language={language} getGGUser={getGGUser} getFBUser={getFBUser} getCartItem={getCartItem} setUserCode={setUserCode} />} />
          <Route path="/payment" element={<Payment language={language} getCartItem={getCartItem} />} />
          <Route path="/paymentOmise" element={<PaymentOmise language={language} getCartItem={getCartItem} />} />
          <Route path="/paymentOmise/trueMoney" element={<PaymentTruewallet language={language} getCartItem={getCartItem} />} />
          <Route path="/table" element={<TablePlanPage language={language} getCartItem={getCartItem} />} />
          {/* <Route path="/test/sc" element={<ScrollableContent language={language} />} /> */}

        </Routes>
      </Router>
      {window.location.pathname.split('/')[1].toLocaleLowerCase() === '16two' && window.location.pathname.split('/').length === 2 && template === 'Template5' || window.location.pathname.split('/')[2] === '' && window.location.pathname.split('/')[1].toLocaleLowerCase() === '16two' && template === 'Template5' ? (
        // <Footer />
        <>
        </>
      ) : (
        <Footer path={window.location.pathname.split('/')[1].toLocaleLowerCase()} />
      )}
    </div>
  );
};

export default Home;
