import React from "react";

export default function SorasoButton(props) {
    const { text, onClick, style, mobileW, mobileH, disabled } = props;
    return (
        <div
            className={
                style === "primary"
                    ? `soraso-button ${disabled ? "disabled" : ""}`
                    : `soraso-button-outline ${disabled ? "disabled" : ""}`
            }
            onClick={
                disabled
                    ? null
                    : (e) => {
                        onClick(e);
                    }
            }
            style={{ height: mobileH, width: mobileW ?? 'auto' }}
        >
            <div className="btn" style={{ cursor: disabled ? 'context-menu' : 'pointer' }}>
                <p
                    className="m-auto d-flex justify-content-center align-items-center"
                    style={{
                        height: "100%",
                        fontSize: "inherit",
                        textTransform: "capitalize",
                    }}
                >
                    {text}
                </p>
            </div>
        </div>
    );
}
