import React, { useCallback, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";

//Image
import logo from "../component/img/soraso_logo.png";
import PicSuccess from "../component/img/soraso_refresh_bg_79.svg";
import { ReactComponent as Close } from "../component/img/close.svg";
import { ReactComponent as Passcode } from "../component/img/Passcode.svg";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";

//css
import "../component/css/style.css";
import "../component/css/custom.css";
import { baseLink, apiLink, coreApi } from '../constants/DataPublic';
import { useGlobalState } from '../global'
import { useMediaQuery } from 'react-responsive';
import { GuestChangePasswordModel, GuestRegisterModel } from '../Models/AccountModel';
import { GuestChangePasswordService } from '../Services/AccountService';
import { hashStringWithSHA256 } from '../component/Helpers/EncryptHelper';
import FiledPasswordInput from '../component/input/FieldPasswordInput';
import SorasoSuccessModel from '../component/modal/SorasoSuccessModal';
import Loading from '../component/Loading';
import { patternPassword } from '../component/Helpers/PatternPasswordHelper';
import { removeSession } from '../component/Helpers/BaseHelper';

const ChangePass = (props) => {

    const { language } = props
    const navigate = useNavigate();
    const [routePath, setRoutePath] = useGlobalState('route');
    const [canChange, setCanChange] = useState(false);
    const [activeConfirm, setActiveConfirm] = useState(false);
    const [message, setMessage] = useState("");
    const [reqiureOldPassword, setReqiureOldPassword] = useState("");
    const [reqiureNewPassword, setReqiureNewPassword] = useState("");
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const [loading, setLoading] = useState("");

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    useEffect(() => {
        if (user != null) {
            console.log("Change Passcode");
        } else {
            navigate("/signin")
        }
    }, [])

    function passwordValidation() {
        if (patternPassword(NewpasswordInput)) {
            setReqiureNewPassword(t(''));
            changePassword();
        } else {
            setReqiureNewPassword(t('password_requirements_not_met'));
        }
    }

    const onNumerPWKeyDown = (event) => {
        if (!/[0-9]/.test(event.key) && event.key != 'Backspace') {
            event.preventDefault();
            console.log("event", event)
        }

    };

    const [OldpasswordType, setOldPasswordType] = useState("password");
    const [OldpasswordInput, setOldPasswordInput] = useState("");


    const toggleOldPassword = () => {
        if (OldpasswordType === "password") {
            setOldPasswordType("number")
            return;
        } setOldPasswordType("password")
    }

    const [NewpasswordType, setNewPasswordType] = useState("password");
    const [NewpasswordInput, setNewPasswordInput] = useState("");

    const toggleNewPassword = () => {
        if (NewpasswordType === "password") {
            setNewPasswordType("number")
            return;
        } setNewPasswordType("password")
    }

    const [CFpasswordType, setCFPasswordType] = useState("password");
    const [CFpasswordInput, setCFPasswordInput] = useState("");

    const toggleCFPassword = () => {
        if (CFpasswordType === "password") {
            setCFPasswordType("number")
            return;
        } setCFPasswordType("password")
    }

    const comfirmCheck = () => {
        if (OldpasswordInput !== "") {
            setReqiureOldPassword("")

        } else {
            setReqiureOldPassword("please_fill_old_password")
        }

        if (CFpasswordInput !== "") {
            setMessage("")
        } else {
            setMessage("please_fill_confirm_password")
        }

        if (NewpasswordInput !== "") {
            setReqiureNewPassword("")
        } else {
            setReqiureNewPassword("please_fill_new_password")
        }

        if (OldpasswordInput !== "" && CFpasswordInput !== "" && NewpasswordInput !== "") {
            passwordValidation()
        }
    }

    // useEffect(() => {
    //     if (OldpasswordInput.length === 6) {
    //         checkPasscode()
    //     } else {
    //         setCanChange(false)
    //     }
    // }, [OldpasswordInput])

    // useEffect(() => {
    //     if (NewpasswordInput.length === 6 && CFpasswordInput.length === 6) {
    //         setActiveConfirm(true)
    //     } else {
    //         setMessage("")
    //         setActiveConfirm(false)
    //     }

    // }, [NewpasswordInput, CFpasswordInput])


    async function checkPasscode() {

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", "accept-language": language },
            body: JSON.stringify({
                UserId: user.userId,
                Passcode: OldpasswordInput,
                PushKey: "",
                DeviceType: "",
                DeviceKey: ""
            }),
        };
        console.log(requestOptions)
        await fetch(
            coreApi + "/afcl/Account/checkpasscode",
            requestOptions
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const items = data;
                console.log(items);
                if (items["Success"] === true) {
                    console.log("Check Success = true");
                    setCanChange(true)
                    setMessage("")
                } else {
                    console.log("Check Success = false");
                    setCanChange(false)
                    setMessage(items["Message"])
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API /afcl/Account/checkpasscode ', error)
                setCanChange(false)
            });
    }


    async function changePassword() {
        setLoading(true);
        if (NewpasswordInput !== CFpasswordInput) {
            console.log("New Password Not Match!!")
            setMessage("password_notmatch")
            setLoading(false);
        } else {
            const payload = new GuestChangePasswordModel(user.userId, hashStringWithSHA256(OldpasswordInput), hashStringWithSHA256(NewpasswordInput));
            await GuestChangePasswordService(payload, language)
                .then(function (data) {
                    const items = data;
                    if (items['Success'] === true) {
                        // sessionStorage.clear();
                        setLoading(false);
                        removeSession('UserLogin');
                        setShowPopupChangePass(true)
                    } else {
                        setLoading(false);
                        setMessage(items['Message'])
                    }
                })
                .catch((error) => {
                    console.log('Error: ', error);
                });
        }
    }

    /** Send Email Forget **/
    const [showPopupChangePass, setShowPopupChangePass] = useState(false);
    const CloseShowPopupChangePass = () => setShowPopupChangePass(false);

    // ภาษา
    const { t } = useTranslation();

    return (
        <div>
            <div className="page page-steps" style={{ height: '100vh' }}>
                <div className="section py-5">

                    <div className="container">
                        <div className="row">
                            <div className="logosignin">
                                <img src={logo} alt="" id="logosignin" />
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6 box-body box-border box-signin signupradius p-5" style={{ margin: "0 auto", width: isMobile ? "340px" : "" }}>

                                <from>
                                    <div className="mb-3">
                                        <h2 className="main-title mb-3">{t('change_password')}</h2>
                                        <p className='text-muted'>
                                            {t('create_password_detail')}(!@#$%^&*()_+|~-=\`{ }[]:&quot;;'&lt;&gt;?,./)
                                        </p>

                                        <div className="py-2">
                                            <div className="mb-4">
                                                <label className="form-label ps-4 weight-500" style={{ color: '#0E315C' }}>{t('old_password')}* </label>
                                                {OldpasswordType != "password" && (
                                                    <FiledPasswordInput
                                                        type={"text"}
                                                        PasswordInput={OldpasswordInput}
                                                        setPasswordInput={setOldPasswordInput}
                                                        togglePassword={toggleOldPassword}
                                                        placeholder={t('old_password') + '*'}
                                                    />
                                                )}
                                                {OldpasswordType === "password" && (
                                                    <FiledPasswordInput
                                                        type={"password"}
                                                        PasswordInput={OldpasswordInput}
                                                        setPasswordInput={setOldPasswordInput}
                                                        togglePassword={toggleOldPassword}
                                                        placeholder={t('old_password') + '*'}
                                                    />
                                                )}
                                                <span className="text-danger">{t(reqiureOldPassword)}</span>
                                            </div>

                                            <div className="mb-4">
                                                {NewpasswordType != "password" && (
                                                    <FiledPasswordInput
                                                        type={"text"}
                                                        PasswordInput={NewpasswordInput}
                                                        setPasswordInput={setNewPasswordInput}
                                                        togglePassword={toggleNewPassword}
                                                        placeholder={t('new_password') + '*'}
                                                    />
                                                )}
                                                {NewpasswordType === "password" && (
                                                    <FiledPasswordInput
                                                        type={"password"}
                                                        PasswordInput={NewpasswordInput}
                                                        setPasswordInput={setNewPasswordInput}
                                                        togglePassword={toggleNewPassword}
                                                        placeholder={t('new_password') + '*'}
                                                    />
                                                )}
                                                <span className="text-danger">{t(reqiureNewPassword)}</span>
                                            </div>

                                            <div className="mb-4">
                                                {CFpasswordType != "password" && (
                                                    <FiledPasswordInput
                                                        type={"text"}
                                                        PasswordInput={CFpasswordInput}
                                                        setPasswordInput={setCFPasswordInput}
                                                        togglePassword={toggleCFPassword}
                                                        placeholder={t('confirm_new_password') + '*'}
                                                    />

                                                )}
                                                {CFpasswordType === "password" && (
                                                    <FiledPasswordInput
                                                        type={"password"}
                                                        PasswordInput={CFpasswordInput}
                                                        setPasswordInput={setCFPasswordInput}
                                                        togglePassword={toggleCFPassword}
                                                        placeholder={t('confirm_new_password') + '*'}
                                                    />

                                                )}
                                                <span className="text-danger">{t(message)}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="mb-3">
                                        <Button type="button" className="btn btn-link btn-lb text-capitalize gradient-background rounded-pill" onClick={comfirmCheck}>
                                            {t('confirm')}
                                        </Button>
                                        <SorasoSuccessModel
                                            showModal={showPopupChangePass}
                                            onCloseModal={CloseShowPopupChangePass}
                                            routePath={routePath}
                                            path={'/'}
                                            textDetail={'password_has_been_changed'}
                                            pic={PicSuccess}
                                            isBackToSignIn={true}
                                            isBackToHome={false}
                                        />
                                    </div>
                                </from>
                                <div className="mt-3 col-12 d-flex justify-content-center">
                                    <a href={baseLink + routePath + "/"} className=" text-back-sigin-boarder gradient-text"
                                        style={{
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        }}>{t('back_to_soraso')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Loading loading={loading} />
            </div>
        </div>
    )
}

export default ChangePass
