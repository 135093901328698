import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";

const FiledPasswordInput = (props) => {
    const { type, PasswordInput, setPasswordInput, togglePassword, placeholder } = props;

    return (
        <>
            <div style={{ position: 'relative' }}>
                <input
                    type={type}
                    className="form-control mb-3 ps-4 py-2"
                    id="password"
                    value={PasswordInput}
                    placeholder={placeholder}
                    maxLength={14}
                    required
                    style={{ borderRadius: "10px", borderColor: PasswordInput !== "" ? "black" : "" }}
                    onChange={(e) => setPasswordInput(e.target.value)}
                />
                <span onClick={togglePassword}
                    style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)', cursor: 'pointer', margin: '0 10px' }}>
                    {type === "password" ? (
                        <FiEye />
                    ) : (<FiEyeOff />)}
                </span>
            </div>
        </>
    );
}

export default FiledPasswordInput