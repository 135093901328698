export class UserLoginModel {
    constructor(Email, Password) {
        this.Email = Email;
        this.Password = Password;
    }
}

export class UserLogoutModel {
    constructor(UserId, Email) {
        this.UserId = UserId;
        this.Email = Email;
        this.DeviceKey = "";
    }
}

export class VisitorRegisterModel {
    constructor(Email, FirstName, LastName, PhoneNo, IDCardNo, CountryId) {
        this.Email = Email
        this.FirstName = FirstName
        this.LastName = LastName
        this.PhoneNo = PhoneNo
        this.IDCardNo = IDCardNo
        this.CountryId = CountryId
        this.DeviceType = ""
        this.PushKey = ""
        this.DeviceKey = ""
    }
}

export class VisitorUpdateModel {
    constructor(UserId, UserCode, Email, FirstName, LastName, PhoneNo, IDCardNo, CountryId) {
        this.UserId = UserId
        this.UserCode = UserCode
        this.Email = Email
        this.FirstName = FirstName
        this.LastName = LastName
        this.PhoneNo = PhoneNo
        this.IDCardNo = IDCardNo
        this.CountryId = CountryId
        this.DeviceType = ""
        this.PushKey = ""
        this.DeviceKey = ""
    }
}

export class GuestRegisterModel {
    constructor(Email, FirstName, LastName, Password, CountryCode, Phone, Photo, Line) {
        this.Email = Email
        this.FirstName = FirstName
        this.LastName = LastName
        this.Password = Password
        this.CountryCode = CountryCode
        this.Phone = Phone
        this.Photo = Photo
        this.DeviceType = ""
        this.PushKey = ""
        this.DeviceKey = ""
        this.Line = Line
    }
}

export class CheckExternalLoginModel {
    constructor(Provider, ProviderKey, Email) {
        this.Provider = Provider;
        this.ProviderKey = ProviderKey;
        this.Email = Email;
        this.PushKey = "";
        this.DeviceType = "";
        this.DeviceKey = "";

    }
}

export class GuestChangePasswordModel {
    constructor(UserId, OldPassword, NewPassword) {
        this.UserId = UserId
        this.OldPassword = OldPassword
        this.NewPassword = NewPassword
    }
}

export class GuestForgotModel {
    constructor(email) {
        this.email = email
    }
}
