import React from 'react'
import { ReactComponent as CaretDown } from '../img/chevron-down-79.svg'

export default function EventSelectDropDown(props) {
    const { selectedValue, onClick, mobile } = props

    return (
        <div className='dropdown'>
            <button className="btn dropdown-toggle"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: 'none',
                    minWidth: mobile ?? '200px',
                    maxWidth: mobile ?? '250px',
                    minHeight: '35px',
                    height: mobile ? '38px' : '45px',
                    wordBreak: 'break-word'
                }}
                type="button"
                onClick={onClick}
            >
                <p className='m-auto text-capitalize dropdown-text'
                    style={{ width: 'inherit', textOverflow: 'ellipsis', overflow: 'hidden', textTransform: 'capitalize', color: '#000', lineHeight: '2' }}>
                    {selectedValue.value ? selectedValue.value : 'Every Day'}
                </p>
                <CaretDown />
            </button>
        </div>
    )
}
