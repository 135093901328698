import { ReactComponent as Edit } from '../img/edit_icon.svg';

const FiledEditInput = (props) => {
    const { name, readOnly, defaultValue, placeholder, setOnChange, handleClick, showEditIcon, isText, isPhone, value } = props;

    return (
        <>
            <div style={{ position: 'relative' }}>
                <input type="text"
                    name={name}
                    value={value}
                    readOnly={readOnly}
                    className="form-control"
                    minLength={isText ? "3" : ""}
                    maxLength={isPhone ? "10" : ""}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    style={{ borderRadius: "10px", paddingRight: "15%" }}
                    onChange={setOnChange}
                    onKeyPress={isPhone ? (event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    } : () => { }}
                />
                {!showEditIcon && (
                    <Edit onClick={() => handleClick(name)} style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)', cursor: 'pointer', margin: '0 10px' }} />
                )}
            </div>
        </>
    );
}

export default FiledEditInput