import React, { useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ItemGroupCard from './ItemGroupCard';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from "react-scroll";
import { useMediaQuery } from 'react-responsive';
import CaretDown from '../img/caret_down_icon.svg';



export default function ItemGroupCarousel(props) {
    const { items } = props;
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' })

    const [isActive, setIsActive] = useState("");

    useEffect(() => {
        // console.log('ItemGroupCarousel', items);
        setIsActive(items[0].PackageSubTypeCode)
    }, [items]);

    useEffect(() => {
        window.scrollTo(0, isMobile ? 80 : 0); // Scroll to top
    }, [isActive])

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1280 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1280, min: 800 },
            items: 4
        },
        mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 3
        }
    };

    const handleActiveChange = (e) => {
        // console.log(e)
        // setIsActive();
        // console.log('Active change', );
    };

    const CustomArrow = ({ onClick, direction, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button
            className={`carousel-arrow react-multiple-carousel__arrow react-multiple-carousel__arrow--${direction}`}
            onClick={() => onClick()} >
            <img className='carousel-arrow' src={CaretDown} style={{ transform: direction == 'right' ? 'rotate(270deg)' : 'rotate(90deg)' }} />
        </button>;
    };

    return (
        <div style={{ width: '90%', height: 'fit-content' }}>
            <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                infinite={true}
                slidesToSlide={2}
                showDots={true}
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass='group-package-item d-flex justify-content-center'
                className='group-package-item-carousel'
                customLeftArrow={<CustomArrow direction={'left'} />}
                customRightArrow={<CustomArrow direction={'right'} />}
            >
                {items.map((item, index) => {
                    return (
                        <Link
                            key={index}
                            // activeClass="group-active"
                            spy={true}
                            smooth={true}
                            // offset={10}
                            duration={700}
                            to={item.PackageSubTypeCode}
                            containerId="containerElement"
                            className='group-link-container'
                            onSetActive={handleActiveChange(item.PackageSubTypeCode)}
                        >
                            <ItemGroupCard item={item} active={isActive === item.PackageSubTypeCode} setIsActive={setIsActive} />
                        </Link>
                    )
                })}
            </Carousel>
        </div >
    )
}