import { Backdrop } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react';

import "./css/loader.css";

export default function Loading(props) {
    const { loading } = props;

    useEffect(() => {
        if (loading) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'visible';
        }
    }, [loading]);

    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: "1" }}
                    style={{ zIndex: 1100 }}
                    open
                >
                    {/* <div className="spinner-grow" role="status" style={{ color: "#DEDEDE", width: "3rem", height: "3rem" }}></div> */}
                    <div class="la-ball-scale-multiple la-3x">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </Backdrop>
            )}
        </>
    )
}
