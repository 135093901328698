import { useState, useEffect } from 'react';

import { coreApi } from '../constants/DataPublic';
import { CheckUserExists, GetCountry, GetCountryPhone, GetProvince, GuestRegister } from '../constants/BaseApi';
import { GuestForgotModel, GuestRegisterModel } from '../Models/AccountModel';
import { CheckUserExistsService, GuestRegisterService, getCountryPhoneService, getCountryService, getProvinceService } from '../Services/AccountService';

const useRegister = (props) => {
  const [canRegist, setCanRegist] = useState(false);
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(false);
  const [message, setMessage] = useState('');
  const [country, setCountry] = useState([]);
  const [province, setProvince] = useState([]);
  const [countryPhone, setCountryPhone] = useState([]);

  const { language } = props;

  let userRegist = {};

  // Check Email before sign up
  function checkSignup(e) {
    console.log('Email: ', email);
    if (emailValidation()) {
      // Validate email
      checkUserExist();
    }
  }

  function emailValidation() {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || email === '') {
      setMessage('warn_email');
      setCanRegist(false);
      return false;
    } else if (regex.test(email) === false) {
      setMessage('invalid_email');
      setCanRegist(false);
      return false;
    } else {
      return true;
    }
  }

  async function checkUserExist() {
    const payload = new GuestForgotModel(email);
    await CheckUserExistsService(payload, language)
      .then((data) => {
        const items = data;
        if (items['Success']) {
          console.log('Success = true');
          setMessage('');
          setCanRegist(true);
          getCountry();
        } else {
          console.log('Success = false');
          setMessage('duplicate_email');
          setCanRegist(false);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        alert('Error: API ' + CheckUserExists, error)
      });
  }

  async function getCountry() {
    await getCountryService(language)
      .then((res) => {
        setCountry(res['Result']);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Account/country ', error)
      });
  }

  async function getProvince(countryId) {
    await getProvinceService(countryId, language)
      .then((res) => {
        setProvince(res['Result']);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Account/city/ ', error)
      });
  }

  async function getCountryPhone() {
    await getCountryPhoneService(language)
      .then((res) => {
        setCountryPhone(res['Result']);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Account/country/phone', error)
      });
  }

  const saveSignup = async (user) => {
    userRegist = { ...user };
    console.log('Save Signup : ', userRegist);

    const payload = new GuestRegisterModel(
      userRegist.email,
      userRegist.fName,
      userRegist.lName,
      userRegist.password,
      userRegist.countryCode,
      userRegist.tel,
      userRegist.photo,
      userRegist.lineID
    );

    await GuestRegisterService(payload, language)
      .then((data) => {
        const items = data;
        sessionStorage.removeItem('UserLogin');
        if (items['Success']) {
          let userLogin = {
            fName: items['Result'].User.FirstName,
            lName: items['Result'].User.LastName,
            email: items['Result'].User.Email,
            userId: items['Result'].User.UserId,
            userCode: items['Result'].User.UserCode,
            photo: items['Result'].User.UserPicture,
            channelCode: items['Result'].ChannelCode,
            isLogin: true,
          };
          sessionStorage.setItem('UserLogin', JSON.stringify(userLogin));
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      });

    // await fetch(apiURL, requestOptions)
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (data) {
    //     const items = data;
    //     console.log('Save Register: ', items);
    //     sessionStorage.removeItem('UserLogin');
    //     if (items['Success']) {
    //       let userLogin = {
    //         fName: items['Result'].User.FirstName,
    //         lName: items['Result'].User.LastName,
    //         email: items['Result'].User.Email,
    //         userId: items['Result'].User.UserId,
    //         userCode: items['Result'].User.UserCode,
    //         photo: items['Result'].User.UserPicture,
    //         channelCode: items['Result'].ChannelCode,
    //         isLogin: true,
    //       };
    //       sessionStorage.setItem('UserLogin', JSON.stringify(userLogin));
    //     }
    //   })
    //   .catch((error) => {
    //     console.log('Error: ', error);
    //     // alert('Error: ', apiURL, ' ', error)
    //   });
  };

  return { canRegist, result, message, email, country, province, countryPhone, userRegist, setCanRegist, setEmail, getCountry, getProvince, getCountryPhone, checkSignup, saveSignup };
};

export default useRegister;
