// Tableplan
export const GetEventAll = '/Event/GetEventAll';
export const GetEventCalendar = '/Event/GetDateLookup';
export const GetEventByDate = '/Event/GetEventByDate';
export const GetZoneAndEventByDate = '/POS/TableBook/TablePlan'
export const GetZonePlan = '/POS/TableBook/ZonePlan';
export const GetPackageByEvent = '/Event/PackageLookup';

// Package
export const GetPackageAddOn = '/ECommerce/Package/Package';
export const GetFBAddOn = '/ECommerce/Package/FB';
export const SaveOrder = '/afcl/Package/SaveOrder/V2';
export const CheckOutOrder = '/afcl/Package/CheckOutV2/';
export const VerifyPromotionCode = '/ECommerce/Package/VerifyPromotionCode/';
export const GetPackageTicket = '/afcl/Package/Ticket';
export const GetActivePayment = '/afcl/Package/GetActivePayment';
export const CartCheckOutOrder = '/afcl/Package/Cart/CheckOut/V2/';

// Table
export const BlockTable = '/POS/TableBook/Block';
export const UnblockTable = '/POS/TableBook/Unblock';
export const ConfirmBooking = '/POS/TableBook/BookingConfirm';

// Payment
export const AllPayment = '/afcl/Package/AllPayment';
export const CheckPaymentStatus = '/afcl/Package/Pay/CheckStatus';

// Voucher
export const GetVoucherVerify = '/ECommerce/Package/Voucher/Verify';
export const ApplyVoucher = '/ECommerce/Package/Voucher/Apply';

// Account
export const VisitorRegistor = '/Ecommerce/Account/visitor/register';
export const VisitorUpdateProfile = '/Ecommerce/Account/visitor/update/profile';
export const GuestLogin = '/Ecommerce/Account/guest/login';
export const GuestRegister = '/Ecommerce/Account/guest/register';
export const GuestChangePassword = '/Ecommerce/Account/guest/changePassword';
export const GuestForgotPassword = '/Ecommerce/Account/guest/forgotPassword';
export const GuestLogout = '/Ecommerce/Account/logout';
export const CheckExternalLogin = '/Ecommerce/Account/external/checklogin';
export const ExternalGuestregister = '/Ecommerce/Account/external/guest/register';
export const CheckUserExists = '/Ecommerce/Account/checkuserexists';
export const CheckPassCode = '/afcl/Account/checkpasscode';

export const GetCountry = '/Ecommerce/Country';
export const GetProvince = '/afcl/Account/city/';
export const GetCountryPhone = '/afcl/Account/country/phone';
export const GetTerms = '/afcl/Account/agreement';
