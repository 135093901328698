import React from 'react'
import { useTranslation } from "react-i18next";


import { Modal, ModalFooter } from 'react-bootstrap';
import logo from "../img/soraso_logo.png";
import { baseLink } from '../../constants/DataPublic';
import SorasoButton from '../buttons/SorasoButton';

const SorasoSuccessModel = (props) => {
    const { showModal, onCloseModal, onClick, textDetail, routePath, pic, isBackToSignIn, isBackToHome, path, showButton, onConfirm } = props;
    // Translation
    const { t } = useTranslation();
    return (
        <>
            {showModal && (
                <Modal show={showModal} className="col-12 popup-success-modal">
                    {path === '' ? (
                        <a className="close"><button type="button" class="btn-close" aria-label="Close" onClick={onCloseModal}></button></a>
                    ) : (
                        <a href={baseLink + routePath + path} className="close"><button type="button" class="btn-close" aria-label="Close" onClick={onCloseModal}></button></a>
                    )}
                    <div className="logosignin"><img src={logo} alt="" className="logo-popup" /></div>
                    <div className="text-center mb-4">
                        <img src={pic} alt="" className="img-popup" />
                    </div>
                    <p className="text-center text-79">{t(textDetail)}</p>
                    {isBackToSignIn && (
                        <div className="mt-3 col-12 d-flex justify-content-center">
                            <a href={baseLink + routePath + '/signin'} className=" text-back-sigin-boarder gradient-text"
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }}>{t('back_to_sigin')}</a>
                        </div>
                    )}
                    {isBackToHome && (
                        <div className="mt-3 col-12 d-flex justify-content-center">
                            <a href={baseLink + routePath + '/'} className=" text-back-sigin-boarder gradient-text"
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }}>{t('back_to_soraso')}</a>
                        </div>
                    )}
                    {showButton && (
                        <ModalFooter style={{ borderTop: '0px' }}>
                            <div className='row' style={{ width: '100%', rowGap: '10px' }}>
                                <div className='col-6'>
                                    <SorasoButton text={t('confirm')} onClick={onConfirm} style='primary' />
                                </div>
                                <div className='col-6'>
                                    <SorasoButton text={t('cancel')} onClick={onCloseModal} style='outline' />
                                </div>
                            </div>
                        </ModalFooter>
                    )}
                </Modal>
            )}
        </>
    );
}
export default SorasoSuccessModel