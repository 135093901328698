import { getBookingSession, getDateSession, getEventSession } from "../component/Helpers/BaseHelper";

export class GetZonePlanLookupModel {
    constructor(OutletCode, CampaignCode, SelectDate) {
        this.OutletCode = OutletCode;
        this.CampaignCode = CampaignCode;
        this.SelectDate = SelectDate;
    }
}

export class GetTablePlanLookupModel {
    constructor(OutletCode, CampaignCode, ZoneCode, SelectDate) {
        this.OutletCode = OutletCode;
        this.CampaignCode = CampaignCode;
        this.ZoneCode = ZoneCode;
        this.SelectDate = SelectDate;
    }
}
export class BlockTableModel {
    constructor(Tables) {
        this.BookingId = getBookingSession().bookingId == null ? 0 : getBookingSession().bookingId;
        this.CampaignCode = getEventSession() == null ? "" : getEventSession().selectEvent.id;
        this.ArrivalDate = getDateSession() ?? null;
        this.Tables = Tables;
    }
}