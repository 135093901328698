import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import useRegister from '../customHook/useRegister';

import logo from "../component/img/soraso_logo.png";
import PicSuccess from "../component/img/soraso_success_79.svg";
import { ReactComponent as Lock } from "../component/img/lock.svg";
import { ReactComponent as Refresh } from "../component/img/refresh.svg";
import { ReactComponent as Close } from "../component/img/close.svg";
import { baseLink } from '../constants/DataPublic';
import { useGlobalState } from '../global'
import { useMediaQuery } from 'react-responsive';
import { hashStringWithSHA256 } from '../component/Helpers/EncryptHelper';
import SorasoSuccessModel from '../component/modal/SorasoSuccessModal'
import Loading from '../component/Loading';
import { patternPassword } from '../component/Helpers/PatternPasswordHelper';

const SetPasscode = (props) => {

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
    const navigate = useNavigate();
    const [userName, setUserName] = useState("")
    const [routePath, setRoutePath] = useGlobalState('route');
    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    // Signup info
    const { userSignup, laguage } = props

    useEffect(() => {
        if (user != null && user.isLogin) {
            navigate("/")
        } else if (Object.keys(userSignup).length > 0) {
            setUserName(userSignup.fName)
        } else {
            navigate("/signup")
        }
    }, [])

    const [passcode, setPasscode] = useState([]);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [PinStyle, setPinStyle] = useState(["defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad"]);
    const [message, setMessage] = useState("");
    const [requiredPassword, setRequiredPassword] = useState("");
    const [loading, setLoading] = useState("");

    // Check User Register
    let { userInfo, saveSignup } = useRegister({ laguage: laguage });
    userInfo = { ...userSignup }



    const checkPassword = () => {
        if (password !== "") {
            setRequiredPassword('')
            if (confirmPassword !== "") {
                if (password === confirmPassword) {
                    userInfo.password = hashStringWithSHA256(password);
                    if (patternPassword(password)) {
                        setMessage(t(''));
                        setLoading(true);
                        submitRegister();
                    } else {
                        setMessage(t('password_requirements_not_met'));
                    }
                } else {
                    setMessage(t('password_didnot_match'));
                }
            } else {
                setMessage(t('please_fill_confirm_password'));
            }
        } else {
            setRequiredPassword(t('please_fill_password'))
            setMessage(t('please_fill_confirm_password'));
        }

    }


    const submitRegister = async () => {
        await saveSignup(userInfo)
        let user = sessionStorage.getItem('UserLogin')
        user = JSON.parse(user)
        if (user != null) {
            setLoading(false)
            setShowSignupSuccess(true)
        }
    };

    // const checkPinPass = (num) => {
    //     if (passcode.length < 6) {
    //         setPasscode([...passcode, num])
    //         PinStyle[passcode.length] = "showPinpad"
    //     }
    // }

    // const ResetPinpass = () => {
    //     setPasscode([])
    //     setPinStyle(["defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad"])
    //     setMessage("")
    // }

    // useEffect(() => {
    //     if (passcode.length === 6) {
    //         submitRegister()
    //     }
    // }, [passcode])

    // const submitRegister = async (e) => {
    //     let passcodeStr = ""
    //     passcode.forEach(element => {
    //         passcodeStr = passcodeStr + element
    //     });

    //     if (passcodeStr.length === 6) {
    //         userInfo.passcode = passcodeStr
    //         await saveSignup(userInfo)
    //         let user = sessionStorage.getItem('UserLogin')
    //         user = JSON.parse(user)
    //         if (user != null) {
    //             setShowSignupSuccess(true)
    //         }
    //     }
    // }

    /** Register Success. **/
    const [showPopupSignupSuccess, setShowSignupSuccess] = useState(false);
    const CloseSignupSuccess = () => setShowSignupSuccess(false);

    // ภาษา
    const { t } = useTranslation();

    return (
        <div className="page page-steps" style={{ minHeight: '100vh' }}>
            <div className="section py-5">
                <div className="container">
                    <div className="row">
                        <div className="logosignin">
                            <img src={logo} alt="" id="logosignin" />
                        </div>

                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6 box-body box-border box-signin signupradius p-5" style={{ margin: "0 auto", width: isMobile ? "340px" : "" }}>
                            <div className="mb-3">
                                <h2 className="main-title mb-3">{t('create_password')}</h2>
                                <p className='text-muted'>
                                    {t('create_password_detail')}(!@#$%^&*()_+|~-=\`{ }[]:&quot;;'&lt;&gt;?,./)
                                </p>
                                <input
                                    type="password"
                                    className="form-control "
                                    id="password"
                                    placeholder="password"
                                    required
                                    style={{ borderRadius: "10px" }}
                                    maxLength={14}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span className="text-danger mb-3">{t(requiredPassword)}</span>
                                <label htmlFor="exampleFormControlInput1" className="font-black">
                                    {t('confirm_password')}*
                                </label>
                                <input
                                    type="password"
                                    className="form-control mb-3"
                                    id="confirm_password"
                                    placeholder="password"
                                    required
                                    style={{ borderRadius: "10px" }}
                                    maxLength={14}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <span className="text-danger">{t(message)}</span>
                                <div className="mb-3 mt-4">
                                    <Button type="button" className="btn btn-link btn-lb text-capitalize gradient-background rounded-pill" onClick={checkPassword}>
                                        {t('confirm')}
                                    </Button>
                                </div>
                            </div>
                            <SorasoSuccessModel
                                showModal={showPopupSignupSuccess}
                                routePath={routePath}
                                path={'/'}
                                textDetail={'register_success'}
                                onCloseModal={CloseSignupSuccess}
                                pic={PicSuccess}
                                isBackToSignIn={false}
                                isBackToHome={false}
                            />
                            {/* <Modal show={showPopupSignupSuccess} className="col-12 popup-setpw">
                                <a href={baseLink + routePath} className="close"><button type="button" class="btn-close" aria-label="Close" onClick={CloseSignupSuccess}></button></a>
                                <div className="logosignin"><img src={logo} alt="" className="logo-popup" /></div>
                                <div className="text-center mb-4">
                                    <img src={PicSuccess} alt="" className="img-popup" />
                                </div>
                                <p className="text-center text-color">{t('register_success')}</p>
                            </Modal>  */}
                            {/* <center>
                                <div className="mb-3"><Lock /></div>
                                <div className="mb-5 weight-500">{t('hi_fill_passcode', { userName })}</div>
                                <div className="col-8">
                                    <div className="row" style={{ justifyContent: "center", alignItems: "center" }}>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[0]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[1]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[2]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[3]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[4]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[5]}></div>
                                        </div>
                                    </div>
                                </div>

                                <span className="text-danger mb-3">{message}</span>
                            </center>

                            <center className="py-5">

                                <div className="row mb-5">
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("1")}><span className="box-passcode">1</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("2")}><span className="box-passcode">2</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("3")}><span className="box-passcode">3</span></button>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("4")}><span className="box-passcode">4</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("5")}><span className="box-passcode">5</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("6")}><span className="box-passcode">6</span></button>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("7")}><span className="box-passcode">7</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("8")}><span className="box-passcode">8</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("9")}><span className="box-passcode">9</span></button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("0")}><span className="box-passcode">0</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={ResetPinpass}><Refresh className="size-refresh" /></button>
                                    </div>
                                </div>

                            </center>*/}

                        </div>

                    </div>
                </div>
            </div>
            <Loading loading={loading} />
        </div>
    )
}

export default SetPasscode