import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalState } from '../global'

import LineLight from "../component/img/line-light.svg";
import YoutubeLight from "../component/img/youtube-light.svg";
import FacebookLight from "../component/img/facebook-light.svg";
import IgLight from "../component/img/ig-light.svg";

import "./css/style.css";
import { Col, Row } from "react-bootstrap";
import { fbLink, igLink, lineLink, versions, ytLink } from "../constants/DataPublic";

export const Footer = (props) => {
  const { path } = props;
  const [routePath, setRoutePath] = useGlobalState('route');
  const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');
  const [footer, setFooter] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (path === 'signin' || path === 'forget' || path === 'changepw' || path === 'guestCheckout' || path === 'signup' || path === 'setpw') {
      setFooter(true);
    } else {
      setFooter(false);
    }
  }, [path]);
  return (
    <>
      <div className={footer ? "custom-footer-login" : "custom-footer"}>

        {/* กำนดว่าให้ footer แสดงเฉพาะ size Desktop */}
        <div className="d-none d-lg-block">
          <div className="p-4 gradient-background text-white text-center">
            <div className="footer-container">

              {/* Desktop */}
              <div class="row gy-4">
                <div class="col-lg-6 d-flex" style={{ gap: '10px' }}>
                  <div className="col-auto text-start d-flex justify-content-center align-items-center" style={{ width: '45%', wordBreak: 'break-all' }}>
                    <p className="font-10 mb-0">
                      {t('smart_finder_co')}
                    </p>
                  </div>
                  <div className="col-auto text-start" style={{ width: '25%', wordBreak: 'break-all' }}>
                    <Row>
                      <Col>
                        <div className="col-auto text-start">
                          <p className="font-10 mb-0">
                            {t('email_us')}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="col-auto text-start">
                          <a className="font-10 mb-0 text-white" href={`mailto:${t('smart_finder_email')}`}>
                            {t('smart_finder_email')}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="col-auto text-start" style={{ width: '25%', wordBreak: 'break-all' }}>
                    <Row>
                      <Col>
                        <div className="col-auto text-start">
                          <p className="font-10 mb-0">
                            {t('call_us')}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="col-auto text-start">
                          <a className="font-10 mb-0 text-white" href="tel:+6621234556">
                            {t('smart_finder_phone')}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class="col-lg-2 d-flex justify-content-center">
                  <div className="col-auto text-start d-flex justify-content-center align-items-center" style={{ width: '50px' }}>
                    <a href={lineLink}>
                      <img src={LineLight} />
                    </a>
                  </div>
                  <div className="col-auto text-start d-flex justify-content-center align-items-center" style={{ width: '50px' }}>
                    <a href={ytLink}>
                      <img src={YoutubeLight} />
                    </a>
                  </div>
                  <div className="col-auto text-start d-flex justify-content-center align-items-center" style={{ width: '50px' }}>
                    <a href={fbLink}>
                      <img src={FacebookLight} />
                    </a>
                  </div>
                  <div className="col-auto text-start d-flex justify-content-center align-items-center" style={{ width: '50px' }}>
                    <a href={igLink}>
                      <img src={IgLight} />
                    </a>
                  </div>
                </div>
                <div class="col-lg-2 d-flex justify-content-end align-items-center">
                  <ul className="list-unstyled list-inline mb-0 list-footer-menu">
                    <li className="list-inline-item">
                      <a href="https://www.seenbeachclubsamui.com/privacy-policy" >
                        {t('privacy_policy')}
                      </a>
                    </li>
                    {/* <li className="list-inline-item">|</li>
                    <li className="list-inline-item">
                      <a href="#" >
                        {t('legal_notices')}
                      </a>
                    </li> */}
                    <li className="list-inline-item">|</li>
                    <li className="list-inline-item">
                      <a href="https://www.seenbeachclubsamui.com/terms-and-conditions" >
                        {t('terms_of_use')}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-2 d-flex justify-content-end align-items-center">
                  <ul className="d-flex justify-content-center align-items-center list-unstyled list-inline mb-0 list-footer-menu ">
                    <li className="list-inline-item">
                      <p className="m-0">
                        {'Power by SORASO'}
                      </p>
                    </li>
                    <li className="list-inline-item ms-2">
                      <p className="m-0">
                        V {versions}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default Footer;
