import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

//Image
import logo from '../component/img/soraso_logo.png';
import EmailConfirm from '../component/img/email.PNG';
import { ReactComponent as Close } from '../component/img/close.svg';
import PicSuccess from '../component/img/email_79.svg'

//css
import '../component/css/style.css';
import '../component/css/custom.css';

import useLogin from '../customHook/useLogin';
import { baseLink, apiLink, coreApi } from '../constants/DataPublic';
import { useGlobalState } from '../global'
import { useMediaQuery } from 'react-responsive';
import { GuestForgotModel } from '../Models/AccountModel';
import { GuestForgotPassService } from '../Services/AccountService';
import SorasoSuccessModel from '../component/modal/SorasoSuccessModal';
import Loading from '../component/Loading';

const Forget = (props) => {
  const { language } = props;
  const navigate = useNavigate();
  const [editEmail, setEditEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [routePath, setRoutePath] = useGlobalState('route');
  const [message, setMessage] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
  const [loading, setLoading] = useState("");

  /** Send Email Forget **/
  const [showPopupSendEmail, setShowPopupSendEmail] = useState(false);
  const CloseShowPopupSendEmail = () => setShowPopupSendEmail(false);
  const ShowShowPopupSendEmail = () => setShowPopupSendEmail(true);

  let user = sessionStorage.getItem('UserLogin');
  user = JSON.parse(user);

  useEffect(() => {
    console.log('User', user);
    if (user == null) {
      setEmail('');
      setEditEmail(true);
    } else if (user != null && user.isLogin) {
      navigate('/');
    } else if (user.email != null) {
      setEmail(user.email);
      setEditEmail(false);
    }
  }, []);

  // Login with Email
  // let { result, message, setEmail, setResult, submitForgot } = useLogin({ lang: language });

  // useEffect(() => {
  //   if (result) {
  //     console.log('Show Popup');
  //     forgotPasscode();
  //   }
  // }, [result]);

  function emailValidation() {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || email === '') {
      setMessage('warn_email');
      return false;
    } else if (regex.test(email) === false) {
      setMessage('invalid_email');
      return false;
    } else {
      return true;
    }
  }

  const checkEmail = () => {
    if (emailValidation()) {
      sendEmailForgot();
    }
  }

  // Forgot Password
  async function sendEmailForgot() {
    setLoading(true);
    const payload = new GuestForgotModel(email);

    await GuestForgotPassService(payload, language)
      .then((data) => {
        const items = data;
        if (items['Success']) {
          setMessage('');
          sessionStorage.removeItem('UserLogin');
          setLoading(false);
          setShowPopupSendEmail(true);
        } else {
          setLoading(false);
          setMessage(items['Message']);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  }

  // async function forgotPasscode() {
  //   let user = sessionStorage.getItem('UserLogin');
  //   user = JSON.parse(user);
  //   console.log(user);

  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', 'accept-language': language },
  //     body: JSON.stringify({
  //       UserId: user.userId,
  //       Email: user.email,
  //       DeviceKey: '',
  //     }),
  //   };
  //   console.log(requestOptions);
  //   await fetch(coreApi + '/afcl/Account/forgotpassword', requestOptions)
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (data) {
  //       const items = data;
  //       console.log(items);
  //       if (items['Success'] === true) {
  //         console.log('Forgot Success = true');
  //         sessionStorage.removeItem('UserLogin');
  //         setShowPopupSendEmail(true);
  //       } else {
  //         console.log('Forgot Success = false');
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('Error: ', error);
  //       // alert('Error: API /afcl/Account/forgotpassword ', error)
  //       setResult(false);
  //     });
  // }

  // useEffect(() => {
  //   if (showPopupSendEmail) {
  //     sessionStorage.removeItem('UserLogin');
  //   }
  // }, [showPopupSendEmail]);

  // ภาษา
  const { t } = useTranslation();

  return (
    <div>
      <div className="page box-mh" style={{ minHeight: '100vh' }}>
        <div className="section py-5 bg-79-sand">
          <div className="container">
            <div className="row">
              <div className="logosignin">
                <img src={logo} id="logosignin" />
              </div>
              <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6 box-body box-border box-signin signupradius p-5" style={{ margin: "0 auto", width: isMobile ? "340px" : "" }}>
                <from>
                  <div className="mb-4">
                    <h2 className="main-title mb-3">{t('forgot_your_password')}</h2>
                    <span className="weight-200">{t('please_enter_email')}</span>
                    <p className="weight-200">{t('new_password_will_be_email')}</p>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-color mt-4">
                      {' '}
                      {t('your_email')}*{' '}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      defaultValue={user != null ? user.email : ''}
                      disabled={!editEmail}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ borderRadius: "10px" }}
                    />
                    <span className="text-danger">{t(message)}</span>
                  </div>

                  <div className="mb-3">
                    <Button type="button" className="btn btn-link btn-lb text-capitalize gradient-background rounded-pill" onClick={checkEmail}>
                      {t('confirm')}
                    </Button>
                    <SorasoSuccessModel
                      showModal={showPopupSendEmail}
                      onCloseModal={CloseShowPopupSendEmail}
                      routePath={routePath}
                      path={'/signin'}
                      textDetail={'email_was_send'}
                      pic={PicSuccess}
                      isBackToSignIn={true}
                      isBackToHome={false}
                    />
                  </div>
                </from>
                <div className="mt-3 col-12 d-flex justify-content-center">
                  <a href={baseLink + routePath + "/signin"} className=" text-back-sigin-boarder gradient-text"
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}>{t('back_to_sigin')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loading loading={loading} />
      </div>
    </div>
  );


};

export default Forget;
