import { useControls } from "react-zoom-pan-pinch";

const ControlsZoomPinch = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
        <>
            <div style={{
                position: 'sticky',
                bottom: '1%',
                left: '1%',
                zIndex: '10'
            }}>
                <div className='zoom-button'>
                    <button onClick={() => zoomIn()}>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                    <button onClick={() => zoomOut()}>
                        <i class="fa fa-minus" aria-hidden="true"></i>
                    </button>
                    <button onClick={() => resetTransform()}>
                        {/* <img style={{
                            WebkitMask: 'url(' + ResetIcon + ') no-repeat center'
                        }} className="controls-zoom-img" /> */}
                        <i class="fa fa-repeat" aria-hidden="true"></i>
                    </button>
                </div>
            </div >
        </>
    )
}

export default ControlsZoomPinch