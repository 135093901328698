import '../component/css/landindcss.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageLoader } from "react-image-and-background-image-fade";
import { convertDateToISO, delay, getCartSession, getDateSession, getErrorMessage, getEventSession, getEventStateSession, removeSession, resetBookingSesstion, setDateSession, setEventTimeSession } from '../component/Helpers/BaseHelper';
import { useMediaQuery } from 'react-responsive'
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import SelectZoneBar from '../component/SelectZoneBar';
import { useLocation } from 'react-router-dom';
import ControlsZoomPinch from '../component/buttons/ControlsZoomPinch';
import { getVoucherSession } from '../component/Helpers/BaseHelper';


import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ImageMap from '../component/Tableplan/ImageMap';
import Loading from '../component/Loading';
import SorasoRefresh from '../component/img/soraso_refresh_79.svg';
import ExpireIcon from '../component/img/expire_icon_79.svg';

// Base Provider
import { useToast } from '../component/Helpers/ToastContext';
import { useAlert } from '../component/Helpers/SwalAlertContext';

// Api Constants
import AlertModal from '../component/modal/AlertModal';
import { UnBlocktable, getEventAndZoneLookup, getEventDate, getZonePlanLookup } from '../Services/TableBookService';
import { BlockTableModel, GetZonePlanLookupModel } from '../Models/TableBookModel';

//Component
const LandingPage = (props) => {
  const { language, getCartItem, setnavActiveIndex } = props;
  let state = getEventStateSession();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { showToast } = useToast();
  const { addAlert } = useAlert();
  const [showAlert, setShowAlert] = useState(false);
  const [alertCallback, setAlertCallback] = useState({});
  const { t } = useTranslation();
  const scrollableDivRef = useRef();

  // Date 
  const [startDate, setStartDate] = useState();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const [eventDateArray, setEventDateArray] = useState([]);
  let eventAndZone = getEventSession();

  // Zone DropDown State
  const [tablePlan, setTablePlan] = useState();
  const [outlet, setOutlet] = useState(null);
  const [zone, setZone] = useState([]);
  const [selectedZone, setSelectedZone] = useState({
    key: "",
    value: null
  });

  // Cart
  let cart = JSON.parse(sessionStorage.getItem("MyCart"));

  // Event State
  const [eventItems, setEventItems] = useState([]);

  const [activeEvent, setActiveEvent] = useState({ id: 0, value: "" });

  const [alertIcon, setAlertIcon] = useState(SorasoRefresh);
  const [alertMessage, setAlertMessage] = useState(t('expire_reservation'));
  const [showCancle, setShowCancle] = useState(true);

  const openConfirmationModal = () => {
    return new Promise((resolve) => {
      setShowAlert(true);

      const handleConfirm = () => {
        resolve(true); // User confirmed the change
      };

      const handleCancel = () => {
        resolve(false); // User canceled the change
      };

      // Set the ConfirmationModal callbacks
      setAlertCallback({ confirm: handleConfirm, cancel: handleCancel });
    });
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setnavActiveIndex(1);
    var date = getDateSession();
    if (date != null && date != "") {
      date = date + 'T00:00:00';
      let startDate = new Date(date);
      let currentDate = new Date();
      startDate.setHours(7, 0, 0);
      currentDate.setHours(7, 0, 0, 0);
      if (startDate < currentDate) {
        // console.log("DATE", startDate.toISOString(), currentDate.toISOString());
        checkExpireReserve(date);
      } else {
        setStartDate(new Date(date));
      }
    } else {
      setStartDate(new Date());
    }
    getEventCalendar();
  }, [])

  useEffect(() => {
    if (outlet !== null && startDate) {
      getZoneAndEventByDate(convertDateToISO(startDate), activeEvent.id)
    }
  }, [language]);

  useEffect(() => {
    if (selectedZone != null && selectedZone !== "") {
      // getTablePlan();
    } else {
      setTablePlan(null)
    }
  }, [selectedZone])

  useEffect(() => {
    if (startDate !== undefined && startDate !== null) {
      let date = new Date(startDate);
      setDateSession(convertDateToISO(date));
      if (language != null && language != undefined) {
        let eventCode = "";
        if (state !== null) {
          eventCode = state.CampaignCode;
        }
        getZoneAndEventByDate(convertDateToISO(date), eventCode);
      }
    }
  }, [startDate])

  const transformComponentRef = useRef();

  const checkExpireReserve = async (date) => {
    setAlertMessage(t('expire_reservation'));
    setAlertIcon(ExpireIcon);
    setShowCancle(false);

    await openConfirmationModal()
      .then(async (confirmed) => {
        if (confirmed) {
          setShowAlert(false);
          removeSession('MyCart');
          resetBookingSesstion();
          removeSession('CurrentEvent');
          removeSession('EventTime');
          setStartDate(new Date());
          window.location.reload();
        }
      })
      .catch((error) => {
        // Handle errors or user cancelation
        setShowAlert(false);
        removeSession('MyCart');
        resetBookingSesstion();
        setStartDate(new Date());
      });
  }

  const getEventCalendar = async () => {
    setLoading(true);
    await delay(1000);
    try {
      await getEventDate('en')
        .then(data => {
          // Handle the response data
          const date = data.Data.map((item) => new Date(item.EventDate));
          setEventDateArray(date);
          setLoading(false);
        })
        .catch(error => {
          // Handle errors
          console.error('Error from GetEventDate:', error);
          throw error;
        });

    } catch (error) {
      setLoading(false);
      console.log(error);
      showToast(error.message, 'error');
    }
  }

  const getZoneAndEventByDate = async (date, eventCode) => {
    if (eventCode === null) { eventCode = "" }
    eventAndZone = getEventSession();
    const voucherData = getVoucherSession();

    if (voucherData || voucherData !== null) {
      eventCode = voucherData.CampaignCode;
    }

    let params = {
      eventDate: date,
    };

    if (eventCode) {
      params.eventCode = eventCode;
    } else {
      if (eventAndZone) {
        params.eventCode = eventAndZone.selectEvent.id;
      }
    }

    setLoading(true);
    await delay(1000);

    await getEventAndZoneLookup(params, language ?? 'th')
      .then(async (response) => {
        // Handle the response data
        if (response.Status === "Ok") {
          const data = response.Data;
          const zonesData = data.Zones.map((item) => ({ id: item.ZoneCode, value: item.ZoneName }));
          if (voucherData || voucherData !== null) {
            zonesData.filter((a) => voucherData?.ZoneList?.some((b) => a.id === b.ZoneCode));
          }

          setSelectedZone({ id: 0, value: "" });
          setZone(zonesData);
          setEventItems(data.Events);

          if (eventAndZone !== null) {
            const selectEventId = eventAndZone.selectEvent.id;
            const foundEvent = data.Events.find((e) => e.CampaignCode === selectEventId);

            if (foundEvent) {
              const newEventValue = foundEvent.CampaignName;
              setActiveEvent({ id: selectEventId, value: newEventValue });
              setEventTimeSession(foundEvent.EventStartTime);
              eventAndZone.selectEvent.value = newEventValue;
              sessionStorage.setItem("CurrentEvent", JSON.stringify(eventAndZone));
            } else {
              sessionStorage.removeItem("CurrentEvent");
              if (eventCode !== null && eventCode !== "") {
                var event = data.Events.find((e) => {
                  return e.CampaignCode === eventCode
                })
              }
            }
          } else {
            sessionStorage.removeItem("CurrentEvent");
            if (eventCode !== null && eventCode !== "") {
              var event = data.Events.find((e) => {
                return e.CampaignCode === eventCode
              })

              if (event !== null && event) {
                setActiveEvent({ id: event.CampaignCode, value: event.CampaignName });
                setEventTimeSession(event.EventStartTime);
              } else {
                setActiveEvent({
                  id: data.Events[0].CampaignCode,
                  value: data.Events[0].CampaignName
                });
                setEventTimeSession(data.Events[0].EventStartTime)
              }
            } else if (data.Events.length > 0) {
              setActiveEvent({
                id: data.Events[0].CampaignCode,
                value: data.Events[0].CampaignName
              });
              setEventTimeSession(data.Events[0].EventStartTime)
            }
          }

          if (data.Events.length == 0) {
            addAlert("No Events Available", "error");
            setActiveEvent("");
          }

          setOutlet({
            OutletCode: data.OutletCode,
            OutletName: data.OutletName,
          });

          if (data.OutletCode !== "" && data.Zones.length > 0) {
            await getZonePlan(data.OutletCode, data.Events[0].CampaignCode);
          } else {
            setTablePlan();
            addAlert(t('no_package'), "error");
          }

          setLoading(false);
        } else {
          throw (getErrorMessage(response.Errors));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error from GetEventDate:', error);
        setZone([]);
        setSelectedZone({ id: 0, value: "" });
        setActiveEvent({ id: 0, value: "" });
        setEventItems(null);
        setLoading(false);
        console.log(error);
        showToast(error.message, 'error');
      });
  }

  const getZonePlan = async (outletCode, eventCode) => {
    let date = getDateSession();

    setLoading(true);

    const payload = new GetZonePlanLookupModel(outletCode, eventCode, date)

    await delay(1000);
    try {
      await getZonePlanLookup(payload, language ?? 'th')
        .then(async (response) => {
          let dataFilter = [];
          let voucher = getVoucherSession();
          if (voucher !== null || voucher) {
            dataFilter = response.Result?.Details?.filter((a) => voucher?.ZoneList?.some((b) => a.TableNo === b.ZoneCode));
          } else {
            dataFilter = response.Result['Details']
          }
          let dataObj = {
            OutletCode: response.Result['OutletCode'],
            ZoneCode: response.Result['ZoneCode'],
            ZoneName: response.Result['ZoneName'],
            Picture: response.Result['Picture'],
            AreaWidth: response.Result['AreaWidth'],
            AreaHeight: response.Result['AreaHeight'],
            Opacity: response.Result['Opacity'],
            Details: dataFilter
          }
          setTablePlan(dataObj);
          setLoading(false);
        }).catch((error) => {
          // Handle errors
          console.error('Error from GetEventDate:', error);
          throw error;
        });
    } catch (error) {
      setZone([]);
      setSelectedZone({ id: "", value: null });
      setActiveEvent({ id: 0, value: "" });
      setLoading(false);
      setTablePlan(null);
      console.log(error);
      showToast(error.message, 'error');
    }
  }

  const onSelectZone = (e) => {
    if (cart != null && cart.length > 0 && eventAndZone.selectZone != e) {
      setAlertIcon(SorasoRefresh);
      openConfirmationModal()
        .then(async (confirmed) => {
          if (confirmed) {
            await CallUnblockAllTable();
            clearSessions();
            setShowAlert(false);
            if (e !== null && e !== undefined && e != "") {
              sessionStorage.setItem('CurrentEvent', JSON.stringify({ selectZone: e, selectEvent: activeEvent }))
              navigate('/table', { state: { selectZone: e, selectEvent: activeEvent } });
            }
          }
        })
        .catch((error) => {
          // Handle errors or user cancelation
          console.error(error);
        });
    } else {
      if (e !== null && e !== undefined && e != "") {
        sessionStorage.setItem('CurrentEvent', JSON.stringify({ selectZone: e, selectEvent: activeEvent }))
        navigate('/table', { state: { selectZone: e, selectEvent: activeEvent } });
      }
    }

  }

  const NoEven = () => {
    // return <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}> <h2 className=''>{t('no_event_active')}</h2> </div>
    return <></>;
  }

  const [backDrop, setbackDrop] = useState(-1);
  const [popupIndex, setPopupIndex] = useState(0);


  const setBackdropClose = () => {
    setbackDrop(-1);
    setPopupIndex(0);
  }


  const clearSessions = async () => {
    removeSession('CurrentEvent');
    removeSession('MyCart');
    setActiveEvent({ id: 0, value: "" });
    getCartItem(0);
  };


  async function CallUnblockAllTable() {
    let cart = getCartSession();
    let selectedTable = [];
    if (cart !== null && cart.length > 0) {
      cart.map((table) => {
        selectedTable.push(table.TableNo);
      })
    }

    if (selectedTable.length < 1) return false;

    const payload = new BlockTableModel(
      selectedTable.map((e) => {
        return { TableNo: e }
      }),
    )

    await delay(1000);
    try {
      await UnBlocktable(payload, language ?? 'EN')
        .then((response) => {
          if (response.Success) {
            // console.log(data);
            delay(1500);
          } else {
            throw (response.Result.Message);
          }
        }).catch((error) => {
          throw error;
        })

    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const handleZoomStop = (e) => {
    if (e.state.scale === 1) {
      if (scrollableDivRef.current) {

        scrollableDivRef.current.scrollTop = 1500; // Scroll to a specific position (e.g., 500 pixels)
      }
    }
  };


  return (
    <div className="page homepage" ref={scrollableDivRef}>
      {/* Slide Banner */}
      <ImageLoader src={'https://ohhsome.agency/buytickets/img/bg-slider.jpg'} transitionTime='0s'>
        {({ hasLoaded, shouldShowLoader, hasFailed }) => (
          <>
            {shouldShowLoader && !hasFailed && (
              <div className='banner-loading' style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                <h2 style={{ color: 'rgb(112 112 112)' }}> Loading ... </h2>
              </div>
            )}

            {hasFailed && (
              <div className='AwesomeoLoader__failed'>
                Loading Fail :(
              </div>
            )}

            {hasLoaded && (
              <>
                {isDesktopOrLaptop &&
                  <>
                    <div className='tableplan' style={{ paddingBottom: '0' }}>
                      <div className='tableplan-header d-flex justify-content-center'>
                        <SelectZoneBar
                          isMobile={false}
                          eventDateArray={eventDateArray}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          optionsZone={zone}
                          eventItems={eventItems}
                          selectedValueZone={selectedZone.value}
                          errorTextZone={'Zone All'}

                          selectedValueEvent={activeEvent}
                          scanMobileH={isTabletOrMobile ? 25 : 30}
                          onRefreshPlan={(date, event) => getZoneAndEventByDate(convertDateToISO(startDate), event ?? null)}
                          getCartItem={getCartItem}
                          setActiveEvent={setActiveEvent}
                          setSelectedZone={setSelectedZone}
                        />
                      </div>
                      {tablePlan && tablePlan != null && tablePlan.Details.length > 0 ? (
                        <div style={{ marginTop: '0', width: '100%' }}>
                          <div className='col'>
                            <div className='tableplan-image text-center'>
                              <TransformWrapper
                                disablePadding={true}
                                initialScale={1}
                                initialPositionX={0}
                                initialPositionY={0}
                                ref={transformComponentRef}
                                onZoomStop={handleZoomStop}
                              >

                                {() => (
                                  <React.Fragment>
                                    <div className='content d-flex justify-content-center' style={{
                                      position: 'relative'
                                    }}>
                                      <ControlsZoomPinch />
                                      <TransformComponent wrapperStyle={{ maxWidth: '100%', display: 'block !important' }}>
                                        <ImageMap
                                          tablePlan={tablePlan}
                                          onSelectZone={onSelectZone}
                                          children="zone"
                                        />
                                      </TransformComponent>
                                    </div>
                                  </React.Fragment>
                                )}
                              </TransformWrapper>
                            </div>
                          </div>
                        </div>
                      ) : NoEven()}
                    </div>
                  </>
                }

                {isTabletOrMobile &&
                  <>
                    <div className='tableplan' style={{ paddingBottom: '0' }}>
                      <div className='tableplan-header d-flex justify-content-center align-items-center' style={{ width: '100%' }}>
                        <div className='tableplan-header' style={{ width: '100%' }}>
                          <SelectZoneBar
                            isMobile={true}
                            eventDateArray={eventDateArray}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            optionsZone={zone}
                            selectedValueZone={selectedZone.value}

                            errorTextZone={'Zone All'}

                            zone={zone}
                            selectedValueEvent={activeEvent}
                            getCartItem={getCartItem}
                            eventItems={eventItems}
                            setActiveEvent={setActiveEvent}
                            setSelectedZone={setSelectedZone}
                            options={options}

                            datePickerMobileSize={'30vw'}
                            zoneMobileSize={'30vw'}
                            eventMobile={'30vw'}
                            scanMobileH={isTabletOrMobile ? 25 : 30}

                            onRefreshPlan={(date, event) => getZoneAndEventByDate(convertDateToISO(startDate), event ?? null)}
                          />
                        </div>
                      </div>

                      {tablePlan && (
                        <div className='tableplan-image text-center'>
                          <TransformWrapper
                            disablePadding={true}
                            initialScale={1}
                            initialPositionX={0}
                            initialPositionY={0}
                            ref={transformComponentRef}
                          >

                            {() => (
                              <React.Fragment>
                                <div className='content' style={{
                                  position: 'relative'
                                }}>

                                  <TransformComponent wrapperStyle={{ maxWidth: '100%', display: 'block !important' }}>
                                    <ImageMap
                                      tablePlan={tablePlan}
                                      onSelectZone={onSelectZone}
                                      children="zone"
                                    />
                                  </TransformComponent>

                                  <ControlsZoomPinch />
                                </div>
                              </React.Fragment>
                            )}
                          </TransformWrapper>

                        </div>
                      )}
                    </div>
                  </>
                }
              </>
            )}
          </>
        )
        }
      </ImageLoader >


      <Loading loading={loading} />

      {showAlert && (
        <AlertModal
          openModal={showAlert}
          onCloseModal={() => setShowAlert(false)}
          onConfirm={alertCallback.confirm}
          isShowCancle={showCancle}
          isShowClose={false}
        >
          <div className='d-flex flex-column justify-content-around align-items-center' style={{ height: '100%' }}>
            <div className='mb-3'>
              <img src={alertIcon} />
            </div>
            {alertIcon == SorasoRefresh ? (
              <>
                <div className='' style={{ fontWeight: 'bold' }}>
                  {t('remove_alert_1')}
                </div>
                <div className='' style={{ fontWeight: 'bold' }}>
                  {t('remove_alert_2')}
                </div>
              </>
            ) : (
              <p className='' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {alertMessage}
              </p>
            )}

          </div>
        </AlertModal>
      )}

    </div >
  );
};

export default LandingPage;
