import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import noImg from '../img/no_image.png';

export default function ItemGroupCard(props) {
    const { item, active, setIsActive } = props;
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' })

    return (
        <div onClick={() => { setIsActive(item.PackageSubTypeCode) }}>
            {!active ? (
                <div
                    className="group-package-type-card card"
                    style={{
                        width: "fit-content",
                        height: "fit-content",
                        borderRadius: "12px",
                        cursor: "pointer",
                        userSelect: "none",
                    }}
                >
                    <div
                        className="container p-0"
                        style={{ width: isMobile ? "80px" : "150px", height: isMobile ? "60px" : "110px" }}
                    >
                        <div className="d-flex justify-content-center">
                            <LazyLoadImage
                                className="subgroup-img"
                                src={item.PictureUrl ?? ''}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = noImg;
                                }}
                            />
                        </div>
                        <div className="m-auto d-flex align-items-center justify-content-center subgroup-text active">
                            {item.PackageSubTypeName}
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className="group-package-type-card card gradient-background"
                    style={{
                        width: "fit-content",
                        height: "fit-content",
                        borderRadius: "12px",
                        cursor: "pointer",
                        userSelect: "none",
                    }}
                >
                    <div
                        className="container p-0"
                        style={{ width: isMobile ? "80px" : "150px", height: isMobile ? "60px" : "110px", margin: '3px' }}
                    >
                        <div className="d-flex justify-content-center">
                            <LazyLoadImage
                                className="subgroup-img active"
                                src={item.PictureUrl ?? ''}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = noImg;
                                }}
                            />
                        </div>
                        <div className="m-auto d-flex align-items-center justify-content-center subgroup-text">
                            {item.PackageSubTypeName}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
