import '../component/css/landindcss.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import React, { useEffect, useRef, useState } from 'react';
import notfound from '../component/img/notfound.png';

import { useTranslation } from 'react-i18next';

//Component
const NotFound = (props) => {
    const { language } = props;
    const { t } = useTranslation();

    return (
        <div className="page homepage" style={{ height: '750px' }}>
            <div style={{height:'30%',display:'flex' ,justifyContent:'center',alignItems:'center'}}>
               <h1 style={{color:'red'}}>{t('cam_notfound')}</h1> 
            </div>
            <div style={{height:'70%',display:'flex' ,justifyContent:'center'}}>
                <img src={notfound} style={{height:'80%'}}></img>
            </div>

        </div>
    );
};

export default NotFound;
