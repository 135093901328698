import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useRegister from '../../customHook/useRegister';
import { apiLink, coreApi } from '../../constants/DataPublic';

import { ReactComponent as Close } from '../img/close.svg';
import { useGlobalState } from '../../global'
import { useMediaQuery } from 'react-responsive';
import { checkoutOrderService } from '../../Services/PackageService';
import { VisitorUpdateService } from '../../Services/AccountService';
import { VisitorRegisterModel, VisitorUpdateModel } from '../../Models/AccountModel';
import SorasoButton from '../buttons/SorasoButton';
import { ReactComponent as Edit } from '../img/edit_icon.svg';
import { ReactComponent as Shield } from '../img/shield_79.svg';
import { ReactComponent as DropDown } from '../img/caret_down_icon.svg';
import FiledEditInput from '../input/FieldEditInput';

const Checkout = (props) => {
  const { cart, language, userInfo } = props;
  let { country, province, countryPhone, getCountry, getProvince, getCountryPhone } = useRegister({ language: language });
  const [paymentMethod, setPaymentMethod] = useState([]);
  const navigate = useNavigate();

  const [routePath, setRoutePath] = useGlobalState('route');
  const [requireFname, setRequireFname] = useState('');
  const [requireLname, setRequireLname] = useState('');
  const [requireID, setRequireID] = useState('');
  const [requireEmail, setRequireEmail] = useState('');
  const [requireTel, setRequireTel] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const [countryId, setCountryId] = useState(null);

  useEffect(() => {
    console.log('');
  }, [userInfo]);

  const [readOnly, setReadOnly] = useState({
    fName: true,
    lName: true,
    phone: true,
  });

  const [newValues, setNewValues] = useState({
    fName: userInfo.fName,
    lName: userInfo.lName,
    phone: userInfo.phoneNo,
    countryId: userInfo.CountryId
  });

  const [defaultValues, setDefaultValues] = useState({
    fName: userInfo.fName,
    lName: userInfo.lName,
    phone: userInfo.phoneNo,
    countryId: userInfo.CountryId
  });


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 767px)',
  });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  const { t } = useTranslation();

  useEffect(() => {
    if (userInfo == null || Object.keys(userInfo).length === 0 || (userInfo.type === 'user' && !userInfo.isLogin)) {
      // ถ้าไม่ได้ login หรือ continue as guest
      console.log('user null', userInfo);
      sessionStorage.removeItem('UserLogin');
      navigate('/guestCheckout');
    } else if (cart == null || cart.length === 0) {
      console.log('Cart 0');
      navigate('/');
    } else {
      userInfo.paymentMethod = '';
      userInfo.paymentUrl = '';

      sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
      // saveCart();
      getUserInfo()
    }
  }, []);

  useEffect(() => {
    console.log('newValues', readOnly);
  }, [readOnly]);

  const handleOnChange = (e) => {
    setNewValues({ ...newValues, [e.target.name]: e.target.value })
  }

  const handelEdit = (e) => {
    console.log('e', e);
    setReadOnly({ ...readOnly, [e]: false })

  }


  const handleCancel = () => {
    setReadOnly({ ...readOnly, fName: true, lName: true, phone: true })
    setRequireFname('');
    setRequireLname('');
    setRequireTel('');
    setNewValues(defaultValues);
  }


  const saveCart = async (e) => {

    if (userInfo !== null && (userInfo.isLogin || userInfo.type === 'guest')) {

      let orderItem = [];
      cart.map((e) => {
        orderItem.push({
          PackageCode: e.PackageCode,
          ItemCode: e.ItemCode,
          Qty: e.Qty,
          // VisitDate: e.VisitDate === null ? null : e.VisitDate.substr(5, 2) + '/' + e.VisitDate.substr(8, 2) + '/' + e.VisitDate.substr(0, 4),
          VisitDate: e.VisitDate === null ? null : e.VisitDate,
          VisitTime: e.VisitTime,
          VisitLocation: e.VisitLocation,
        });
      });

      let bodytext = {
        ConfirmationNo: userInfo.confirmationNo,
        ChannelCode: userInfo.channelCode,
        UserId: userInfo.userId,
        UserCode: userInfo.userCode,
        PromotionCode: '',
        OrderItems: orderItem,
        Prefix: routePath.replace('/', '')
      };

      // Email: userInfo.email,
      //   FirstName: userInfo.fName,
      //   LastName: userInfo.lName,
      //   PhoneNo: userInfo.phoneNo,
      //   IDCardNo: userInfo.idCardNo,
      //   telCode: userInfo.telCode,
      //   DeviceType:"",
      //   PushKey:"",
      //   DeviceKey:"",

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept-language': language },
        body: JSON.stringify(bodytext),
      };

      await fetch(apiLink + '/afcl/Package/SaveOrder/V2', requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          let pk = items['Result']['InvalidPk'].filter((a) => {
            return cart.find((b) => {
              return a.PackageCode === b.PackageCode;
            });
          });
          if (pk.length > 0) {
            setShowAlert(true)
          } else {
            if (items['Success']) {
              console.log('Success: ', items['Success']);
              userInfo.confirmationNo = items['Result'].ConfirmationNo;
              sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
              getUserInfo(items['Result'].ConfirmationNo);
              getCountry();
              getCountryPhone();
            } else if (items['fail']) {
              alert('Promo invalid')
            }
          }

        })
        .catch((error) => {
          console.log('Error: ', error);
          // alert('Error: API /afcl/Package/SaveOrder ', error)
        });
    } else {
      navigate('/signin');
    }
  };

  const getUserInfo = async (confirmationNo) => {
    // const requestOptions = {
    //   method: 'GET',
    //   headers: { 'Content-Type': 'application/json', 'accept-language': language },
    //   // body: JSON.stringify({
    //   //   ConfirmationNo: confirmationNo,
    //   //   UserCode: userInfo.userCode,
    //   // }),
    // };

    let params = {
      confirmationNo: confirmationNo,
    };

    await checkoutOrderService(userInfo.userCode, params, language)
      .then(function (data) {
        const items = data;

        userInfo.fName = items['Result']['UserInformation'].FirstName;
        userInfo.lName = items['Result']['UserInformation'].LastName;
        userInfo.email = items['Result']['UserInformation'].Email;
        userInfo.phoneNo = items['Result']['UserInformation'].PhoneNo;
        userInfo.idCardNo = items['Result']['UserInformation'].IDCardNo;
        userInfo.country = items['Result']['UserInformation'].Country;
        userInfo.province = items['Result']['UserInformation'].Province;
        userInfo.countryId = items['Result']['UserInformation'].CountryId;
        userInfo.provinceId = items['Result']['UserInformation'].ProvinceId;
        userInfo.PhoneCode = items['Result']['UserInformation'].PhoneCode;

        if (userInfo.countryId == null) {
          userInfo.telCode = 66;
        }

        sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));

        if (userInfo.countryId != null) {
          getProvince(userInfo.countryId);
        }

        getCountry();
        getCountryPhone();

        setCountryId(userInfo.countryId);
        setNewValues({ ...newValues, phone: userInfo.phoneNo, countryId: userInfo.countryId })
        setDefaultValues({ ...defaultValues, phone: userInfo.phoneNo, countryId: userInfo.countryId })

        items['Result']['PaymentMethods'].sort((a, b) => (a > b ? 1 : -1));

        setPaymentMethod(items['Result']['PaymentMethods']);

        console.log("userInfo.telCode", items)
        console.log("userInfouserInfo.countryId", userInfo)
      })
      .catch((error) => {
        console.log('Error: ', error);
        // alert('Error: API /afcl/Package/CheckOut ', error)
      });
  };

  const checkInfo = (e) => {
    e.preventDefault();

    userInfo.fName = newValues.fName;
    userInfo.lName = newValues.lName;
    userInfo.phoneNo = newValues.phone;
    userInfo.countryId = newValues.countryId;
    userInfo.telCode = newValues.countryId;

    if (userInfo.fName === '') {
      setRequireFname('warn_firstname');
    } else setRequireFname('');

    if (userInfo.lName === '') {
      setRequireLname('warn_lastname');
    } else setRequireLname('');

    // if (userInfo.idCardNo === '') {
    //   setRequireID('warn_id');
    // } else setRequireID('');

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const validMail = regex.test(userInfo.email);
    if (userInfo.email === '') {
      setRequireEmail('warn_email');
    } else if (!validMail) {
      setRequireEmail('invalid_email');
    } else setRequireEmail('');

    if (userInfo.phoneNo === '') {
      setRequireTel('warn_phone');
    } else setRequireTel('');

    // && userInfo.idCardNo 
    if (userInfo.fName != '' && userInfo.lName != '' != '' && userInfo.email != '' && validMail && userInfo.phoneNo != '') {
      updateVisitor();
    }
  };

  const updateVisitor = async () => {
    // setEdit(false);
    setReadOnly({ ...readOnly, fName: true, lName: true, phone: true })
    props.setEditGuest(false);
    console.log('UpdateVisitor :', userInfo);
    sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));

    const payload = new VisitorUpdateModel(
      userInfo.userId,
      userInfo.userCode,
      userInfo.email,
      userInfo.fName,
      userInfo.lName,
      userInfo.phoneNo,
      userInfo.idCardNo,
      userInfo.countryId,
    );

    await VisitorUpdateService(payload, language)
      .then(function (data) {
        const items = data;
        console.log('UpdateVisitor result :', items);
        if (items['Success'] === true) {
          console.log('UpdateVisitor Success = true');
          setErrMessage('');
          getUserInfo(userInfo.confirmationNo);
        } else {
          console.log('UpdateVisitor Success = false');
          setErrMessage(items['Message']);
          setReadOnly({ ...readOnly, fName: false, lName: false, phone: false })
          props.setEditGuest(true);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        // alert('Error: API /afcl/Account/visitor/update/profile ', error)
      });
  };

  useEffect(() => {
    if (userInfo.type === 'guest') {
      userInfo.telCode = countryId
    } else {
      userInfo.countryId = countryId
    }
    // userInfo.type === 'guest' ? userInfo.telCode == e.CountryId : userInfo.countryId == e.CountryId
  }, [countryId]);

  /** Register Success. **/
  const [showPopup, setShowPopup] = useState(false);
  const CloseSignupSuccess = () => setShowPopup(false);
  const ShowSignupSuccess = () => setShowPopup(true);

  return (
    <div className="col-main">
      <div className="row mb-4 justify-content-center">
        <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12" style={{ width: '100%', padding: isDesktopOrLaptop ? '0 15%' : '0 7%' }}>
          <div className="border-right-separator py-4 px-5 box-body box-border" style={{ borderRadius: '12px' }}>
            <h2 className="font-30 text-uppercase mb-1 lh-1">
              {userInfo.type === 'guest' ? t('guest_information') : t('customer_information')}
            </h2>
            <p className="color-grey mb-4">{t('proceed_checkout')}</p>

            <form>
              <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label htmlFor="exampleFormControlInput1" className="">
                  {t('firstname')} *
                </label>
                <FiledEditInput
                  name={"fName"}
                  value={newValues.fName}
                  readOnly={readOnly.fName}
                  defaultValue={defaultValues.fName}
                  placeholder={t('first_name_here')}
                  setOnChange={handleOnChange}
                  handleClick={handelEdit}
                  showEditIcon={!readOnly.fName}
                  isText={true}
                  isPhone={false}
                />
                <span className="text-danger">{t(requireFname)}</span>
              </div>

              <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label htmlFor="exampleFormControlInput1" className="">
                  {t('lastname')}*
                </label>
                <FiledEditInput
                  name={"lName"}
                  value={newValues.lName}
                  readOnly={readOnly.lName}
                  defaultValue={defaultValues.lName}
                  placeholder={t('last_name_here')}
                  setOnChange={handleOnChange}
                  handleClick={handelEdit}
                  showEditIcon={!readOnly.lName}
                  isText={true}
                  isPhone={false}
                />
                <span className="text-danger">{t(requireLname)}</span>
              </div>

              <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label htmlFor="exampleFormControlInput1" className="form-label ">
                  {' '}
                  {t('email')}*{' '}
                </label>
                <div style={{ position: 'relative' }}>
                  <input type="email" id="email" readOnly={true} className="form-control" defaultValue={userInfo.email} name="booking_guest_email" placeholder={t('email_here')} style={{ borderRadius: "10px", paddingRight: "15%" }} />
                  <Shield style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)', cursor: 'pointer', margin: '0 10px' }} />
                </div>
                <span className="text-danger">{t(requireEmail)}</span>
              </div>

              <div className="form-group mb-3">
                <label htmlFor="exampleFormControlSelect1" className="">
                  {t('country_of_residence')}
                </label>
                <div style={{ position: 'relative' }}>
                  <select className="form-control" id="country" defaultValue={newValues.countryId} style={{ borderRadius: "10px" }} readOnly disabled>
                    <option key="0" value="0">
                      -
                    </option>
                    {country
                      .map((e, key) => {
                        return (
                          <option key={e.CountryId} value={readOnly.phone ? newValues.countryId : e.CountryId} selected={e.CountryId == newValues.countryId}>
                            {e.CountryName}
                          </option>
                        );
                      })}
                  </select>
                  <DropDown style={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)', margin: '0 10px' }} />
                </div>

              </div>

              <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label htmlFor="exampleFormControlInput1" className="">
                  {t('telephone')}*
                </label>
                <div className="row">
                  <div className={isDesktopOrLaptop ? "col-3 pb-lg-0 pe-0" : "col-3 pb-lg-0 px-0"}>
                    <select className="form-control" id="telCode" onChange={(e) => (setNewValues({ ...newValues, countryId: e.target.value }))} style={{ borderRadius: "10px" }} readOnly={readOnly.phone} disabled={readOnly.phone}>
                      <option key="0" value="0">
                        -
                      </option>
                      {countryPhone.map((e, key) => {
                        return (
                          <option key={e.CountryId} value={readOnly.phone ? newValues.countryId : e.CountryId} selected={e.CountryId == newValues.countryId}>
                            {e.PhoneCode}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={isDesktopOrLaptop ? "col-9" : "col-9 pe-0"}>
                    <FiledEditInput
                      name={"phone"}
                      value={newValues.phone}
                      readOnly={readOnly.phone}
                      defaultValue={defaultValues.phone}
                      placeholder={t('phone_number') + '*'}
                      setOnChange={handleOnChange}
                      handleClick={handelEdit}
                      showEditIcon={!readOnly.phone}
                      isText={false}
                      isPhone={true}
                    />
                  </div>
                  <span className="text-danger">{t(requireTel)}</span>
                </div>
                <span className="text-danger">{ }</span>
              </div>

              <div className="row col-xl-12 col-lg-12 col-md-12 col-sm-12 align-items-center">
                <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 pb-3 pb-lg-0">
                  <p className="color-grey font-10 lh-1 mb-1">{'*' + t('indicate_required')}</p>
                  {/* <p className="color-grey font-10 lh-1 mb-0">{t('real_email')}</p> */}
                </div>
              </div>
              {(!readOnly.phone || !readOnly.fName || !readOnly.lName) && (
                <div className="row align-items-center">
                  <div className="col-6">
                    <SorasoButton
                      text={t('update')}
                      onClick={checkInfo}
                      style={'primary'}
                    />
                  </div>
                  <div className="col-6">
                    <SorasoButton
                      text={t('cancel')}
                      onClick={handleCancel}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {/* <div className="box-payment ">
          <div className="card-body my-3 mx-3">
            <h3 className="font-30 text-uppercase lh-1 mb-4 weight-700">{t('select_payment')}</h3>

            <ul className="list-unstyled">
              {routePath.toLocaleLowerCase() == '/16two' && paymentMethod.length > 0 && (
                <li
                  className="my-4 mx-3"
                  onClick={() => {
                    const radioBtn = document.getElementById('Installment');
                    radioBtn.checked = true;
                    userInfo.paymentMethod = 'Installment';
                    userInfo.paymentUrl = '';
                    userInfo.totalPrice = '';
                    sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
                  }}
                >
                  <div className="form-check box-checkpayment row">
                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-12">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="payment_method"
                          id="Installment"
                          value="Installment"
                          onChange={() => {
                            userInfo.paymentMethod = 'Installment';
                            userInfo.paymentUrl = '';
                            userInfo.totalPrice = '';
                            sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
                          }}
                        />
                        <div style={{ width: isDesktopOrLaptop ? '270px' : '204px', borderRadius: '20px', display: 'flex', justifyContent: 'center', background: '#cbcbcb', height: '25px', alignItems: 'center' }}>
                          <img src="https://gsastorages.blob.core.windows.net/afcl/CREDIT.png" height="60%" style={{ marginRight: '8px' }} /> &nbsp;
                          <label className="form-check-label">
                            {' '}
                            <>{t('visa')} ({t('Installment')})</>{' '}
                          </label>
                        </div>
                      </div>
                      <div className="col-6" style={{ display: 'flex', justifyContent: 'right' }}>
                      </div>
                    </div>
                  </div>
                </li>
              )}

              {paymentMethod.map((e, index) => {
                return (
                  <li
                    className="my-4 mx-3"
                    onClick={() => {
                      const radioBtn = document.getElementById(e.MethodName);
                      radioBtn.checked = true;
                      userInfo.paymentMethod = e.MethodName;
                      // userInfo.paymentUrl = e.PaymentUrl;
                      // userInfo.totalPrice = e.TotalPrice;
                      sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
                    }}
                  >
                    <div className="form-check box-checkpayment row">
                      <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-12">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment_method"
                            id={e.MethodName}
                            value={e.MethodName}
                            onChange={() => {
                              userInfo.paymentMethod = e.MethodName;
                              // userInfo.paymentUrl = e.PaymentUrl;
                              // userInfo.totalPrice = e.TotalPrice;
                              sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
                            }}
                          />
                          <div
                            style={{
                              width: index == 0 ? '180px' : '160px',
                              borderRadius: '20px',
                              display: 'flex',
                              justifyContent: 'center',
                              background: '#cbcbcb',
                              height: '25px',
                              alignItems: 'center',
                            }}
                          >
                            <img src={e.IconUrl} height="60%" style={{ marginRight: '8px' }} /> &nbsp;
                            <label className="form-check-label"> {index == 0 ? <>{t('visa')}</> : <>{t('bank')}</>} </label>
                            {e.MethodName}
                          </div>
                        </div>
                        <div className="col-6" style={{ display: 'flex', justifyContent: 'right' }}>
                          <img src={e.MethodLogoUrl} className="payment-2c2p" />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>

            <p className="font-12 weight-500 mb-0" style={{ color: '#F71980' }}>
              {'*' + t('indicate_required')}
            </p>
          </div>
        </div> */}
      <Modal show={showAlert} className="col-12 cart-placeorder">
        <a href="#" className="close">
          <Close className="popup-close" onClick={() => setShowAlert(false)} />
        </a>
        <h5 className="text-center text-danger py-5">{t('package_invalid')}</h5>
      </Modal>
    </div>
  );
};
export default Checkout;
