import '../component/css/landindcss.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import React, { useEffect, useRef, useState } from 'react';
import pending from '../component/img/paymentpending.png';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import SorasoButton from '../component/buttons/SorasoButton';

//Component
const PendingPay = (props) => {

    let itCrossCam = sessionStorage.getItem('itCrossCampaign');
    itCrossCam = JSON.parse(itCrossCam);

    const { language } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goHome = () => {
        if (itCrossCam) {
            window.location.href = '/'
        } else {
            navigate("/")
        }
    }

    return (
        <div className="page homepage" style={{ height: '750px' }}>
            <div style={{ height: '60%', display: 'flex', justifyContent: 'center', marginTop: '20px', alignItems: 'center' }}>
                <img src={pending} style={{ height: '80%' }}></img>
            </div>
            <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h5 style={{ fontWeight: '500', fontSize: '16px', width: '70%', textAlign: 'center' }}>{t('pending')}</h5>
            </div>
            <div style={{ height: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* <button className="btn btn-main btn-done" onClick={goHome}>{t('back')}</button> */}
                <SorasoButton
                    text={t('back')}
                    style='primary'
                    onClick={goHome}
                />
            </div>
        </div>
    );
};

export default PendingPay;
