import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Modal } from 'react-bootstrap';

import { ReactComponent as Verified } from "../../component/img/check-green.svg";
import { baseLink, apiLink, incTax } from '../../constants/DataPublic'

import logo from "../img/soraso_logo.png";
import { ReactComponent as Close } from "../img/close.svg";
import { ReactComponent as Success } from "../img/Success.svg";
import CartList from './CartList';
import { useGlobalState } from '../../global'
import SorasoButton from '../buttons/SorasoButton';
import { getBookingSession, getCartSession, getDateSession, getEventSession, getEventTimeSession, getVoucherSession, removeSession, resetBookingSesstion, setCartSession, setUserSession } from '../Helpers/BaseHelper';
import { saveOrder } from '../../Services/PackageService';
import { BlockTableModel } from '../../Models/TableBookModel';
import { Blocktable } from '../../Services/TableBookService';
import BinIcon from '../img/paymentfail.png';
import ExpireIcon from '../img/expire_icon_79.svg';
import AlertModal from '../modal/AlertModal';
import { checkPackagePromotion } from '../../Services/PackageService';
import { useToast } from '../Helpers/ToastContext';

const Cart = (props) => {

  const { packageCode, currentStep, language, cart, needCheckout, paymentGateway, publicKey, notEqualPackage } = props

  const [routePath, setRoutePath] = useGlobalState('route');
  const [promo, setPromo] = useGlobalState('discountPromo');
  const [promoCode, setPromoCode] = useGlobalState('promoCode');
  const [havePro, setHavePro] = useGlobalState('havePro');
  const [disabledPromo, setDisabledPromo] = useGlobalState('disabledPromo');
  const [subTotal, setSubTotal] = useState(0)
  // const [promo, setPromo] = useState(0)
  // const [promoCode, setPromoCode] = useState(0)
  const [total, setTotal] = useState(0)
  const [saving, setSaving] = useState(0)
  const [savingDis, setSavingDis] = useState(0);
  const [canPay, setCanPay] = useState(true)
  const { showToast } = useToast();

  const [alertCallback, setAlertCallback] = useState({});
  const [alertIcon, setAlertIcon] = useState(BinIcon);

  const navigate = useNavigate();

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  let promotion = sessionStorage.getItem('Promo');
  promotion = JSON.parse(promotion);

  useEffect(() => {
    let subT = 0
    let savT = 0
    if (cart != null) {
      if (cart.length > 0) {
        cart.forEach((e) => {
          e.Package.forEach((f) => {
            subT = subT + f.ItemPrice * f.Qty;
            savT = savT + f.ItemDiscount * f.Qty;
          })
        });
        setCanPay(true);
      } else {
        setCanPay(false);
      }
      setSubTotal(subT);
      // setSaving(savT);
      setSavingDis(savT);
    }
    // setPromo(promotion.discountPromo)
    // setPromoCode(promotion.promoCode)
    // setHavePro(promotion.havePro)
    // setTotal(subTotal - promotion.discountPromo);
    // console.log("cart", cart)
    // console.log("savT", savT)
  }, [cart])

  useEffect(() => {
    if (promotion.promoType === 'AMT') {
      setTotal(subTotal - promotion.discountPromo);
      setSaving(savingDis + promotion.discountPromo)
      setPromo(promotion.discountPromo)
    } else {
      let discount = subTotal * (promotion.discountPromo / 100)
      setTotal(subTotal - discount);
      setSaving(savingDis + promotion.discountPromo)
      setPromo(discount)
    }
    setHavePro(promotion.havePro)
    setPromoCode(promotion.promoCode)
  }, [subTotal])

  const onEditCart = (pCode, iCode) => {
    props.onEditCart(pCode, iCode)
  }

  const onRemoveCart = (pCode, iCode, tableNo) => {
    props.onRemoveCart(pCode, iCode, tableNo);
  };

  const onAddToCart = (itemCart, isEdit, tableNo) => {
    props.onAddToCart(itemCart, isEdit, tableNo);
  };

  const saveCart = async (e, showP) => {
    e.preventDefault();
    user = sessionStorage.getItem('UserLogin');
    user = JSON.parse(user);
    let event = getEventSession();
    let booking = getBookingSession();
    const voucherData = getVoucherSession();
    let tableList = [];
    console.log('SaveCart Entry, ', user, myCart);
    if (user !== null && (user.isLogin || user.type == 'guest')) {
      let orderItem = [];
      myCart.map((e) => {
        tableList.push(e.TableNo);
        e.Package.map((f, index) => {
          return orderItem.push({
            PackageCode: f.PackageCode,
            ItemCode: f.ItemCode,
            Qty: f.Qty,
            VoucherNo: f.VoucherNo,
            TableNo: e.TableNo,
          });
        })
      });

      let bodytext = {
        ArrivalDate: getDateSession() ?? "",
        ArrivalTime: getEventTimeSession() ?? "",
        ConfirmationNo: user.confirmationNo,
        ChannelCode: user.channelCode,
        UserId: user.userId,
        UserCode: user.userCode,
        PromotionCode: promoCode, //ทดสอบไว้ก่อน 
        OrderItems: orderItem,
        Prefix: routePath.replace('/', ''),
        CampaignCode: event.selectEvent.id ?? "",
        TableNo: tableList,
        BookingId: booking.bookingId,
        VoucherNo: voucherData?.VoucherNo,
      };

      console.log('SaveCart body', bodytext);
      // เช็ค api voucher หรือไม่

      // const apiPath = voucherData ? '/ECommerce/Package/Voucher/Apply' : '/afcl/Package/SaveOrder/V2'
      await saveOrder(bodytext, language, voucherData)
        .then(function (data) {
          const items = data;
          console.log('Save Cart Rightbar: ', items);
          if (items['Success']) {
            console.log('Save Cart Success: true, total = ', total);
            user.confirmationNo = items['Result'].ConfirmationNo;
            user.totalPrice = total;
            sessionStorage.setItem('UserLogin', JSON.stringify(user));
            setShowPopup(showP);
            if (voucherData) {

              if (items['Result'].ConfirmationNo === null || items['Result'].ConfirmationNo === "") {
                // Clear Session
                resetBookingSesstion();
                removeSession("MyCart");
                removeSession("Promo");
                removeSession('Voucher');
                removeSession('CurrentEvent');
                removeSession('EventTime');
                removeSession('DefaultDate');
                setUserSession(user);

                navigate('/done', {
                  state: {
                    confirmationNo: user.confirmationNo,
                    TagHtml: items.Result.TagHtml
                  }
                });
                return; // Break
              }
            }
            if (!showP) {
              navigate('/payment');
            }
          } else {
            if (!!items['Message']) {
              setAlertText(items['Message']);
            } else {
              setAlertText(t('package_invalid'));
            }
            console.log('Save Cart Success: false');
            setShowAlert(true);
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
          setAlertText(error);
          setShowAlert(true);
        });
    } else {
      navigate('/signin');
    }
  };

  const confirmOrder = async (e) => {
    user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': { 'step': 5, 'option': user.paymentMethod }
        }
      }
    });
    let table = cart.map((e, index) => {
      return { TableNo: e.TableNo }
    })
    table.sort();
    const payload = new BlockTableModel(
      table,
    );

    try {
      await Blocktable(payload, language ?? 'EN').then(async (res) => {
        if (res.Success) {
          let errorTable = res.Result.ErrorTableList.map((e) => {
            return e.TableNo;
          });

          if (errorTable !== null && errorTable.length > 0) {
            setAlertText(`Failed to book table ${errorTable.map((e) => { return e + ", " })} Please Remove`);
            setAlertIcon(BinIcon);
            await openConfirmationModal()
              .then(async (confirmed) => {
                if (confirmed) {
                  setShowAlert(false);
                  let cart = getCartSession();
                  if (cart !== null) {
                    setCartSession(cart.filter((e) => !errorTable.includes(e.TableNo)))
                  }
                } else {
                  setShowAlert(false);
                }
              })
              .catch((error) => {
                // Handle errors or user cancelation
                let cart = getCartSession();
                setCartSession(cart.filter((e) => !errorTable.includes(e.TableNo)))
                console.error(error);
              });
            return false;
          }
        } else {
          setAlertText(t('expire_reservation'));
          setAlertIcon(ExpireIcon);
          await openConfirmationModal()
            .then(async (confirmed) => {
              if (confirmed) {
                removeSession('MyCart');
                resetBookingSesstion();
              }
            })
            .catch((error) => {
              // Handle errors or user cancelation
              setShowAlert(false);
              removeSession('MyCart');
              resetBookingSesstion();
            });
          window.location.href = '/';
          return false;
        }

        await saveCart(e, false);

      }).catch((error) => {
        throw error;
      })
    } catch (error) {
      setAlertText(error);
      setShowAlert(true);
    }
  }

  const verifyPromoCode = async () => {
    await checkPackagePromotion(language ?? 'EN', promoCode)
      .then(function (data) {
        const items = data.Result;
        if (data.Success) {
          if (items.DiscountType === "AMT") {
            let totalDiscount = subTotal - items.DiscountAmt
            setPromo(items.DiscountAmt)
            setTotal(totalDiscount);
            setSaving(savingDis + items.DiscountAmt);
            sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: items.DiscountAmt, promoCode: promoCode, havePro: true, promoType: items.DiscountType }))
          } else {
            let discount = subTotal * (items.DiscountAmt / 100)
            let totalDiscount = subTotal - discount
            setPromo(discount)
            setTotal(totalDiscount);
            setSaving(savingDis + discount);
            sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: items.DiscountAmt, promoCode: promoCode, havePro: true, promoType: items.DiscountType }))
          }
          setHavePro(true)
        } else {
          setSaving(savingDis)
          setTotal(subTotal);
          // setPromoCode(null)
          setPromo(null)
          setAlertText(data.Message);
          // setShowAlert(true);
          openConfirmationModal()
            .then(async (confirmed) => {
              if (confirmed) {
                setShowAlert(false);
              } else {
                setShowAlert(false);
              }
            })
            .catch((error) => {
              // Handle errors or user cancelation
              console.error(error);
            });
          setHavePro(false)
          sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: '', promoCode: '', havePro: false, promoType: '' }))
        }
        console.log('items,', items)
      }).catch((error) => {
        console.log('Error: ', error);
        showToast(error.message + " " + error.config.url, 'error');
      });
    // const requestOptions = {
    //   method: 'GET',
    //   headers: { 'accept-language': language },
    // };
    // let toPro = promoCode == '' ? null : promoCode
    // let prefix = routePath != '' ? '?Prefix=' + routePath.replace('/', '') : ''
    // await fetch(apiLink + '/afcl/Package/VerifyPromotionCode/' + toPro + prefix, requestOptions)
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (data) {
    //     const items = data;
    //     if (items.Status) {
    //       if (items.DiscountType === "AMT") {
    //         let totalDiscount = subTotal - items.DiscountAmt
    //         setPromo(items.DiscountAmt)
    //         setTotal(totalDiscount);
    //         setSaving(savingDis + items.DiscountAmt);
    //         sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: items.DiscountAmt, promoCode: promoCode, havePro: true, promoType: items.DiscountType }))
    //       } else {
    //         let discount = subTotal * (items.DiscountAmt / 100)
    //         let totalDiscount = subTotal - discount
    //         setPromo(discount)
    //         setTotal(totalDiscount);
    //         setSaving(savingDis + discount);
    //         sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: items.DiscountAmt, promoCode: promoCode, havePro: true, promoType: items.DiscountType }))
    //       }
    //       setHavePro(true)
    //     } else {
    //       setSaving(savingDis)
    //       setTotal(subTotal);
    //       setPromoCode(null)
    //       setPromo(null)
    //       setAlertText('promo_notavai');
    //       setShowAlert(true);
    //       setHavePro(false)
    //       sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: '', promoCode: '', havePro: false, promoType: '' }))
    //     }
    //     console.log('items,', items)
    //   })
    //   .catch((error) => {
    //     console.log('Error: ', error);
    //     setAlertText(error);
    //     setShowAlert(true);
    //   });


    // let totalPrice = subTotal - promo;
    // let totalSaving = saving + promo;
    // setTotal(totalPrice);
    // setSaving(totalSaving);
    // console.log('saving', saving);
    // console.log('promo', promo);
    // console.log('totalPrice', totalPrice);
    // console.log('Promo Code', totalSaving);
    // console.log('user.confirmationNo', user.confirmationNo);
  };

  const setCartUpdate = (cartUpdate) => {
    props.setCartUpdate(cartUpdate)
  }

  /** Register Success. **/
  const [showPopup, setShowPopup] = useState(false);
  const CloseSignupSuccess = () => setShowPopup(false);
  const ShowSignupSuccess = () => setShowPopup(true);

  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");

  // ภาษา
  const { t } = useTranslation();

  const openConfirmationModal = () => {
    return new Promise((resolve) => {
      setShowAlert(true);

      const handleConfirm = () => {
        resolve(true); // User confirmed the change
      };

      const handleCancel = () => {
        resolve(false); // User canceled the change
      };

      // Set the ConfirmationModal callbacks
      setAlertCallback({ confirm: handleConfirm, cancel: handleCancel });
    });
  };

  const handleChangePromo = event => {
    const result = event.target.value.replace(/[^A-Za-z0-9]/gi, '');
    setPromoCode(result);
  };

  return (
    //START RIGHT CONTENT
    <div className="cart-mobile mb-5" >
      {/* คูปอง */}
      <div className="d-block d-lg-none">
        <br />
        {!disabledPromo && (
          <>
            {!havePro ?
              <div className="box mb- py-3 px-3 bg-white"
                style={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
              >
                <div className="row">
                  <div className="col-12 ">
                    <h5 className="font-14">{t('enter_promo_code')}</h5>
                  </div>
                  <div className="col-12 mb-3">
                    {!canPay ?
                      <input className="form-control bg-79-sand" type="text" placeholder={t('promocode_here')} readOnly />
                      :
                      <input className="form-control bg-79-sand" type="text" placeholder={t('promocode_here')} value={promoCode} onChange={(e) => { handleChangePromo(e) }} />
                    }
                  </div>
                  <div className="col-12 text-end">
                    {/* <button className="btn btn-dark" type="button" onClick={verifyPromoCode}>Verify</button> */}
                    <SorasoButton
                      text={t('verify')}
                      style='primary'
                      onClick={verifyPromoCode}
                      disabled={promoCode === ""}
                    />
                  </div>
                </div>
              </div>
              :
              <div className="box mb- py-3 px-3 bg-white"
              // style={{ backgroundColor: '#DEDEDE' }}
              >
                <div className="row">
                  <div className="col-12 ">
                    <h5 className="font-20 text-start">{t('promo_code')}</h5>
                  </div>
                  <div className="col-12">
                    <p className="color-grey font-18 weight-600 mb-0 text-start">{promoCode}</p>
                  </div>
                  <div className="col-12 ps-0 text-end">
                    <p className="color-green font-18 weight-600 mb-0">{t('verified')} <Verified className="img-" /></p>
                  </div>
                </div>
              </div>
            }
          </>
        )}
      </div>

      {/* คูปอง */}


      <div className="box box-border">
        {!needCheckout && (
          <div>
            <div className="box-cart-header">
              <div className="cart-title">
                <span className="icon material-icons">shopping_cart</span><span>{t('cart')}</span>
              </div>
            </div>

            <div className="box-body ">
              <div className="row">
                <div className="col-6">
                  <p className="font-12 weight-400 color-grey mb-0">{t('item')}</p>
                </div>
                <div className="col-6">
                  <p className="font-12 weight-400 color-grey text-end mb-0">{t('price')}</p>
                </div>
                <div className="col-12"><hr /></div>
              </div>
              {/* {
                cart.map((e, index) => {
                  let checkLast = false
                  if (e.step == 1 && cart.some(x => x.step != 1) && cart.filter(x => x.step == 1).length == 1) {
                    checkLast = true
                  }
                  return (
                    <CartList currentStep={currentStep} checkLastItem={checkLast} cartItem={e} setCartUpdate={setCartUpdate} onRemoveCart={onRemoveCart} onAddToCart={onAddToCart} />

                  )
                })
              } */}

              {cart && cart.length > 0 && (
                cart.map((e, index) => {
                  // console.log("Cart Map", e);
                  let pk = e.Package.map((item, index) => {
                    // console.log("Package Map", item);
                    let checkLast = false
                    if (item.step == 1 && cart.some(x => x.Package.step != 1) && cart.filter(x => x.Package.step == 1).length == 1) {
                      checkLast = true
                    }
                    // console.log("Checking last item", checkLast);
                    return <CartList key={index} currentStep={currentStep} checkLastItem={checkLast} tableNo={e.TableNo} cartItem={item} setCartUpdate={setCartUpdate} onRemoveCart={onRemoveCart} onAddToCart={onAddToCart} />;
                  })

                  return <>
                    {/* Table */}
                    <div className='p-2 mt-1'>
                      <div className='cart-table-head p-3'>
                        <div className='row'>
                          <div className='col gradient-text'><h4>{t('table')} {e.TableNo}</h4></div>
                        </div>
                        <div className='row'>
                          <div className='col weight-500'>{t('arrival_date')}</div>
                          <div className='col weight-500 text-end'>{e.ArrivalDate}</div>
                        </div>
                      </div>
                    </div>
                    {/* Package Description */}
                    <div className='p-3 pt-1 pb-0'>{pk}</div>
                  </>
                })
              )
              }
            </div>
          </div>
        )}

        <div className="box-body" style={{ minHeight: 100 }}>
          <div className="row align-items-center">
            <div className="col-6">
              <p className="font-14 weight-400 color-grey mb-0">{t('subtotal')}</p>
            </div>
            <div className="col-6 text-end">
              <p className="font-18 weight-700 mb-0">{subTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</p>
            </div>
            <div className="col-12"><hr /></div>
          </div>
          <div className="row align-items-center ">
            <div className="col-6">
              <p className="font-14 weight-400 color-grey mb-0">{t('promotion')}</p>
            </div>
            <div className="col-6 text-end">
              <p className="font-18 weight-700 mb-0">
                {promotion.promoType == 'AMT' ?
                  <>
                    {promotion.discountPromo && (
                      <>
                        {'- ' + promotion.discountPromo.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                      </>
                    )}
                  </>
                  :
                  <>
                    {promotion.discountPromo && (
                      <>
                        {subTotal == 0 ?
                          <>

                          </>
                          :
                          <>
                            {'- ' + (subTotal * (promotion.discountPromo / 100)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                          </>
                        }

                      </>
                    )}
                  </>
                }
              </p>
            </div>
            {/* <div className="col-sm-12"><hr /></div> */}
          </div>
        </div>
        <div className="box-body bg-79-sun">
          <div className="row align-items-center">
            <div className="col-6 pe-0">
              <p className="font-24 weight-700 mb-0">{t('total')} <span className="font-10 color-grey weight-400">{t('incl_tax')} ({incTax}%)</span></p>
            </div>
            <div className="col-6 text-end ps-0">
              <p className="font-20 weight-700 color-black mb-0">
                {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
              </p>
            </div>
          </div>
        </div>
        <div className="box-body pb-4">
          <div className="row align-items-center mb-4">
            <div className="col-6">
              <p className="font-14 weight-400 color-grey mb-0">{t('total_saving')}</p>
            </div>
            <div className="col-6 text-end">
              {/* <p className="font-18 weight-700 mb-0">{saving.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} {t('thb')}</p> */}
              <p className="font-18 weight-700 mb-0">{parseFloat(saving).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</p>
            </div>
          </div>
          <div className="row align-items-center">
            {currentStep < 4 && (
              <>
                <div className="col-12">
                  <a href={baseLink + routePath + "/package/" + packageCode + "/4"}><button className="btn gradient-background" type="button">{t('checkout')}</button></a>
                </div>
                <div className="col-12 relative"><hr /><span className="or">{t('or')}</span></div>
                <div className="col-12">
                  <a href={baseLink + routePath + "/package/" + packageCode + "/" + (currentStep + 1)}><button className="btn secondary-background mb-3" type="button" >{t('next')}</button></a>
                </div>
              </>
            )}

            {!needCheckout && (
              <div>
                <div className="col-12">
                  {/* <button className={`btn gradient-background mb-3 ${props.editGuest ? 'inactive' : ''}`} type="button" onClick={() => props.setNeedCheckout(true)} >{t('gotocheckout')}</button> */}
                  <SorasoButton
                    text={t('gotocheckout')}
                    style='primary'
                    disabled={props.editGuest}
                    onClick={() => props.setNeedCheckout(true)}
                  />
                </div>
                {/* {routePath !== "" && (
                <div className="col-12">
                  <button className="btn btn-green" type="button" onClick={(e) => saveCart(e, true)}>
                    {t('save_cart')}
                  </button>
                </div>
              )} */}
              </div>
            )}

            {needCheckout && (
              <div className="col-12">
                {/* <button className={`btn gradient-background mb-3 ${props.editGuest ? 'inactive' : ''}`} type="button" onClick={confirmOrder} disabled={!canPay}>{t('confirm_payment')}</button> */}
                <SorasoButton
                  text={t('confirm_payment')}
                  style='primary'
                  disabled={!canPay}
                  onClick={confirmOrder}
                />
              </div>
            )}

            {/* <Modal show={showPopup} className="col-12 cart-savecart">
              <a href="#" className="close"  ><Close className="popup-close" onClick={CloseSignupSuccess} /></a>
              <div className="logosignin"> <img src={logo} className="logo-popup" /></div>
              <div className="text-center mb-3">
                <Success className="img-popup" />
              </div>
              <p className="text-center text-color">{t('save_complete')}</p>
            </Modal>

            <Modal show={showAlert} className="col-12 cart-placeorder">
              <a href="#" className="close"  ><Close className="popup-close" onClick={() => setShowAlert(false)} /></a>
              <h5 className="text-center text-danger">{t(alertText)}</h5>
            </Modal> */}

            {showAlert && (
              <AlertModal
                openModal={showAlert}
                onCloseModal={() => setShowAlert(false)}
                onConfirm={alertCallback.confirm}
                isShowCancle={false}
              >
                <div className='d-flex flex-column justify-content-around align-items-center' style={{ height: '100%' }}>
                  <div className='mb-3'>
                    <img height={150} src={alertIcon} />
                  </div>
                  <p className='' style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                    {alertText}
                  </p>
                </div>
              </AlertModal>
            )}
          </div>
        </div>
      </div>

    </div>
    //END RIGHT CONTENT
  )
}
export default Cart;