/* ----------- Common ----------- */
export const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

/* ----------- String Convert ----------- */
export const convertDateToISO = (date) => {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    return formattedDate
}

export const formatToCurrency = (amount) => {
    const resultPrice = amount
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
};

export const getErrorMessage = (error) => {
    if (error === undefined || error === null) {
        return "Error:";
    }
    const errorMessages = [];
    for (const propertyName in error) {
        if (error.hasOwnProperty(propertyName)) {
            errorMessages.push(error[propertyName][0]);
        }
    }
    return errorMessages.toString();
}

/* ----------- Table Status ----------- */
export const getTableStatusValue = (status) => {
    const tableStatus = [
        { status: 'CRT', value: 'Add To Cart' },
        { status: 'VC', value: 'Available' },
        { status: 'OO', value: 'Out of Order' },
        { status: 'RR', value: 'Reservation' },
        { status: 'OC', value: 'Occupied' }
    ];
    let result = tableStatus.find((e) => {
        if (e.status.toUpperCase() === status.toUpperCase()) return e;
    });

    return result;
}

/* ----------- remove Session with parameter ----------- */
export function removeSession(sessionName) {
    sessionStorage.removeItem(sessionName);
}


/* ----------- Cart ----------- */
export function getCartSession() {
    let cart = sessionStorage.getItem("MyCart");
    return JSON.parse(cart);
}

export function setCartSession(myCart) {
    let cart = JSON.stringify(myCart);
    sessionStorage.setItem("MyCart", cart);
}

export function getCountCart(myCart) {
    let count = 0;
    if (myCart !== null || myCart?.length > 0) {
        myCart.forEach((e) => {
            e.Package?.forEach((x) => {
                count += (x.Qty);
            })
        })
        console.log("count cart", count)
        return count
    } else {
        return 0
    }
}

/* ----------- DefaultDate ----------- */
export function getDateSession() {
    let date = sessionStorage.getItem("DefaultDate");
    if (date !== null) {
        date = JSON.parse(date).split('T')[0];;
        // console.log("request", date)
        return date;
    } else {
        return null;
    }
}

export function setDateSession(data) {
    let date = JSON.stringify(data);
    sessionStorage.setItem("DefaultDate", date);
}

/* ----------- CurrentEvent ----------- */
export function getEventSession() {
    let event = sessionStorage.getItem("CurrentEvent");
    return JSON.parse(event);
}

export function setEventSession(eventInfo) {
    let event = JSON.stringify(eventInfo);
    sessionStorage.setItem("CurrentEvent", event);
}

/* ----------- BookingInfo ----------- */
export function getBookingSession() {
    let booking = sessionStorage.getItem("BookingInfo");
    return JSON.parse(booking);
}

export function setBookingSession(bookingInfo) {
    let booking = JSON.stringify(bookingInfo);
    sessionStorage.setItem("BookingInfo", booking);
}

export function resetBookingSesstion() {
    let booking = JSON.stringify({ bookingId: 0 });
    sessionStorage.setItem("BookingInfo", booking);
}

/* ----------- EventTime ----------- */
export function getEventTimeSession() {
    let event = sessionStorage.getItem("EventTime");
    return JSON.parse(event);
}

export function setEventTimeSession(eventTime) {
    let event = JSON.stringify(eventTime);
    sessionStorage.setItem("EventTime", event);
}

/* ----------- Voucher ----------- */
export function getVoucherSession() {
    let voucher = sessionStorage.getItem("Voucher");
    return JSON.parse(voucher);
}

export function setVoucherSession(voucherData) {
    let data = JSON.stringify(voucherData);
    sessionStorage.setItem("Voucher", data);
}

/* ----------- UserInfo ----------- */
export function getUserSession() {
    let user = sessionStorage.getItem("UserLogin");
    return JSON.parse(user);
}

export function setUserSession(user) {
    let data = JSON.stringify(user);
    sessionStorage.setItem("UserLogin", data);
}

/* ----------- Event ----------- */
export function getEventStateSession() {
    let event = sessionStorage.getItem("EventState");
    if (event === undefined) {
        removeSession('EventState');
        return null;
    } else {
        return JSON.parse(event);
    }
}

export function setEventStateSession(event) {
    let data = JSON.stringify(event);
    sessionStorage.setItem("EventState", data);
}