import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";

import { Link, Element } from "react-scroll";
import styled from "styled-components";

import { SubItemGroup } from './SubItemGroup';

import { ReactComponent as FoodIcon } from "../../component/img/fnb.svg";
import { ReactComponent as Verified } from "../../component/img/check-green.svg";
import { ReactComponent as PopularIcon } from "../../component/img/most-popular.svg";
import { ReactComponent as ServiceIcon } from "../../component/img/service.svg";
import { ReactComponent as CameraIcon } from "../../component/img/camera.svg";

import { baseLink, apiLink } from '../../constants/DataPublic'
import { GetFBAddOn } from '../../constants/BaseApi';
import { getFB } from '../../Services/PackageService';

// const PaddedDiv = styled(Link)`
//   padding-top: 10px;
//   .active {
//     color: blue;
//   }
// `;

const Step3 = (props) => {

  const { packageCode, currentStep, language, cartUpdate, itemList, tableNo } = props

  const [packageSubTypeCode, setPackageSubTypeCode] = useState("")

  // const [itemList, setItemList] = useState([])
  const [itemSubGroup, setItemSubGroup] = useState([])

  const focusSection = useRef([])

  const gotoSelectedSection = () => {
    window.scrollTo({
      top: focusSection.current.offsetTop - 230,
      behavior: "smooth"
    })
  }


  useEffect(() => {
    getPackageCard()
    window.scrollTo(0, 0);
  }, [])

  const getPackageCard = async () => {
    await getFB(language)
      .then((res) => {
        console.log("Step 4: ", res)
        let iList = res["Result"]["Packages"]
        let sList = res["Result"]["PackageSubTypes"]
        let pSubTypeCode = iList.filter(e => e.PackageCode === packageCode)
        // setItemList(iList)
        setItemSubGroup(sList)

        if (pSubTypeCode.length > 0) {
          setPackageSubTypeCode(pSubTypeCode[0].PackageSubTypeCode)
          // gotoSelectedSection()
        }
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/Extra/ ', error)
      });
  }

  const onAddToCart = (itemCart, isEdit) => {
    props.onAddToCart(itemCart, isEdit, tableNo)
  }

  // ภาษา
  const { t } = useTranslation();

  return (
    <div className="col-main pe- step4">
      {/* <header className="sticky-top-Item">
        <div className="display-none display-block">
          <div className="row py-3 px-3">
            <div className="col-7 text-end">
              <div className="d-flex align-items-center">
                <FoodIcon className="mb-0 me-3" />
                <h2 className="mb-0 lh-1 font-24 text-uppercase">{t('food_beverage')}</h2>
              </div>
            </div>
            <div className="col-5 text-end">
              <button className="btn btn-blue btn-skip" type="button" onClick={() => props.setCurrentStep(3)}>{t('skip')}</button>
            </div>
          </div>
        </div>
      </header> */}

      <div className="d-block d-lg-none py-2 border-bottom box-dec-step bg-79-sun">
        <p className="text-center mb-0 weight-700" style={{ paddingTop: "5px" }}>{t('food_beverage')}</p>
      </div>


      <div className="FlexDiv">
        {/* <div className="LeftColumn">
          <ul className="nav nav-pills flex-lg-column indicator ItemGroup">
            {
              itemSubGroup.map((e, index) => {
                return <li><PaddedDiv className="nav-link" spy={true} smooth={true} to={e.PackageSubTypeCode} containerId="containerElement" style={{ cursor: "pointer" }}>{e.PackageSubTypeName}</PaddedDiv></li>
              })
            }
          </ul>
        </div> */}


        <div id="containerElement" className="RightColumn step4">
          {itemSubGroup.map((e, index) => {
            return (
              <div key={index}>
                <li className="mb-4 StyledElement" name={e.PackageSubTypeCode} id={e.PackageSubTypeCode}>
                  {e.PackageSubTypeName === "Most Popular" && (<PopularIcon className="mb-3 me-3" />)}
                  {e.PackageSubTypeName === "Services & Facilities" && (<ServiceIcon className="mb-3 me-3" />)}
                  {e.PackageSubTypeName === "Nearby Attractions" && (<CameraIcon className="mb-3 me-3 " />)}

                  {e.PackageSubTypeCode === packageSubTypeCode && (
                    <span ref={focusSection}></span>
                  )}
                  <span className="h2 mb-3 lh-1 text-uppercase">{e.PackageSubTypeName}</span>
                  <SubItemGroup itemSubGroup={e.PackageSubTypeCode} itemList={itemList} currentStep={currentStep} packageCode={packageCode} cartUpdate={cartUpdate} onAddToCart={onAddToCart} />

                </li>

              </div>
            )
          })
          }
          <div className="box-space"></div>
        </div>

      </div>
    </div>
  )
}

export default Step3;
