import axios from 'axios';
import { apiLink } from '../constants/DataPublic';

let apiUrl = apiLink;

const defaultHeaders = {
    'Content-Type': 'application/json',
};

const axiosInstance = axios.create({
    baseURL: apiUrl,
    headers: defaultHeaders,
    timeout: 60000,
});

axiosInstance.interceptors.request.use((config) => {
    // Header
    config.headers['Accept-Language'] = config.headers['Accept-Language'] || 'EN';
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        // Centralized error handling
        if (error.response) {
            if (error.response.status === 400) {
                console.error('Bad Request:', error.response.data);
            } else {
                console.error('HTTP Status:', error.response.status);
                console.error('Response:', error.response.data);
            }
        } else if (error.request) {
            console.error('Server Not Responding');
        } else {
            console.error('Request Failed:', error.message);
        }
        throw error; // Re-throw the error to propagate it to the caller
    }
);

export const setApiBaseUrl = (url) => {
    apiUrl = url;
    axiosInstance.defaults.baseURL = url;
};

export async function makeApiRequest(url, language, method = 'get', data = null, additionalHeaders = {}, params = null, apiUrl = apiLink) {
    let originalBaseUrl = axiosInstance.defaults.baseURL;

    try {
        if (apiUrl !== apiLink) {
            setApiBaseUrl(apiUrl);
        }

        const fullUrl = `${axiosInstance.defaults.baseURL}${url}`;
        const payload = {
            method,
            url: fullUrl,
            params: params,
            headers: {
                'Accept-Language': language || 'EN',
                ...defaultHeaders,
                ...additionalHeaders,
            },
            data,
        };

        console.log(`Request ${method.toUpperCase()} ${url}`, payload);

        const response = await axiosInstance(payload);

        console.log(`Response ${url}`, response.data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        // Reset the base URL to the original value
        setApiBaseUrl(apiLink);
    }
}

export default axiosInstance;
