import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { ReactComponent as Close } from "../img/close.svg";
import { delay, getBookingSession, getDateSession, getEventSession } from '../Helpers/BaseHelper';
import { apiLink } from '../../constants/DataPublic';
import { UnblockTable } from '../../constants/BaseApi';
import AlertModal from '../modal/AlertModal';
import BinIcon from '../img/bin_icon_79.svg';
import ExpireIcon from '../img/expire_icon_79.svg';
import { UnBlocktable } from '../../Services/TableBookService';
import { BlockTableModel } from '../../Models/TableBookModel';

const CartList = (props) => {

    const { cartItem, tableNo, mainTable } = props
    const { currentStep } = props
    const { checkLastItem } = props

    const [itemQty, setItemQty] = useState(cartItem.Qty)

    const [removePackageCode, setRemovePackageCode] = useState("")
    const [removeItemCode, setRemoveItemCode] = useState('')
    const [removeStep, setRemoveStep] = useState("5")

    const [showConfirm, setShowConfirm] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        console.log("CartList :", cartItem);
    }, []);

    useEffect(() => {
        setItemQty(cartItem.Qty)
        console.log('cartItem : CartList', cartItem)
    }, [cartItem])

    const addToCart = (item, isEdit, tableNo) => {
        props.onAddToCart(item, isEdit, tableNo)
    }

    // ภาษา
    const { t, i18n } = useTranslation();

    async function CallUnblockTable(tableNo) {
        let event = getEventSession();
        let booking = getBookingSession();

        const payload = new BlockTableModel(
            [{ TableNo: tableNo }],
        )

        try {
            await UnBlocktable(payload, i18n.language ?? 'EN').then(async (response) => {
                if (response.Success) {
                    const data = response.Result;
                    console.log(data);
                    if (mainTable === tableNo) {
                        navigate("/package/" + removePackageCode + "/" + currentStep)
                        window.location.reload()
                    }
                    window.location.reload();
                } else {
                    throw (response.Result.Message);
                }
            }).catch((error) => {
                throw error;
            });
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    return (
        <>
            <div className="row" id="index">
                <div className="col-7">
                    <p className="font-18 weight-700 mb-0 text-break">{cartItem.PackageName !== '' ? cartItem.PackageName : cartItem.ItemName}</p>
                    {cartItem.VoucherNo ? `( ${t('voucher')})` : ''}
                    <p className="font-18 weight-700 mb-0">{cartItem.PackageShortDescription}</p>
                    {/* <p className="font-14 weight-400 color-grey mb-0">{(cartItem.ItemCode === "" ? (cartItem.PriceUnit == null ? "" : cartItem.PriceUnit) : cartItem.ItemName) + (cartItem.VisitDate != "" && cartItem.VisitDate != null ? t('visitdate') + ": " + cartItem.VisitDate.substr(8, 2) + "/" + cartItem.VisitDate.substr(5, 2) + "/" + cartItem.VisitDate.substr(0, 4) : "")}</p> */}
                    <p className="font-18 weight-700 mb-0">{(cartItem.ItemPrice * itemQty).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</p>
                </div>
                <div className="col-5 text-end">
                    <div className="font-12 weight-400 color-grey mb-0 h-100 d-flex">
                        <div className="input-group ms-auto-cart mt-2">
                            <span className="input-group-btn d-flex align-items-center">
                                <button type="button" className="btn  btn-number minus" data-type="minus" data-field="quant[1]"
                                    style={cartItem.VoucherNo ? { display: 'none' } : null}
                                    disabled={cartItem.VoucherNo}
                                    onClick={() => {
                                        if (itemQty > 1) {
                                            let newQty = itemQty - 1
                                            setItemQty(itemQty => newQty)
                                            addToCart({
                                                PackageCode: cartItem.PackageCode,
                                                PackageName: cartItem.PackageName,
                                                ItemCode: cartItem.ItemCode,
                                                ItemName: cartItem.ItemName,
                                                Qty: newQty,
                                                Tax: 0,
                                                ItemPrice: cartItem.ItemPrice,
                                                ItemDiscount: cartItem.ItemDiscount,
                                                PriceName: cartItem.PriceName,
                                                PriceUnit: cartItem.PriceUnit,
                                                TotalPrice: (newQty) * cartItem.ItemPrice,
                                                VisitDate: cartItem.VisitDate,
                                                VisitTime: cartItem.VisitTime,
                                                VisitLocation: cartItem.VisitLocation,
                                                step: cartItem.step,
                                                status: 'normal',
                                                isMain: false,
                                                VoucherNo: cartItem.VoucherNo,
                                            }, true, tableNo
                                            )
                                            props.setCartUpdate({
                                                PackageCode: cartItem.PackageCode,
                                                ItemCode: cartItem.ItemCode,
                                                Qty: newQty,
                                                TotalPrice: (newQty) * cartItem.ItemPrice,
                                                step: cartItem.step
                                            })
                                            //   }

                                        }
                                        else {
                                            setRemovePackageCode(cartItem.PackageCode)
                                            setRemoveItemCode(cartItem.ItemCode)
                                            setRemoveStep(cartItem.step)
                                            setShowConfirm(true)
                                        }
                                    }}>
                                    <span className="material-icons">remove</span>
                                </button>
                            </span>
                            <input type="text" name="quant[1]" className="form-control input-number d-flex align-items-center h-100" style={{ marginLeft: "-3px" }} value={itemQty} min={0} disabled />
                            <span className="input-group-btn d-flex align-items-center">
                                <button type="button" className="btn btn-number plus" data-type="plus" data-field="quant[1]"
                                    disabled={cartItem.VoucherNo}
                                    style={cartItem.VoucherNo ? { display: 'none' } : null}
                                    onClick={() => {
                                        let newQty = itemQty + 1
                                        setItemQty(itemQty => newQty)
                                        addToCart({
                                            PackageCode: cartItem.PackageCode,
                                            PackageName: cartItem.PackageName,
                                            ItemCode: cartItem.ItemCode,
                                            ItemName: cartItem.ItemName,
                                            Qty: newQty,
                                            Tax: 0,
                                            ItemPrice: cartItem.ItemPrice,
                                            ItemDiscount: cartItem.ItemDiscount,
                                            PriceName: cartItem.PriceName,
                                            PriceUnit: cartItem.PriceUnit,
                                            TotalPrice: (newQty) * cartItem.ItemPrice,
                                            VisitDate: cartItem.VisitDate,
                                            VisitTime: cartItem.VisitTime,
                                            VisitLocation: cartItem.VisitLocation,
                                            step: cartItem.step,
                                            status: 'normal',
                                            isMain: false,
                                            VoucherNo: cartItem.VoucherNo,
                                        }, true, tableNo
                                        )
                                        props.setCartUpdate({
                                            PackageCode: cartItem.PackageCode,
                                            ItemCode: cartItem.ItemCode,
                                            Qty: newQty,
                                            TotalPrice: (newQty) * cartItem.ItemPrice,
                                            step: cartItem.step
                                        })
                                    }}>
                                    <span className="material-icons">add</span>
                                </button>
                            </span>
                            {cartItem.VoucherNo && (
                                <span className="input-group-btn d-flex align-items-center">
                                    <button type="button" className="btn  btn-number minus" data-type="minus" data-field="quant[1]"
                                        style={{ borderColor: 'red' }}
                                        onClick={() => {
                                            if (itemQty > 1) {
                                                let newQty = itemQty - 1
                                                setItemQty(itemQty => newQty)
                                                addToCart({
                                                    PackageCode: cartItem.PackageCode,
                                                    PackageName: cartItem.PackageName,
                                                    ItemCode: cartItem.ItemCode,
                                                    ItemName: cartItem.ItemName,
                                                    Qty: newQty,
                                                    Tax: 0,
                                                    ItemPrice: cartItem.ItemPrice,
                                                    ItemDiscount: cartItem.ItemDiscount,
                                                    PriceName: cartItem.PriceName,
                                                    PriceUnit: cartItem.PriceUnit,
                                                    TotalPrice: (newQty) * cartItem.ItemPrice,
                                                    VisitDate: cartItem.VisitDate,
                                                    VisitTime: cartItem.VisitTime,
                                                    VisitLocation: cartItem.VisitLocation,
                                                    step: cartItem.step,
                                                    status: 'normal',
                                                    isMain: false,
                                                    VoucherNo: cartItem.VoucherNo,
                                                }, true, tableNo
                                                )
                                                props.setCartUpdate({
                                                    PackageCode: cartItem.PackageCode,
                                                    ItemCode: cartItem.ItemCode,
                                                    Qty: newQty,
                                                    TotalPrice: (newQty) * cartItem.ItemPrice,
                                                    step: cartItem.step
                                                })
                                                //   }

                                            }
                                            else {
                                                setRemovePackageCode(cartItem.PackageCode)
                                                setRemoveItemCode(cartItem.ItemCode)
                                                setRemoveStep(cartItem.step)
                                                setShowConfirm(true)
                                            }
                                        }}>
                                        <span className="material-icons" style={{ color: 'red' }}>delete</span>
                                    </button>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                {checkLastItem && (<div className="col-12"><hr /></div>)}
                <AlertModal
                    openModal={showConfirm}
                    onCloseModal={() => setShowConfirm(false)}
                    onConfirm={async () => {
                        if (cartItem.isMain) await CallUnblockTable(tableNo);
                        props.onRemoveCart(removePackageCode, removeItemCode, tableNo)
                        setShowConfirm(false)
                        window.location.reload()
                    }}
                    isShowCancle={true}
                >
                    <div className='d-flex flex-column justify-content-around align-items-center' style={{ height: '100%' }}>
                        <div className='mb-3'>
                            <img height={100} src={BinIcon} />
                        </div>
                        <p className='' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {checkLastItem ?
                                <h5 className="text-center py-4">{t('confirm_delete_package')}</h5>
                                :
                                <h5 className="text-center py-4">{t('confirm_delete_cart')}</h5>
                            }
                        </p>
                    </div>

                </AlertModal>
                {/* <Modal show={showConfirm} className="col-12 delete-Item">
                    <a href="#" className="close"  ><Close className="popup-close" onClick={() => setShowConfirm(false)} /></a>
                    {checkLastItem ?
                        <h5 className="text-center py-4">{t('confirm_delete_package')}</h5>
                        :
                        <h5 className="text-center py-4">{t('confirm_delete_cart')}</h5>
                    }


                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn-blue mb-3" type="button"
                                onClick={() => {
                                    props.onRemoveCart(removePackageCode, removeItemCode, tableNo)
                                    if (currentStep != 4) {
                                        navigate("/package/" + removePackageCode + "/" + currentStep)
                                        window.location.reload()
                                    }
                                    
                                    setShowConfirm(false)
                                }}>{t('confirm')}</button>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-outline-secondary mb-3" type="button" onClick={() => setShowConfirm(false)}>{t('cancel')}</button>
                        </div>
                    </div>
                </Modal> */}
            </div>
            <hr />
        </>
    )
}

export default CartList
