import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { zeroPad } from "react-countdown";
import { ReactComponent as Close } from "../component/img/close.svg";
import { delay, removeSession } from "../component/Helpers/BaseHelper";
import { allPayment, checkPaymentStatus } from "../Services/PaymentService";
import { PaymentModel } from "../Models/PaymentModel";

//Image

const Payment = (props) => {

  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  let Promo = sessionStorage.getItem('Promo')
  Promo = JSON.parse(Promo)

  let paymentHis = sessionStorage.getItem('paymentHis')
  paymentHis = JSON.parse(paymentHis)

  const params = new URLSearchParams(window.location.search)

  const { language } = props
  const [webPaymentUrl, setWebPaymentUrl] = useState("")
  const [invoiceNo, setInvoiceNo] = useState("")
  const [total, setTotal] = useState(0)
  const [qrExpired, setQrExpired] = useState(600000)
  const [paymentSuccess, setPaymentSuccess] = useState(null)
  const [showAlert, setShowAlert] = useState(false);
  const [loadBackdrop, setLoadBackdrop] = useState(true);
  const [alertText, setAlertText] = useState("");

  const navigate = useNavigate()

  useEffect(() => {
    console.log("Payment: ", user);
    let confirm = params.get('ConfirmationNo') //
    let userCode = params.get('UserCode') //
    console.log("confirm", confirm)
    if (paymentHis && !confirm && !userCode) {
      navigate("/package/" + myCart[0].PackageCode + "/4")
      sessionStorage.removeItem('paymentHis')
    } else {
      if (user != null && user != undefined) {
        setTotal(user.totalPrice)
        sessionStorage.removeItem('paymentHis')
        if (confirm && userCode) {
          setInvoiceNo(true)
          clearCart(false)
        } else {
          getPayment()
        }
      } else navigate('/')
    }

  }, [])

  useEffect(() => {
    if (user.InvoiceNumber != "") {
      // const interval = setInterval(() => {
      //   clearCart(true)
      // }, checkStatusTimerMS)
      // return () => clearInterval(interval)
      // clearCart(true);
    }
  }, [user.InvoiceNumber])

  const getPayment = async () => {
    // const requestOptions = {
    //   method: "GET",
    //   headers: { "accept-language": language}
    // };
    // await fetch(
    //   user.paymentUrl,
    //     requestOptions
    // )
    // /afcl/Package/AllPayment
    // let link = user.paymentMethod == 'QRCode' ? '/afcl/Package/PayQr' : user.paymentMethod == 'Installment' ? '/afcl/Package/PayIPPOnline' : '/afcl/Package/PayOnline';
    sessionStorage.setItem('paymentHis', JSON.stringify('1'));

    let payload = new PaymentModel(user.confirmationNo, user.userCode)

    await allPayment(payload, language)
      .then(function (data) {
        if (data["Success"]) {
          const items = data["Result"];
          console.log("getPayment", data);
          console.log("WebPaymentUrl", items["WebPaymentUrl"]);
          console.log("InvoiceNumber", items["InvoiceNumber"]);
          user.InvoiceNumber = items["InvoiceNumber"];
          sessionStorage.setItem('UserLogin', JSON.stringify(user));
          // sessionStorage.setItem('UserLogin', JSON.stringify({InvoiceNumber: items["InvoiceNumber"]}));
          window.location.href = items["WebPaymentUrl"];
          // setWebPaymentUrl(items["WebPaymentUrl"])
          // if (user.paymentMethod === "Credit Card") {
          // setWebPaymentUrl(items["WebPaymentUrl"])
          // setInvoiceNo(items["InvoiceNumber"])
          // } else if (user.paymentMethod === "QRCode") {
          //   setWebPaymentUrl(items["QrData"]["data"])
          //   console.log("QrData", items["QrData"]["data"])
          //   setInvoiceNo(items["InvoiceNumber"])
          //   setQrExpired(items["QrData"]["expiryTimer"] == null ? 600000 : parseInt(items["QrData"]["expiryTimer"]))
          // } else if (user.paymentMethod === "Installment") {
          //   setWebPaymentUrl(items['WebPaymentUrl']);
          //   setInvoiceNo(items['InvoiceNumber']);
          // } else {
          //   setWebPaymentUrl("")
          //   setInvoiceNo("")
          //   setTotal(0)
          //   console.log("bad request")
          // }
        } else {
          console.log("bad request", data["Result"])
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <span></span>
    } else {
      // Render a countdown
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const clearCart = async (showModal) => {
    try {
      await delay(3000);
      let payload = new PaymentModel(user.confirmationNo, user.userCode)

      const result = await checkPaymentStatus(payload, language)
        .then(function (data) {
          const items = data;
          console.log("PaymentStatus", items);
          if (items["Success"]) {
            if (items["Result"]["ResponseCode"] === "0000") {
              // Successful
              // let tax = 0
              // myCart.forEach(element => {
              //   tax += element.Tax
              //   console.log('element', element.Tax)
              // });
              // console.log('tax', tax)

              // let product = []
              // myCart.forEach((e) => {
              //   let obj = {
              //     name: e.PackageName,
              //     id: e.PackageCode,
              //     price: e.ItemPrice.toFixed(2),
              //     category: 'Package',
              //     quantity: e.Qty,
              //     coupon: Promo.promoCode
              //   }
              //   product.push(obj)
              // })

              // // for Google tag (GTM)
              // var dataLayer = window.dataLayer || [];
              // dataLayer.push({
              //   'event': 'transaction',
              //   'ecommerce': {
              //     'purchase': {
              //       'actionField': {
              //         'id': user.confirmationNo,
              //         'affiliation': user.channelCode,
              //         'revenue': user.totalPrice.toFixed(2),
              //         'Tax': tax.toFixed(2),
              //         'shipping': '',
              //         'currency': 'THB',
              //         'coupon': Promo.promoCode
              //       },
              //       'products': product
              //     },

              //   }
              // });
              // console.log('dataLayer', dataLayer)
              // Clear Session
              removeSession("MyCart");
              removeSession("Promo");
              removeSession('Voucher');
              removeSession('CurrentEvent');
              removeSession('EventTime');
              removeSession('DefaultDate');
              const result = items.Result;
              navigate('/done', {
                state: {
                  confirmationNo: user.confirmationNo,
                  TagHtml: result.TagHtml,
                  TagHtmlMobile: result.TagHtmlMobile,
                }
              })
              return items["Result"]["ResponseCode"];
              // navigate("/done",{confirmationNo:user.confirmationNo}) 
            } else if (items["Result"]["ResponseCode"] === "102") {
              // Payment Status is in Progress
            } else if (items["Result"]["ResponseCode"] === "0001") {
              // Transaction pending 
              navigate("/pending")
              sessionStorage.removeItem("MyCart")
              sessionStorage.removeItem("Promo")
            }
            else if (items["Result"]["ResponseCode"] === "2001") {
              navigate("/paymentfail")
              return items["Result"]["ResponseCode"];
            }
            else {
              navigate("/paymentfail")
              // if (showModal) {
              //   // setAlertText("Please contact administrator.")
              //   setAlertText("Transaction fail please try again.")
              //   setShowAlert(true)
              //   setTimeout(() => {
              //     navigate("/paymentfail")
              //   }, 3000)
              // }
              return items["Result"]["ResponseCode"];
            }
            return null;
          } else {
            navigate("/paymentfail")
            return items["Message"];
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          // alert('Error: API /afcl/Package/Pay/CheckStatus ' , error)
        });
      if (result === null) {
        await delay(10000); // Daylay for 10 Seconds
        return clearCart(false);
      } else {
        return result;
      }
    } catch (ex) {

    }
  }


  const goBack = async () => {
    let payload = new PaymentModel(user.confirmationNo, user.userCode)

    await checkPaymentStatus(payload, language)
      .then(function (data) {
        const items = data;
        console.log("PaymentStatus", items);
        if (items["Success"]) {
          console.log("Back", items["Result"]["ResponseCode"]);
          if (items["Result"]["ResponseCode"] === "0000") {
            // Successful
            sessionStorage.removeItem("MyCart")
            navigate("/done", {
              state: {
                confirmationNo: user.confirmationNo
              }
            })
          } else {
            // Do not honor
            navigate("/package/" + myCart[0].PackageCode + "/4")
          }
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert('Error: API /afcl/Package/Pay/CheckStatus ' , error)
      })
  }

  return (
    <div className="page homepage">
      <div className="section">
        <div className="container">
          <div className="col-xl-6 col-lg-8 col-md-9 col-sm-12" style={{ margin: '0 auto' }}>
            {webPaymentUrl && user != null || !loadBackdrop ? (
              ''
            ) : (
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            {webPaymentUrl !== '' && user != null && myCart.length > 0 && (
              <div className="row">
                <iframe className="card-payment pb-3" src={webPaymentUrl}></iframe>
                {/* <object data={webPaymentUrl} width="400" height="700" type="text/html"></object> */}
                {/* <embed src={webPaymentUrl} width={200} height={200} /> */}
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <span className="weight-600">Invoice Number :</span> {invoiceNo}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 paymen-text">
                    <span className="weight-600">Total :</span> {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 weight-600">
                    <span className="text-center mt-4 payment" style={{ cursor: 'pointer' }} onClick={goBack}>
                      {' '}
                      BACK{' '}
                    </span>
                  </div>
                  {/* <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 weight-600 paymen-text">
                    <span className="text-center mt-4 payment" style={{cursor: "pointer"}} onClick={() => clearCart(true)}>Click here! when payment succeed.</span>
                  </div> */}
                </div>
              </div>
            )}

            {/* {webPaymentUrl !== '' && user != null && user.paymentMethod === 'Installment' && myCart.length > 0 && (
              <div className="row">
                <iframe className="card-payment pb-3" src={webPaymentUrl}></iframe>
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <span className="weight-600">Invoice Number :</span> {invoiceNo}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 paymen-text">
                    <span className="weight-600">Total :</span> {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 weight-600">
                    <span className="text-center mt-4 payment" style={{ cursor: 'pointer' }} onClick={goBack}>
                      {' '}
                      BACK{' '}
                    </span>
                  </div>
                </div>
              </div>
            )} */}
          </div>

          {/* QR CODE */}
          {/* {webPaymentUrl !== '' && user != null && user.paymentMethod === 'QRCode' && myCart.length > 0 && (
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12">
                <div style={{ border: '1px solid #0e3e68' }}>
                  <img src={HeaderQR} width="100%" />
                  <div className="px-3 py-3 text-center">
                    <img src={logoQR} width="30%" className=" mb-3" />
                    <img src={webPaymentUrl} width="100%" />
                  </div>
                </div>

                <div className="text-center py-3">
                  Remaining time:&nbsp;
                  <Countdown
                    date={Date.now() + qrExpired}
                    renderer={renderer}
                    onComplete={() => {
                      window.location.reload();
                    }}
                  />
                </div>

                <div className="col-12">
                  <span className="weight-600">Invoice Number :</span>
                  <br />
                  {invoiceNo}
                </div>
                <div className="col-12">
                  <span className="weight-600">Total :</span> {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>

                <div className="col-12 weight-600 pt-3">
                  <span className="text-center mt-4 payment" style={{ cursor: 'pointer' }} onClick={goBack}>
                    {' '}
                    BACK{' '}
                  </span>
                </div>
              </div>
            </div>
          )} */}
          <Modal show={showAlert} className="col-12 cart-placeorder">
            <a href="#" className="close">
              <Close className="popup-close" onClick={() => { setShowAlert(false); navigate("/package/" + myCart[0].PackageCode + "/4") }} />
            </a>
            <h5 className="text-center text-danger">{alertText}</h5>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Payment;
