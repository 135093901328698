
import { BlockTable, GetEventCalendar, GetPackageByEvent, GetZoneAndEventByDate, GetZonePlan, UnblockTable, GetEventAll, GetVoucherVerify, ConfirmBooking } from "../constants/BaseApi";
import { makeApiRequest } from './BaseApiService';


export async function getEventDate(language) {
    return makeApiRequest(GetEventCalendar, language);
}

export async function getEventAndZoneLookup(params, language) {
    return makeApiRequest(GetZoneAndEventByDate, language, 'get', null, {}, params);
}

export async function getZonePlanLookup(model, language) {
    return makeApiRequest(GetZonePlan, language, 'post', model);
}

export async function getTablePlanLookup(model, language) {
    return makeApiRequest(GetZoneAndEventByDate, language, 'post', model);
}

export async function Blocktable(model, language) {
    return makeApiRequest(BlockTable, language, 'post', model);
}

export async function UnBlocktable(model, language) {
    return makeApiRequest(UnblockTable, language, 'post', model);
}

export async function getPackageByEvent(params, language) {
    return makeApiRequest(GetPackageByEvent, language, 'get', null, {}, params);
}

export async function getEventAll(model, language) {
    return makeApiRequest(GetEventAll, language, 'post', model);
}

export async function getVoucherVerify(model, language) {
    return makeApiRequest(GetVoucherVerify, language, 'post', model);
}

export async function confirmBookingService(bookingId, language) {
    const model = {
        BookingId: bookingId
    }
    return makeApiRequest(ConfirmBooking, language, 'post', model);
}