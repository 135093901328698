import FacebookLogin from 'react-facebook-login';
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import FBIcon from "../component/img/facebook.png";

import "../component/css/style.css";
import "../component/css/custom.css";
import { appId } from '../constants/DataPublic'
import { getCartSession } from './Helpers/BaseHelper';
import Loading from './Loading';
import { CheckExternalLoginModel } from '../Models/AccountModel';
import { ExternalGuestCheckLoginService } from '../Services/AccountService';

const FBLogin = (props) => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const [needRegist, setNeedRegist] = useState(false)
    const { language } = props
    const { fbtext } = props
    const [loading, setLoading] = useState(false);
    let myCart = getCartSession();

    const divRef = useRef();

    const componentClicked = () => {
        console.log('clicked');
    }

    const responseFacebook = async (response) => {
        console.log("response", response);
        if (response.status !== "unknown") {
            setLoading(true);
            let fbUser = response;
            // props.getFBUser(fbUser)
            // console.log("props fbUser: ", fbUser)

            // const requestOptions = {
            //     method: "POST",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify({
            //         "Provider": "Facebook",
            //         "ProviderKey": response.userID,
            //         "Email": response.email,
            //         "PushKey": "",
            //         "DeviceType": "",
            //         "DeviceKey": ""
            //     }),
            // };
            const payload = new CheckExternalLoginModel("Facebook", response.userID, response.email);

            // await fetch(
            //     coreApi + "/afcl/Account/external/checklogin",
            //     requestOptions
            // )
            await ExternalGuestCheckLoginService(payload, language)
                .then(function (data) {
                    setLoading(false);
                    const items = data;
                    console.log(items);
                    console.log("NeedRegister: ", items["Result"].NeedRegister);
                    if (!items["Success"] && items["Result"].NeedRegister) {
                        setNeedRegist(true)
                        props.getNeedRegist(items["Result"].NeedRegister)
                    } else if (items["Success"] && !items["Result"].NeedRegister) {
                        props.setUserCode(items["Result"].User.UserCode)
                        let userLogin = {
                            fName: items["Result"].User.FirstName,
                            lName: items["Result"].User.LastName,
                            email: items["Result"].User.Email,
                            userId: items["Result"].User.UserId,
                            userCode: items["Result"].User.UserCode,
                            photo: items["Result"].User.UserPicture,
                            isLogin: true,
                            channelCode: items["Result"].ChannelCode
                        }
                        sessionStorage.setItem('UserLogin', JSON.stringify(userLogin));
                        setLoading(false);
                        if (myCart && myCart.length > 0) {
                            // navigate("/");
                            window.location.href = '/package/' + myCart[0].Package[0].PackageCode + '/4';
                        } else {
                            window.location.href = '/';
                        }
                        props.getNeedRegist(items["Result"].NeedRegister)
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    console.log("Error: ", error);
                    // alert('Error: API /afcl/Account/external/checklogin ', error)
                });
        }
    }

    return (
        <div ref={divRef}>
            <FacebookLogin
                appId={appId}
                autoLoad={false}
                cssClass="btn btn-link btnloginsocial "
                fields="name,email,picture"
                icon={<img src={FBIcon} width={27} alt="" className="" />}
                textButton={<span className="">{t('')}</span>}
                onClick={componentClicked}
                callback={responseFacebook}
                disableMobileRedirect={true}
                isMobile={false}
            />
            <Loading loading={loading} />
        </div>
    )

}

export default FBLogin