import { isSameDay } from 'date-fns';
import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import calendar_icon from '../img/calendar_gradient_79.svg';
import calendat_next from '../img/calendar_next.svg';
import { useTranslation } from "react-i18next";

const CustomDatePicker = forwardRef((props, ref) => {
    const calRef = useRef();

    const { eventDateArray, startDate, setStartDate, setOpenBottomPopupDate, mobile = false, isActive, isEventPage } = props;
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [inline, setInline] = useState(false);

    const { t } = useTranslation();

    // ใช้เพื่อให้เป็นการเรียกใช้ function จาก parent ได้
    useImperativeHandle(ref, () => ({
        setDate,
    }));

    useEffect(() => {
        if (isActive === undefined) return
        if (mobile) {
            if (mobile && isActive) {
                setInline(true)
            } else {
                setInline(true)
                setTimeout(() => {
                    setInline(false)
                }, 500)
            }
        }
    }, [isActive])

    const handleDateChange = (date) => {
        // เช็คว่ามีการ handle Change date จากหน้า parent หรือไม่
        console.log("handleDateChangeDate", date)
        console.log("startDate", startDate)
        if (props.onDateChange) {
            props.onDateChange(date)
        } else {
            setDate(date)
        }
    };


    const setDate = async (date) => {
        setStartDate(date);
        console.log("setDate", date);
        // setDateSession(convertDateToISO(date));
        setIsDatePickerOpen(false);
        if (mobile) setOpenBottomPopupDate() // Close the date picker when a date is selected
    }

    const toggleDatePicker = () => {
        setIsDatePickerOpen(!isDatePickerOpen); // Toggle the date picker visibility
    };

    const dateValidate = (curDate, recDate) => {
        if (recDate !== null) {
            return (curDate.toLocaleDateString() == recDate.toLocaleDateString());
        }
        return false;
    }

    const renderDayContents = (day, date) => {
        return (<div className='custom-day'>

            <span>
                {eventDateArray.some(eventDate => isSameDay(eventDate, date)) ? (<>
                    <div className="day-item day-mark">
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                            <circle cx="2.5" cy="2.5" r="2.5" fill={dateValidate(date, startDate) ? "#FFFFFF" : "#DB5927"} />
                        </svg>
                    </div>
                    <div className={dateValidate(date, startDate) ? '' : 'font-79-day'}>{day} </div>
                </>
                ) : (<>
                    <div className="day-item">
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                            <circle cx="2.5" cy="2.5" r="2.5" fill="transparent" />
                        </svg>
                    </div>
                    <div>{day}</div>
                </>
                )}

            </span>
        </div>);
    };

    const CustomInput = ({ startDate, onClick }) => {

        const handleOpenDate = () => {
            console.log("mobile", mobile)
            if (mobile) {
                setOpenBottomPopupDate()
            } else {
                onClick()
            }
        }

        return (
            <div className={`${isEventPage ? 'date-container-event' : 'date-container'} d-flex align-items-center justify-content-center`}>
                <button className='date-picker-button d-flex align-items-center justify-content-center' style={{ fontSize: '15px', width: 'inherti', height: 'inherit' }} onClick={handleOpenDate}>
                    <p className='m-auto d-inline dropdown-text'>
                        {startDate && startDate !== null ? startDate.toLocaleDateString('en-GB') : t('please_select')}
                    </p>
                    <span style={{
                        paddingLeft: '10px',
                        height: '100%',
                        display: 'flex'
                    }}>
                        {/* <FontAwesomeIcon icon={faCalendarDays} /> */}
                        <img src={calendar_icon} width={20} className='d-inline' />
                    </span>
                </button>
            </div>
        )
    }

    const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
        const dateFormat = "MMMM yyyy"; // Format for displaying month and year

        return (
            <div className="custom-header d-flex justify-content-around p-2 m-3">
                <div onClick={decreaseMonth}><img src={calendat_next} style={{ transform: 'rotate(180deg)', width: '10px', cursor: 'pointer' }} /></div>
                <span className='text-79' style={{ fontWeight: 'bold', cursor: 'default' }}>{date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
                <div onClick={increaseMonth}><img src={calendat_next} style={{ width: '10px', cursor: 'pointer' }} /></div>
            </div>
        );
    };

    return (
        <>
            <DatePicker
                // className='custom-date-picker'
                ref={calRef}
                selected={startDate}
                onChange={date => handleDateChange(date)}
                renderDayContents={renderDayContents}
                minDate={new Date()}
                customInput={<CustomInput startDate={startDate} />}
                closeOnScroll={(e) => e.target === document}
                renderCustomHeader={CustomHeader}
                inline={inline}
            >
                <div
                    className='d-flex justify-content-end align-items-center font-grey'
                    style={{ fontSize: '12px', width: '100%', cursor: 'pointer' }}
                    onClick={() => {
                        calRef.current.setOpen(false);
                        setOpenBottomPopupDate();
                    }}
                >Cancel</div>
            </DatePicker >
        </>
    )
});

export default CustomDatePicker;