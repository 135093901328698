import React, { useState } from 'react'
import table from '../img/icon_table.svg';
import { ReactComponent as CloseGrey } from '../img/close-grey.svg';
import { useMediaQuery } from 'react-responsive';

export default function CartTable(props) {
    const { tableName, onRemove } = props
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' })

    const [hoveredBox, setHoveredBox] = useState(null);

    const handleBoxMouseEnter = (e) => {
        setHoveredBox(e);
    };

    const handleBoxMouseLeave = () => {
        setHoveredBox(null);
    };

    return (
        <div style={{ position: 'relative' }}>
            <div className='table-cart border-drop-shadow d-flex flex-xl-fill align-items-center justify-content-center m-2' key={tableName}>
                <img src={table} width={24} style={{ display: 'inline' }} />
                <p className='d-inline m-0 weight-500' style={{ height: 'fit-content', paddingRight: '5px', paddingLeft: '5px', maxWidth: '70px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '16px' }} onMouseEnter={() => handleBoxMouseEnter(tableName)} onMouseLeave={handleBoxMouseLeave}>{tableName}</p>
                <CloseGrey className="mb-2 close-grey" style={{ width: '10px', height: '20px', verticalAlign: 'top', marginLeft: '5px', cursor: 'pointer' }}
                    onClick={() => onRemove(tableName)}
                ></CloseGrey>
                {hoveredBox === tableName && !isTabletOrMobile && (
                    <div className='tooltipTable'>
                        <div className="w-600" style={{ padding: '5px' }}>
                            <span>{tableName}</span>
                        </div>
                    </div>
                )}
            </div>

        </div>

    )
}
