// ใช้ Config รูปแบบ Card ที่แสดงที่หน้าจอ อ้างอิงกับ PackageTypeCode
const verticalCardStart = ["T001", "T003"]
const verticalCardDiscount = ["T002"]
const verticalCardDetail = ["T005"]
const horizontalCardDiscount = ["T004"]

// Package Type Other ที่ไว้แสดง DayPass, Extreme Pass ที่หน้า Package
const packageExtraType = "T005"
const packageTypeOther = "T009"
const dayPassPackageCode = "P026"
const extremePassPackageCode = "P027"

const packageTypeTicket = "T006"

// PackageGroupTypeCode สำหรับกำหนด Card แบบ Cabana หรือ Transportation
const cabanaPackageGroupTypeCode = "PG02"
const transportPackageGroupTypeCode = "PG03"

// ClientId -> สำหรับ Sign in ด้วย Google Account
// 79 Beach Google Login
const clientId = "2448708332-unju7naaittp80c0q2q9jgu7bgn7ssi1.apps.googleusercontent.com";
// AppId -> สำหรับ Sign in ด้วย Facebook Account
const appId = "2512666902249671"
const fbRedirectURL = "https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df18e7f90d8f3d5c%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttp%253A%252F%252Flocalhost%253A3000%252Ffb124c175f3e2%26relation%3Dopener%26frame%3Df39a2cc9164eb24"
const baseLink = ''

//const coreApi = 'https://79beach-core-api.smartfinder.asia'
//const apiLink = 'https://79beach-ecom-api.smartfinder.asia'

// Prod
const coreApi = 'https://79-core-api.azurewebsites.net'
const apiLink = 'https://79-ecom-api.azurewebsites.net'

// Cart for Tablet & Mobile
const deviceWith = 768

// Payment CountDown
const checkStatusTimerMS = 50000  // 50 seconds x 1000

// Cart
const incTax = 7

// Event
const eventPageLimit = 8
const eventPageNumber = 1;

export const fbLink = 'https://www.facebook.com/79BeachClubSamui';
export const igLink = 'https://www.instagram.com/79beachclubsamui';
export const ytLink = 'https://www.youtube.com/channel/UC3JQKlMnbGtU_N0tvxNZqzA';
export const lineLink = 'https://lin.ee/QhGK502f';

export const versions = '1.0.0';


export { verticalCardStart, verticalCardDiscount, verticalCardDetail, horizontalCardDiscount, packageExtraType, packageTypeOther, packageTypeTicket, dayPassPackageCode, extremePassPackageCode, cabanaPackageGroupTypeCode, transportPackageGroupTypeCode, clientId, appId, fbRedirectURL, baseLink, apiLink, deviceWith, incTax, checkStatusTimerMS, coreApi, eventPageLimit, eventPageNumber }