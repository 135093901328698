import React from 'react'
import MapPinIcon from '../img/icon_map_pin.png'
import TableZone from '../img/icon_table.png'
import { formatToCurrency } from '../Helpers/BaseHelper'

export default function Zone(props) {
    const { tableName, pkPrice, isTabletOrMobile, isMobile } = props
    return (
        <div className="text-center" style={{
            width: '100%',
            height: '100%',
            display: 'flex'
        }}>
            <img src={MapPinIcon} style={{ width: '100%', height: '100%' }} />
            <div className='table-img' style={{
                top: isMobile ? '0' : isTabletOrMobile ? '10%' : '25%',
                left: isTabletOrMobile && '5%'
            }}>
                <img src={TableZone} style={{ width: isTabletOrMobile && '2.5vw' }} />
                <div className='row table-no' style={{
                    top: isTabletOrMobile ? '40%' : '25%'
                }}>
                    <div className='col' style={{
                        fontSize: isTabletOrMobile && '6px',
                        padding: isTabletOrMobile && '0px'
                    }}>
                        {tableName}
                    </div>
                    {/* <div className='col' style={{
                        fontSize: isTabletOrMobile && '6px'
                    }}>
                        <div className='gradient-text-79'>{formatToCurrency(pkPrice)}</div>
                    </div> */}
                </div>
            </div>
        </div >
    )
}
