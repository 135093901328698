import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { Markup } from 'interweave';
import { useMediaQuery } from 'react-responsive'
import { useGlobalState } from '../global'
import PopupSuccessIcon from '../component/img/popup-success.png';
import ArrowBackIcon from '../component/img/arrow-back-79.svg';
import { useRef } from "react";
import { getBookingSession, removeSession, resetBookingSesstion } from "../component/Helpers/BaseHelper";
import { confirmBookingService } from "../Services/TableBookService";

const Done = (props) => {
	const { language } = props
	const { state } = useLocation();

	const isDesktop = useMediaQuery({ query: '(min-width: 1224px)' })
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
	const isMobile = useMediaQuery({ query: '(max-width: 576px)' })

	const [keepState, setKeepstate] = useState();
	const [routePath, setRoutePath] = useGlobalState('route');

	let user = sessionStorage.getItem('UserLogin')
	user = JSON.parse(user)

	let itCrossCam = sessionStorage.getItem('itCrossCampaign');
	itCrossCam = JSON.parse(itCrossCam);

	const navigate = useNavigate();

	useEffect(() => {
		if (state == null || (!user || !user.confirmationNo)) {
			window.location.href = '/';
		}
		// console.log("Done: ", user);
		// console.log("confirmNo: ", state);
		setKeepstate(state)
		props.getCartItem(0)
		confirm();
	}, [])

	const goHome = () => {
		user.confirmationNo = "";
		sessionStorage.setItem('UserLogin', JSON.stringify(user));
		removeSession('EventState');
		// navigate("/")
		window.location.href = '/';
		setKeepstate(null)
	}

	const confirm = async () => {
		//  Confirm Booing
		const booking = getBookingSession();
		if (booking !== null || booking) {
			if (booking.bookingId > 0) {
				await confirmBookingService(booking.bookingId, language);
			}
		}
		user.confirmationNo = "";
		sessionStorage.setItem('UserLogin', JSON.stringify(user));
		resetBookingSesstion();
	}

	// ภาษา
	const { t } = useTranslation();

	return (
		<div className="page page-steps">
			<img className="mt-5 " style={{ cursor: 'pointer', width: '30px', marginLeft: isMobile ? '1em' : '2em', marginTop: isMobile ? '20px' : '' }} src={ArrowBackIcon} onClick={() => goHome()} />
			<div className="col-lg-12 py-5">
				<div className="d-flex justify-content-center height-full">
					<div className="done" style={{ width: isTabletOrMobile ? '90%' : '80%', marginTop: '-60px' }}>
						<div className="d-flex justify-content-center mb-4">
							<img style={{ width: isMobile ? '120px' : '200px' }} src={PopupSuccessIcon} />
						</div>
						{keepState && keepState.confirmationNo ?
							<h5 className={`font-${isMobile ? 12 : 16} mb-3 text-left ${!isMobile ? 'ps-4' : ''}`} style={{ fontWeight: 'bold', marginLeft: isTabletOrMobile ? '0px' : '4rem' }}>{t('your_booking_reference')} : {keepState.confirmationNo}</h5>
							: ''}
						<div>
							{keepState && (
								<>
									{isTabletOrMobile && (<Markup content={" " + keepState.TagHtmlMobile} />)}
									{!isTabletOrMobile && (<Markup content={" " + keepState.TagHtml} />)}
								</>
							)}
						</div>
						<div style={{ width: '100%', margin: 'auto' }}>
						</div>
						<h2 className="font-20 mb-5"></h2>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Done
