import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CartTable from './CartTable';
import NotFound from '../img/notfound_new.png'
import { formatToCurrency, getDateSession, getEventSession, getBookingSession, getCartSession, setCartSession, setBookingSession, getVoucherSession, removeSession, resetBookingSesstion, getCountCart } from '../Helpers/BaseHelper';
import { useNavigate } from 'react-router-dom';
import PackageSelectModal from '../modal/PackageSelectModal';
import AlertModal from '../modal/AlertModal';
import BinIcon from '../img/paymentfail.png';
import ExpireIcon from '../img/expire_icon_79.svg';
import ArrowBackRounded from '../img/arrow-back-rounded.png';
import ArrowForwardRounded from '../img/arrow-forward-rounded.png';
import Loading from '../Loading';
import { useToast } from '../Helpers/ToastContext';
import SorasoButton from '../buttons/SorasoButton';
import { Blocktable, UnBlocktable, getZonePlanLookup } from '../../Services/TableBookService';
import { BlockTableModel } from '../../Models/TableBookModel';
import TextStrike from '../input/TextStrike';
import CaretDown from '../img/caret_down_icon.svg';
import { Markup } from 'interweave';

export default function PackageCard(props) {
    const { mobile, packageEvent, selectedTable, setSelectedTable, event, startDate, getTablePlan, tablePlan } = props;
    const { t, i18n } = useTranslation();
    const { showToast } = useToast();
    const [cartItem, setCartItem] = useState(null);
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [showCancle, setShowCancle] = useState(true);
    const [alertCallback, setAlertCallback] = useState({});

    const [packageList, setPackageList] = useState(packageEvent[0]);
    const [packageIndex, setPackageIndex] = useState(0);
    const [alertMessage, setAlertMessage] = useState(t('table_remove_warining'));
    const [alertIcon, setAlertIcon] = useState(BinIcon);
    const [netPrice, setNetPrice] = useState(0)

    let myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);

    useEffect(() => {
        // Restore CartItem
        var cart = JSON.parse(sessionStorage.getItem("MyCart"));
        if (cart != null) {
            setCartItem(cart);
        }
    }, [])

    useEffect(() => {
        setPackageList(packageEvent[0]);
        setPackageIndex(0);
    }, [packageEvent])

    useEffect(() => {
        if (packageList.IsDiscount) {
            setNetPrice(packageList.PackagePrice - packageList.DiscountPrice)
        } else {
            setNetPrice(packageList.PackagePrice)
        }
    }, [packageList])

    const handleTableRemove = async (e) => {
        let tableFilter = [];
        if (myCart !== null && myCart.length > 0) {
            let findTable = myCart.filter((item) => item.TableNo === e);
            if (findTable !== null && findTable.length > 0) {
                setAlertMessage(t('table_remove_warining'));
                setShowCancle(true);
                openConfirmationModal()
                    .then(async (confirmed) => {
                        if (confirmed) {
                            await CallUnblockTable(e);
                            let newCart = myCart.filter((cart) => cart.TableNo !== e);
                            sessionStorage.setItem('MyCart', JSON.stringify(newCart));
                            myCart = JSON.parse(sessionStorage.getItem('MyCart'));
                            selectedTable.find((item) => {
                                if (item != e) {
                                    tableFilter.push(item);
                                } else {
                                    // Remove Table here
                                }
                            })
                            setSelectedTable(tableFilter);
                            setShowAlert(false);
                            await getTablePlan();
                        }
                    })
                    .catch((error) => {
                        // Handle errors or user cancelation
                        console.error(error);
                        setShowAlert(false);
                    });
            } else {
                selectedTable.find((item) => {
                    if (item != e) {
                        tableFilter.push(item);
                    } else {
                        // Remove Table here
                    }
                })
                setSelectedTable(tableFilter);
            }
        } else {
            selectedTable.find((item) => {
                if (item != e) {
                    tableFilter.push(item);
                } else {
                    // Remove Table here
                }
            })
            setSelectedTable(tableFilter);
        }
    }

    const handleOnClick = async (packageItem) => {
        try {
            // Block Table
            let errorTable = await blockTable();
            let newTable = [];

            if (errorTable && errorTable.length > 0) {
                setAlertMessage(`Failed to book table ${errorTable} Please Remove`)
                setShowCancle(false);
                await openConfirmationModal()
                    .then(async (confirmed) => {
                        if (confirmed) {
                            setShowAlert(false);
                            newTable = selectedTable.filter(e => !errorTable.includes(e)).sort();
                            let cart = getCartSession();
                            if (cart !== null) {
                                // setCartSession(cart.filter((e) => !errorTable.includes(e.TableNo)))
                            }
                            await getTablePlan();
                        } else {
                            setShowAlert(false);
                            newTable = selectedTable.filter(e => !errorTable.includes(e)).sort();
                        }
                    })
                    .catch((error) => {
                        // Handle errors or user cancelation
                        newTable = selectedTable.filter(e => !errorTable.includes(e)).sort();
                        let cart = getCartSession();
                        // setCartSession(cart.filter((e) => !errorTable.includes(e.TableNo)))
                        console.error(error);
                    });
            } else {
                newTable = selectedTable.sort();
            }
            setSelectedTable(newTable);
            // console.log("newTable", newTable);
            if (newTable.length > 0) {
                let eventDetails = "";
                let cart = [];
                let cartSession = getCartSession();

                if (cartSession !== null) {
                    cart = cartSession;
                }

                let netPrice = 0;

                if (packageItem.IsDiscount) {
                    netPrice = packageItem.PackagePrice - packageItem.DiscountPrice;
                } else {
                    netPrice = packageItem.PackagePrice;
                }

                // Voucher
                const voucherData = getVoucherSession();

                let pkItem = [
                    {
                        PackageCode: packageItem.PackageCode,
                        PackageName: packageItem.PackageName,
                        PackageShortDescription: packageItem.PackageShortDescription,
                        ItemCode: "",
                        ItemName: "",
                        Qty: 1,
                        Tax: 0,
                        ItemPrice: voucherData ? 0 : netPrice,
                        ItemDiscount: voucherData ? 0 : packageItem.DiscountPrice,
                        PriceName: packageItem.PriceName,
                        PriceUnit: packageItem.PriceUnit,
                        TotalPrice: voucherData ? 0 : netPrice,
                        VisitDate: "",
                        step: 1,
                        status: 'normal',
                        isMain: true,
                        VoucherNo: voucherData?.VoucherNo,
                    }]

                let tableSort = newTable.sort();
                let eventTime = event.EventStartTime.split(":");
                for (let i = 0; i < tableSort.length; i++) {
                    let checkTable = "";
                    if (cart.length > 0) {
                        checkTable = cart.find((e) => e.TableNo == tableSort[i]);

                        if (checkTable !== undefined && checkTable !== null) {
                            cart.map((e) => {
                                if (e.TableNo == tableSort[i]) {
                                    let checkPackageIndex = e.Package.findIndex((p) => p.PackageCode === packageItem.PackageCode);
                                    if (checkPackageIndex >= 0) {
                                        e.Package[checkPackageIndex].Qty++;
                                    } else {
                                        e.Package.push({
                                            PackageCode: packageItem.PackageCode,
                                            PackageName: packageItem.PackageName,
                                            PackageShortDescription: packageItem.PackageShortDescription,
                                            ItemCode: "",
                                            ItemName: "",
                                            Qty: 1,
                                            Tax: 0,
                                            ItemPrice: packageItem.PackagePrice,
                                            ItemDiscount: packageItem.DiscountPrice,
                                            PriceName: packageItem.PriceName,
                                            PriceUnit: packageItem.PriceUnit,
                                            TotalPrice: netPrice,
                                            VisitDate: "",
                                            step: 1,
                                            status: 'normal',
                                            isMain: false
                                        })
                                    }

                                }
                            })
                        } else {
                            cart.push({
                                TableNo: tableSort[i],
                                Package: pkItem,
                                ArrivalDate: startDate.toLocaleDateString('en-GB') + ' - ' + eventTime[0] + ':' + eventTime[1]
                            })
                        }
                    } else {
                        cart.push({
                            TableNo: tableSort[i],
                            Package: pkItem,
                            ArrivalDate: startDate.toLocaleDateString('en-GB') + ' - ' + eventTime[0] + ':' + eventTime[1]
                        })
                    }
                }
                if ((errorTable && errorTable.length == 0)) {
                    sessionStorage.setItem("MyCart", JSON.stringify(cart));
                    window.location.href = 'package/' + pkItem[0].PackageCode + '/2';
                } else {
                    await getTablePlan();
                }
            } else {
                window.location.reload();
            }
        } catch (error) {
            showToast(error.message, 'error');
        }
    }

    const openConfirmationModal = () => {
        return new Promise((resolve) => {
            setShowAlert(true);

            const handleConfirm = () => {
                resolve(true); // User confirmed the change
            };

            const handleCancel = () => {
                resolve(false); // User canceled the change
            };

            // Set the ConfirmationModal callbacks
            setAlertCallback({ confirm: handleConfirm, cancel: handleCancel });
        });
    };

    function handlePackageChange(index) {
        if (index != -1 && index < packageEvent.length) {
            setPackageIndex(index);
            setPackageList(packageEvent[index]);
        }
    }

    async function blockTable() {
        setLoading(true);

        let table = [];
        let errorTable = [];
        let booking = getBookingSession();
        table = selectedTable.map((e, index) => {
            return { TableNo: e }
        })

        let event = getEventSession();

        const payload = new BlockTableModel(
            table,
        );
        await Blocktable(payload, i18n.language ?? 'EN').then(async (response) => {
            if (response.Success) {
                const data = response.Result;
                // console.log(data);

                errorTable = data.ErrorTableList.map((e) => {
                    return e.TableNo;
                })
                setBookingSession({ bookingId: data.BookingId });
                setLoading(false);
            } else {
                setLoading(false);
                setAlertMessage(t('expire_reservation'));
                setAlertIcon(ExpireIcon);
                await openConfirmationModal()
                    .then(async (confirmed) => {
                        if (confirmed) {
                            setShowAlert(false);
                            removeSession('MyCart');
                            resetBookingSesstion();
                            setSelectedTable([]);
                            window.location.href = '/';
                            errorTable = table;
                        }
                    })
                    .catch((error) => {
                        // Handle errors or user cancelation
                        setShowAlert(false);
                        removeSession('MyCart');
                        resetBookingSesstion();
                    });
                return false;
            }
        });
        return errorTable;
    }

    async function CallUnblockTable(tableNo) {
        let event = getEventSession();
        let table = tablePlan.find((e) => e.TableNo == tableNo);
        let booking = getBookingSession();

        if (table.BookingId !== booking.bookingId) return false;
        setLoading(true);
        const payload = new BlockTableModel([{ TableNo: tableNo }]);
        try {
            await UnBlocktable(payload).then(async (response) => {
                if (response.Success) {
                    const data = response.Result;
                    // console.log(data);
                    await getTablePlan();
                    setLoading(false);
                } else {
                    throw (response.Result.Message);
                }
            }).catch((error) => {
                throw error;
            });
        } catch (error) {
            setLoading(false);
            console.log(error);
            showToast(error.message, 'error');
            return null;
        }
    }

    const resetAlert = () => {
        setAlertIcon(BinIcon);
        setAlertMessage(t('table_remove_warining'));
    }

    return (
        <>
            <div className='card' key={packageList.PackageCode} style={{ boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25)', height: '100%', borderRadius: '18px' }}>
                {/* <div className='card-title p-3 m-0' style={{ fontWeight: 'bold', wordBreak: 'break-word' }}>
                    {t('package_promotion')}
                </div> */}
                <div className='' style={{ position: 'relative' }}>
                    {packageIndex !== 0 && (
                        <img className='package-nav-arrow left' src={ArrowBackRounded} onClick={() => handlePackageChange(packageIndex - 1)} />
                    )}
                    {packageIndex < packageEvent.length - 1 && (
                        <img className='package-nav-arrow right' src={ArrowForwardRounded} onClick={() => handlePackageChange(packageIndex + 1)} />
                    )}
                    <img
                        src={packageList.PackagePictureLists.length > 0 && packageList.PackagePictureLists[0].PictureUrl || ""}
                        style={{ borderRadius: '0', borderTopLeftRadius: '18px', borderTopRightRadius: '18px' }}
                        className="card-package-img" alt="..."
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = NotFound;
                        }}
                    />
                </div>
                <div className='card-body'>
                    <h4 className='mb-3 text-79' style={{ fontWeight: '700' }}>{packageList.PackageName}</h4>
                    <p className='weight-600'>{packageList.PackageCondition}</p>

                    {/* Terms */}
                    <p
                        className="d-flex justify-content-start font-14 mb-3 weight-600 text-decoration-underline"
                        data-bs-toggle="collapse"
                        href={'#term'}
                        role="button"
                        aria-expanded="false"
                        aria-controls="term1"
                        onClick={() => { setOpenModal(true); }
                        }
                    >
                        {/* <img src={TermIcon} className="TermIcon" /> */}
                        {t('terms_conditions')}
                    </p>

                    {/* Item Collapse*/}
                    <p
                        className="d-flex justify-content-start font-14 mb-1 weight-700 text-decoration-underline"
                        data-bs-toggle="collapse"
                        href={'#includes' + packageList.PackageCode}
                        role="button"
                        aria-expanded="false"
                        aria-controls="includes1"
                    >
                        {/* <img src={TermIcon} className="TermIcon" /> */}
                        {t('overview')}
                        <span><img src={CaretDown} /></span>
                    </p>
                    <div className="collapse" id={'includes' + packageList.PackageCode}>
                        {packageList.PackageItemLists.map((e, i) => {
                            return (
                                <p className='ps-3'>
                                    <Markup key={i} content={e.ItemType == 'Ticket' ? '● ' + e.Qty + ' ' + e.ItemName : '+ ' + e.Qty + ' ' + e.ItemName} />
                                </p>
                            );
                        })}

                    </div>
                    {/* Item Collapse*/}

                    <div className='row m-0' style={{ width: '100%' }}>
                        <div className='col-7 p-0 d-flex-row align-items-center'>
                            <h4 className='font-grey' style={{ fontWeight: '600' }}>{t('thb')} {formatToCurrency(netPrice)}</h4>
                            {packageList.IsDiscount && (
                                <>
                                    <p className="oldPrice weight-400 mb-1 ps-5 text-strike">{packageList.PackagePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    {/* <TextStrike text={packageList.PackagePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} /> */}
                                    <div className="mb-1" style={{ minHeight: 40, justifyContent: 'start', display: 'flex' }}>

                                        <div className="ps-2 weight-500 color-black" style={{ height: 50, fontSize: 18, textAlign: 'start' }}>{t('save_price') + ' ' + packageList.DiscountPrice.toLocaleString()} {t('thb')}</div>

                                    </div>

                                </>
                            )}
                        </div>
                        <div className='col-5 d-flex justify-content-center align-items-center p-0' style={{ userSelect: 'none', cursor: 'pointer' }}>
                            <SorasoButton
                                className={selectedTable.length < 1 ? 'btn border-drop-shadow weight-500' : 'btn gradient-background border-drop-shadow weight-500'}
                                mobileH={'40px'}
                                text={t('addtocart')}
                                disabled={selectedTable.length < 1}
                                style='primary'
                                onClick={() => { handleOnClick(packageList) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='card-footer-custom d-flex justify-content-between align-items-center'>
                    <div class="table-grid">
                        {selectedTable && (
                            selectedTable.map((e, index) => {
                                return (
                                    <CartTable
                                        key={e}
                                        tableName={e}
                                        onRemove={handleTableRemove}
                                    />
                                )
                            })
                        )}
                    </div>
                </div>
            </div>

            {packageList && (
                <PackageSelectModal
                    showModal={openModal}
                    onCloseModal={() => setOpenModal(false)}
                    dataEvent={packageList}
                    pictureList={packageList.PackagePictureLists}
                    name={packageList.PackageName}
                    description={packageList.PackageDescription}
                    termAndCondition={packageList.PackageTermAndCondition}
                    price={packageList.IsDiscount ? packageList.PackagePrice - packageList.DiscountPrice : packageList.PackagePrice}
                    oldPrice={packageList.PackagePrice}
                    discountPrice={packageList.DiscountPrice !== 0 ? packageList.DiscountPrice : null}
                    isShowButton={false}
                    packageInfo={packageList}
                />
            )}

            {showAlert && (
                <AlertModal
                    openModal={showAlert}
                    onCloseModal={() => {
                        setShowAlert(false);
                        resetAlert();
                    }}
                    onConfirm={alertCallback.confirm}
                    isShowCancle={showCancle}
                    isShowClose={false}
                >
                    <div className='d-flex flex-column justify-content-around align-items-center' style={{ height: '100%' }}>
                        <div className='mb-3'>
                            <img height={150} src={alertIcon} />
                        </div>
                        <p className='' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                            {alertMessage}
                        </p>
                    </div>
                </AlertModal>
            )}

            <Loading loading={loading} />
        </>
    )
}
