import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'

// import logo from "./img/Logo_SRS.svg";
import logo from "../component/img/logo.png";
import logoMobile from "../component/img/logo-mobile.png";
import loginIcon from "../component/img/login.svg";
import PicProfile from "../component/img/picuser2.png";
import { ReactComponent as User } from "./img/user.svg";
import { ReactComponent as Cart } from "./img/cart.svg";
import { ReactComponent as Hamburger } from "./img/hamburger.svg";
import { baseLink, apiLink, coreApi } from "../constants/DataPublic";
import { useGlobalState } from '../global'

import CartLight from "../component/img/cart-light.svg";
import UserLight from "../component/img/user-light.svg";
import TableIcon from "../component/img/table.svg";
import BeachClub from "../component/img/beach-club.svg";
import LogoUs from "../component/img/lan-us.svg";
import LogoTh from "../component/img/lan-th.svg";

import "./css/style.css";
import Loading from "./Loading";
import { BlockTableModel } from "../Models/TableBookModel";
import { delay, getCartSession, getCountCart, removeSession, resetBookingSesstion } from "./Helpers/BaseHelper";
import { UnBlocktable } from "../Services/TableBookService";
import { useToast } from "./Helpers/ToastContext";
import { GuestLogout } from "../constants/BaseApi";
import { UserLogoutModel } from "../Models/AccountModel";
import { GuestLogoutService, LogoutService } from "../Services/AccountService";

const languages = [
  {
    code: 'en',
    name: 'ENG',
    list: 'ENGLISH',
    country_code: 'gb',
    icon: LogoUs
  },
  {
    code: 'th',
    name: 'TH',
    list: 'THAI',
    country_code: 'th',
    icon: LogoTh
  },
]

const Navbar = (props) => {

  const { navActiveIndex, setnavActiveIndex } = props

  const [routePath, setRoutePath] = useGlobalState('route');
  const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');
  const [loadCampaign, setLoadCampaign] = useGlobalState('loadCampaign');
  const [login, setLogin] = useState(false)
  const [toLink, setToLink] = useState('#')
  const [cItems, setCItems] = useState("")
  const [ticketlink, setTicketlink] = useState()
  const [ticket, setTicket] = useState()
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const navigate = useNavigate()

  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  let itCrossCam = sessionStorage.getItem('itCrossCampaign');
  itCrossCam = JSON.parse(itCrossCam);

  useEffect(() => {
    console.log('user', user);
  }, [user]);

  useEffect(() => {
    // console.log("NAVACTIVE", navActiveIndex);
  }, [navActiveIndex])

  useEffect(() => {
    calCart();
    showUserLogin()
  }, [myCart])

  const calCart = () => {
    let count = getCountCart(myCart);
    console.log("cartItems", count);
    if (count > 0 && count < 100) {
      setCItems(count)
    } else if (count > 100) {
      setCItems("99+")
    } else if (myCart != null && myCart.length > 0) {
      setCItems(count)
    } else {
      setCItems("")
    }
  }

  const showUserLogin = () => {
    user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    if (user != null) {
      setLogin(user.isLogin)

      if (user.isLogin) {
        setToLink(baseLink === '' ? routePath + '/#' : baseLink + routePath + '#')
      } else {
        setToLink(baseLink + routePath + "/signin")
      }
    } else {
      setLogin(false)
      setToLink(baseLink + routePath + "/signin")
    }
  }

  async function setLogout(e) {
    setLoading(true);
    await CallUnblockAllTable();
    e.preventDefault()

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    const payload = new UserLogoutModel(user.userId, user.email)

    await GuestLogoutService(payload, currentLanguageCode)
      .then((data) => {
        const items = data;
        if (items['Success']) {
          sessionStorage.removeItem('UserLogin')
          sessionStorage.removeItem('MyCart')
          setLogin(false)
          setToLink(baseLink + routePath + "/signin")
          setCItems("")
          props.setUserCode("")
          props.userLogout(true)
          setLoading(false);
          if (itCrossCam) {
            window.location.href = '/'
          } else {
            navigate("/")
          }
        }
      })
      .catch(error => {
        setLoading(false);
        console.log("Error: ", error)
      });

    // await fetch(coreApi + GuestLogout, requestOptions)
    //   .then(function (response) {
    //     return response;
    //   })
  }

  const goToCart = () => {
    myCart = sessionStorage.getItem('MyCart')
    myCart = JSON.parse(myCart)
    if (myCart != null && myCart.length > 0) {
      navigate("/package/" + myCart[0].Package[0].PackageCode + "/2")
    } else {
      showToast(t('cart_is_empty'), "warning")
    }
  }

  const onGetticket = async (item, isEdit) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': currentLanguageCode },
    };
    // let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
    const res = await fetch(apiLink + '/afcl/Package/Ticket', requestOptions);
    res
      .json()
      .then((res) => {
        let tic = res['Result']
        setTicket(res['Result']);
        let aIndex
        if (tic != null) {
          aIndex = tic.Packages.find((e) => {
            return e.PackageCode === 'AWPK001';
          });
        }
        let netP
        if (aIndex.IsDiscount) {
          netP = aIndex.PackagePrice - aIndex.DiscountPrice
        } else {
          netP = aIndex.PackagePrice
        }

        let data = {
          PackageCode: aIndex.PackageCode,
          PackageName: aIndex.PackageName,
          ItemCode: "",
          ItemName: "",
          Qty: 1,
          Tax: 0,
          ItemPrice: netP,
          ItemDiscount: aIndex.IsDiscount ? aIndex.DiscountPrice : 0,
          PriceName: aIndex.PriceName,
          PriceUnit: '',
          TotalPrice: netP,
          VisitDate: null,
          VisitTime: '',
          VisitLocation: '',
          step: aIndex.StepId,
          status: 'normal'
        }
        onAddToCart(data, false)
      })
      .catch((error) => {
        console.log(error);
      });

  }


  const onAddToCart = async (item, isEdit) => {

    myCart = sessionStorage.getItem('MyCart')
    myCart = JSON.parse(myCart)

    let aIndex = -1

    if (myCart != null) {
      aIndex = myCart.findIndex(e => { return e.PackageCode === item.PackageCode })
    } else {
      myCart = []
    }

    if (aIndex === -1) {
      if (item.Qty > 0) {
        myCart = [...myCart, item]
        setCart(myCart)
        window.location.href = baseLink + 'package/AWPK001/1'
      }
    } else {
      let preQty = myCart[aIndex].Qty
      let newQty = preQty + 1

      const editCart = myCart.map(e => {
        if (e.PackageCode === item.PackageCode && e.ItemCode === item.ItemCode) {
          return { ...e, Qty: newQty, VisitDate: item.VisitDate, VisitLocation: item.VisitLocation, VisitTime: item.VisitTime, status: 'normal' }
        }
        return e
      })
      setCart(editCart)
      window.location.href = baseLink + 'package/AWPK001/1'
    }

  }

  async function CallUnblockAllTable() {
    myCart = getCartSession();
    if (myCart !== null && myCart?.length > 0) {
      const payload = new BlockTableModel(
        myCart.map((e) => {
          return { TableNo: e.TableNo }
        })
      )
      await delay(1000);
      try {
        await UnBlocktable(payload, currentLanguageCode ?? 'EN')
          .then((response) => {
            if (response.Success) {
              const data = response.Result;
              resetBookingSesstion();
              removeSession('MyCart');
              removeSession('CurrentEvent');
              console.log(data);
              delay(1500);
            } else {
              throw (response.Result.Message);
            }
          }).catch((error) => {
            console.log(error);
            throw error;
          })
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  }

  useEffect(() => {
    if (cart.length > 0) sessionStorage.setItem('MyCart', JSON.stringify(cart));
  }, [cart]);

  const currentLanguageCode = cookies.get('i18next') || 'th'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    props.getLanguage(currentLanguageCode)
  }, [currentLanguageCode])

  const menuItem = [
    { name: 'table', icon: TableIcon, link: '/' },
    { name: 'beach_club', icon: BeachClub, link: '/event' },
  ]

  return (
    <>
      {inactiveCam ?
        <div style={{ pointerEvents: inactiveCam ? 'none' : 'inherit' }}>
          <nav className="fixed-top d-block d-lg-none ">
            <div className="container-fluid navi">
              <div className="row justify-content-between align-items-center">
                <div className="col-4 ">
                  <img src={logo} alt="" className="logo" style={{ width: '50%' }} />
                </div>
                <div className="col-8 text-end">
                  <div className="row navmain">
                    <div className="col-3 p-0">
                      <div className="col-nav border-right">
                        <div className="wrapper text-center ">

                          <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              {currentLanguage.name}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              {languages.map(({ code, name, list, country_code }) => (
                                <li key={country_code}>
                                  <span style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }}></span>
                                  {list}
                                </li>
                              ))}
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="col-3 p-0">
                      <div className="col-nav border-right">
                        <div className="wrapper ">

                          <div className="dropdown companymenuli">
                            <span className="mb-0">
                              <User className="icon" />
                            </span>

                            {/* {login && (
                              <ul className="dropdown-menu text-center py-4 px-3" aria-labelledby="dropdownMenuButton">
                                <li><img src={logo} className="logo-popup" /></li>
                                <li className="my-3">
                                  <img src={user.photo === '' ? PicProfile : user.photo} alt="" className="profilepic-navber" /></li>
                                <li className="mb-4">{user.fName + ' ' + user.lName}</li>
                                <li className="mb-2">
                                  <Button className="btn-changepw text-capitalize btn-light btn-shadow">{t('change_passcode')}</Button>
                                </li>
                                <li><Button className="btn-logout text-capitalize btn-success" onClick={setLogout}>{t('logout')}</Button></li>
                              </ul>
                            )} */}

                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="col-3 p-0">
                      <div className="col-nav border-right">
                        <div className="wrapper ">

                          <div className="mb-0 open-cart">
                            <Cart className="icon" />
                          </div>
                          <div>{cItems != "" && (<span className="icon-button-badge">{cItems}</span>)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 p-0 bg-black color-white">
                      <div className="col-nav">
                        <div className="wrapper ">
                          <a
                            href="#"
                            className="mb-0"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarTogglerDemo03"
                            aria-controls="navbarTogglerDemo03"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                          >
                            <Hamburger className="icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navmain">

                <li className="nav-item">
                  <div className="col-nav">
                    <div className="wrapper ">
                      {t('tickets')}
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="col-nav">
                    <div className="wrapper text-center ">
                      <p className="mb-1 font-8 color-grey">{t('opening_hours')}</p>
                      <p className="mb-0 font-14 weight-700">{t('time_opening')}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>

          <nav className="navbar navbar-expand-lg fixed-top d-none d-lg-block">
            <div className="container-fluid ps-4">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo03"
                aria-controls="navbarTogglerDemo03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <img src={logo} className="logo" />
              <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navmain">

                  <li className="nav-item">
                    <div className="col-nav p-0">
                      <div className="wrapper ">
                        {/* onClick={() => {
                     addToCart(
                       {
                         PackageCode: packageCard.PackageCode,
                         PackageName: packageCard.PackageName,
                         ItemCode: "",
                         ItemName: "",
                         Qty: 1,
                         Tax: 0,
                         ItemPrice: netPrice,
                         ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
                         PriceName: packageCard.PriceName,
                         PriceUnit: '',
                         TotalPrice: netPrice,
                         VisitDate: null,
                         VisitTime: '',
                         VisitLocation: '',
                         step: packageCard.StepId,
                         status: 'normal'
                       }, false
                     )
                   }} */}
                        <a className="mb-0 ticket">
                          {t('tickets')}
                        </a>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-right">
                      <div className="wrapper text-center ">
                        <p className="mb-1 font-8 color-grey">{t('opening_hours')}</p>
                        <p className="mb-0 font-14 weight-700">
                          {t('time_opening')}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-right">
                      <div className="wrapper text-center ">

                        <div className="dropdown">
                          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {currentLanguage.name}
                          </button>
                          {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {languages.map(({ code, name, list, country_code }) => (
                              <li key={country_code}>
                                <span style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }}></span>
                                {list}
                              </li>
                            ))}
                          </ul> */}
                        </div>

                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-right">
                      <div className="wrapper ">
                        <li className="dropdown companymenuli">
                          <User className="icon" />
                        </li>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav border-">
                      <div className="wrapper ">

                        <span className="mb-0 p-3 open-cart" style={{ cursor: "pointer" }}>
                          <Cart className="icon" />
                          {cItems != "" && (<span className="icon-button-badge">{cItems}</span>)}
                        </span>

                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        :
        <div>
          {/* Mobile */}
          <nav className="fixed-top d-block d-lg-none" style={{ zIndex: '1049' }}>
            <div className="container-fluid navi main-79">
              <div className="row justify-content-between align-items-center" style={{ height: '65px' }}>
                <div className="ps-1" style={{ pointerEvents: loadCampaign ? 'inherit' : 'none', width: '50%' }} >
                  <a className="navbar-brand" href={itCrossCam ? baseLink + '/' : (baseLink + routePath == "" ? '/' : baseLink + routePath)}>
                    <img src={logoMobile} style={{ width: '80px' }} alt="" className="logo" />
                  </a>
                </div>
                {/* <div className="text-end" style={{ width: '50%' }}> */}
                <div className="menu-nav">
                  <div className="wrapper text-center" style={{ width: '60px' }}>
                    <img id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ width: '33px' }} src={languages.find((x) => x.name === currentLanguage.name)?.icon ?? null} />

                    <ul className="dropdown-menu lan-position" aria-labelledby="dropdownMenuButton1">
                      {languages.map(({ code, name, list, country_code }) => (
                        <li key={country_code}>
                          <a href="#" className={classNames('dropdown-item', { disabled: currentLanguageCode === code, })} onClick={() => { i18next.changeLanguage(code) }}>
                            <span style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }}></span>
                            {list}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="wrapper text-center">
                    <div className="dropdown">
                      {/* <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {currentLanguage.name}
                      </button> */}

                    </div>
                  </div>

                  <div className="wrapper text-center" style={{ width: '60px' }}>
                    <div className="dropdown companymenuli">
                      <span className="mb-0" onMouseOver={showUserLogin}
                        style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }}
                        onClick={() => {
                          if (!login)
                            navigate("/signin")
                        }}>
                        {!login && (
                          <img src={UserLight} style={{ width: '30px' }} />
                        )}


                      </span>

                      {login && (
                        <>
                          {user.photo === '' ? (
                            <img src={UserLight} style={{ width: '30px' }} />
                          ) : (
                            <img src={user.photo} className="logo profilepic" style={{ width: '35px', height: '35px' }} />
                          )}
                          <ul className="dropdown-menu text-center py-4 px-3 logout-position" aria-labelledby="dropdownMenuButton">
                            <li><img src={logo} className="logo-popup" /></li>
                            <li className="my-3">
                              <img src={user.photo === '' ? PicProfile : user.photo} alt="" className="profilepic-navber" /></li>
                            <li className="mb-4">{user.fName + ' ' + user.lName}</li>
                            <li className="mb-2">
                              <a href={baseLink + routePath + "/changepw"}>
                                <Button className="btn-changepw text-capitalize btn-light btn-shadow">{t('change_passcode')}</Button>
                              </a>
                            </li>
                            <li><Button className="btn-logout text-capitalize btn-success" onClick={setLogout}>{t('logout')}</Button></li>
                          </ul>
                        </>


                      )}
                    </div>
                  </div>

                  <div className="wrapper text-center" style={{ width: '60px' }}>
                    <div className="mb-0 open-cart" onClick={goToCart}>
                      <img src={CartLight} style={{ width: '33px' }} />
                    </div>
                    <div>{cItems != "" && (<span className="icon-button-badge">{cItems}</span>)}</div>
                  </div>
                  {/* <div className="col-3 p-0 bg-black color-white">
                      <div className="col-nav">
                        <div className="wrapper ">
                          <a
                            href="#"
                            className="mb-0"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarTogglerDemo03"
                            aria-controls="navbarTogglerDemo03"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                          >
                            <Hamburger className="icon" />
                          </a>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
              {/* contenttt */}
            </div>

            {/* Nav Menu select */}
            <div className='main-79'>
              <div className='menu-container'>
                {menuItem && menuItem.map((item, index) => (
                  <a onClick={() => { setnavActiveIndex(index + 1); navigate(item.link); }}>
                    <div className={`menu-item ${navActiveIndex == index + 1 && 'active'}`}>
                      <div className="contain-border">
                        <img style={{
                          webkitMask: `url(${item.icon})`,
                          fontSize: '50px',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          // background: navActiveIndex == index + 1 ? '-webkit-linear-gradient(91deg, #520a7a 4.86%, #820228 91.36%)' : '#fff',
                          background: navActiveIndex == index + 1 ? '#9A614D' : '#fff',
                          height: '25px',
                          width: '25px',
                        }} alt="" src="" />
                        <p>{t(`${item.name}`)}</p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </nav>

          {/* Desktop */}
          <nav className="navbar navbar-expand-lg fixed-top d-none d-lg-block" style={{ zIndex: '1049' }}>
            <div className="container-fluid ps-2" style={{ padding: '5px 0' }}>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo03"
                aria-controls="navbarTogglerDemo03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <a style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }} className="navbar-brand" href={itCrossCam ? baseLink + '/' : baseLink + routePath == "" ? '/' : baseLink + routePath}>
                <img src={logo} style={{ width: '100%', maxWidth: '200px', maxHeight: '60px' }} className="logo" />
              </a>
              <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navmain">
                  <li className="nav-item">
                    <div className="col-nav">
                      <div className="wrapper text-center ">
                        <img style={{ width: '33px' }} src={languages.find((x) => x.name === currentLanguage.name)?.icon ?? null} />
                      </div>
                      <div className="wrapper text-center ">

                        <div className="dropdown">
                          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {currentLanguage.name}
                          </button>
                          <ul className="dropdown-menu lan-position" aria-labelledby="dropdownMenuButton1">
                            {languages.map(({ code, name, list, country_code }) => (
                              <li key={country_code}>
                                <a href="#" className={classNames('dropdown-item', { disabled: currentLanguageCode === code, })} onClick={() => { i18next.changeLanguage(code) }}>
                                  <span style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }}></span>
                                  {list}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>

                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="col-nav">
                      <div className="wrapper ">
                        <li className="dropdown companymenuli">
                          {login && (
                            <>
                              <a style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }} href={toLink} className="mb-0" onMouseOver={showUserLogin}>
                                {user.photo === '' ? (
                                  <div className="d-flex align-items-center">
                                    <User className="icon" />
                                    <p className="m-0 px-3 ">{user.fName + ' ' + user.lName}</p>
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <img src={user.photo} className="logo profilepic" style={{ width: '35px', height: '35px' }} />
                                    <p className="m-0 px-3 ">{user.fName + ' ' + user.lName}</p>
                                  </div>
                                )}
                              </a>
                              <ul className="dropdown-menu box-logout submenu logout-position" aria-labelledby="dropdownMenuButton">
                                <li><img src={logo} className="logo-popup" style={{ width: "55%" }} /></li>
                                <li className="box-text-signin"><img src={user.photo === '' ? PicProfile : user.photo} className="logo profilepic" /></li>
                                <li className="box-text-signin">{user.fName + ' ' + user.lName}</li>
                                <li className="mb-3">
                                  <a href={baseLink + routePath + "/changepw"}>
                                    <Button className="text-capitalize btn-light btn-shadow">{t('change_passcode')}</Button>
                                  </a>
                                </li>
                                <li><Button className="text-capitalize btn-success" onClick={setLogout}>{t('logout')}</Button></li>
                              </ul>
                            </>
                          )}

                          {!login && (
                            <>
                              <a style={{ pointerEvents: loadCampaign ? 'inherit' : 'none' }} href={toLink} className="mb-0" onMouseOver={showUserLogin}>
                                <div className="d-flex gap-2">
                                  <img src={loginIcon} style={{ width: "21px" }} />
                                  <p className="m-0 d-flex justify-content-center align-items-center" style={{ fontWeight: '700' }}>{t('sign_in_register')}</p>
                                </div>
                              </a>
                            </>
                          )}

                        </li>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Nav Menu select */}
            <div className='main-79'>
              <div className='menu-container'>
                {menuItem && menuItem.map((item, index) => (
                  <a onClick={() => { setnavActiveIndex(index + 1); navigate(item.link); }}>
                    <div className={`menu-item ${navActiveIndex == index + 1 && 'active'}`}>
                      <div className="contain-border">
                        <img style={{
                          webkitMask: `url(${item.icon})`,
                          fontSize: '50px',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          // background: navActiveIndex == index + 1 ? '#db5927' : '#fff',
                          background: navActiveIndex == index + 1 ? '#9A614D' : '#fff',
                          height: '25px',
                          width: '25px',
                        }} alt="" src="" />
                        <p>{t(`${item.name}`)}</p>
                      </div>
                    </div>
                  </a>
                ))}
                <div className='menu-item' style={{ justifyContent: 'flex-end', width: '120px' }}>
                  <span className="mx-auto my-auto open-cart" style={{ cursor: "pointer" }} onClick={goToCart}>
                    <img src={CartLight} style={{ width: '30px' }} />
                    {cItems != "" && (<span className="icon-button-badge">{cItems}</span>)}
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </div>
      }

      <Loading loading={loading} />
    </>
  );
};

export default Navbar;
