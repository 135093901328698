
import { coreApi } from "../constants/DataPublic";
import { CheckExternalLogin, CheckUserExists, ExternalGuestregister, GetCountry, GetCountryPhone, GetProvince, GetTerms, GuestChangePassword, GuestForgotPassword, GuestLogin, GuestLogout, GuestRegister, VisitorRegistor, VisitorUpdateProfile, } from '../constants/BaseApi';
import { makeApiRequest } from './BaseApiService';

/* -------------------- Guest -------------------- */
export async function GuestLoginService(model, language) {
    return makeApiRequest(GuestLogin, language, 'post', model, {}, null, coreApi);
}

export async function GuestLogoutService(model, language) {
    return makeApiRequest(GuestLogout, language, 'post', model, {}, null, coreApi);
}

export async function VisitorRegisterService(model, language) {
    return makeApiRequest(VisitorRegistor, language, 'post', model, {}, null, coreApi);
}

export async function VisitorUpdateService(model, language) {
    return makeApiRequest(VisitorRegistor, language, 'post', model, {}, null, coreApi);
}

export async function GuestRegisterService(model, language) {
    return makeApiRequest(GuestRegister, language, 'post', model, {}, null, coreApi);
}

export async function CheckUserExistsService(model, language) {
    return makeApiRequest(CheckUserExists, language, 'post', model, {}, null, coreApi);
}

export async function GuestForgotPassService(model, language) {
    return makeApiRequest(GuestForgotPassword, language, 'post', model, {}, null, coreApi);
}

/* -------------------- External -------------------- */

export async function ExternalGuestRegisterService(model, language) {
    return makeApiRequest(ExternalGuestregister, language, 'post', model, {}, null, coreApi);
}

export async function ExternalGuestCheckLoginService(model, language) {
    return makeApiRequest(CheckExternalLogin, language, 'post', model, {}, null, coreApi);
}

export async function GuestChangePasswordService(model, language) {
    return makeApiRequest(GuestChangePassword, language, 'post', model, {}, null, coreApi);
}


export async function getCountryService(language) {
    return makeApiRequest(GetCountry, language, 'get', null, {}, null, coreApi);
}

export async function getProvinceService(countryId, language) {
    return makeApiRequest(GetProvince + countryId, language, 'get', null, {}, null, coreApi);
}

export async function getCountryPhoneService(language) {
    return makeApiRequest(GetCountryPhone, language, 'get', null, {}, null, coreApi);
}

export async function getTermsService(language) {
    return makeApiRequest(GetTerms, language, 'get', null, {}, null, coreApi);
}

