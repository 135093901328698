import React, { useEffect, useState } from 'react';
import { baseLink } from '../../constants/DataPublic';
import { useTranslation } from "react-i18next";
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from "../img/close.svg";
import { useNavigate } from 'react-router-dom';

const StepBar = (props) => {

    let myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);
    const navigate = useNavigate()


    const { itemGroup } = props
    const { currentStep } = props
    const { packageCode } = props

    const [showConfirm, setShowConfirm] = useState(false)

    useEffect(() => {
        console.log("StepBar ", currentStep, " :: ", itemGroup);
    }, [])

    const setCurrentStep = (index) => {
        if (index !== 1) {
            if (index > 1 && myCart.length == 0) {
                console.log("indexindex", index)
                setShowConfirm(true)
            } else {
                props.setCurrentStep(index)
                navigate('/package/' + packageCode + '/' + index);
                console.log("done", index)
            }
        } else {
            navigate('/')
        }
    }

    // ภาษา
    const { t } = useTranslation();

    return (
        <div className="section section-step bg-79-sand">
            <div className="container">
                <ul id="progressbar" className='d-flex justify-content-center'>
                    {
                        itemGroup.map((e, index) => {
                            if (index + 1 === currentStep) {
                                return (
                                    <li className="active" key={index}>
                                        <span className="step border-gradient gradient-background" style={{ cursor: "pointer" }} onClick={() => setCurrentStep(index + 1)}><p className='m-0'>{index + 1}</p></span>
                                        <span className="steptitle gradient-text" style={{ fontWeight: '800' }}>{e.StepName}</span>

                                    </li>
                                )
                            } else if (index + 1 < currentStep) {
                                return (
                                    <li className="done" key={index}>
                                        <span className="material-icons step" style={{ cursor: "pointer" }} onClick={() => setCurrentStep(index + 1)}>done</span>
                                        <span className="steptitle gradient-text" style={{ fontWeight: '800' }}>{e.StepName}</span>

                                    </li>
                                )
                            } else {
                                return (
                                    <li key={index}>
                                        <span className="step bg-79-sun" style={{ cursor: "pointer" }} onClick={() => setCurrentStep(index + 1)}>{index + 1}</span>
                                        <span className="steptitle" style={{ fontWeight: '800' }}>{e.StepName}</span>
                                    </li>
                                )
                            }
                        })
                    }
                    <li className={currentStep === 4 ? "active" : ""}>
                        <span className={currentStep === 4 ? "step active border-gradient gradient-background" : "step bg-79-sun"} style={{ cursor: "pointer" }} onClick={() => setCurrentStep(4)}>{itemGroup.length + 1}</span>
                        <span className={currentStep === 4 ? "steptitle gradient-text" : "steptitle"}>{t('checkout')}</span>
                    </li>
                </ul>
                <Modal show={showConfirm} className="col-12 cart-placeorder">
                    <a href="#" className="close">
                        <Close className="popup-close" onClick={() => setShowConfirm(false)} />
                    </a>
                    <h5 className="text-center text-danger py-5">{t('please_select_package')}</h5>
                </Modal>
            </div>
        </div >
    )
}
export default StepBar;