import React from 'react'
import { ReactSVG } from 'react-svg';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import closeButton from '../img/button_close.svg';
import { getEventStart } from '../Helpers/EventHelper.jsx'
import NotFound from '../img/notfound_new.png'
import { useTranslation } from 'react-i18next';
import { formatToCurrency } from '../Helpers/BaseHelper.jsx';
import SorasoButton from '../buttons/SorasoButton.jsx';
import { useGlobalState } from '../../global.js';

const EventSelectModal = (props) => {
    const { selectDate, showModal, onCloseModal, onClick, event, selectEvent } = props;

    const [languageGlobal] = useGlobalState('language');

    const { t } = useTranslation();

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    return (
        <Modal show={showModal} onHide={onCloseModal} style={{ cursor: 'default' }} className='event-select-modal'>
            <ModalHeader className='modal-header--sticky d-flex justify-content-between mb-3'>
                <Modal.Title className='m-0 font-grey'><h4 className='weight-600'>{t('event')} : {new Date(selectDate).toLocaleDateString(languageGlobal, options)}</h4></Modal.Title>
                <ReactSVG src={closeButton} className="col-md-2 svg-icon text-end" onClick={onCloseModal} style={{ cursor: 'pointer' }} />
            </ModalHeader>
            <ModalBody className='mb-3' style={{ height: '100%', overflow: 'auto' }}>
                {event.map((item, index) => {
                    return (
                        <div key={item.CampaignCode}>
                            <div className={selectEvent.id == item.CampaignCode ? 'event-select gradient-background mb-1' : 'event-select mb-1'}
                            >
                                <div className='row'>
                                    <div className='col-md-auto'>
                                        <img className='event-select-image' src={item.EventPicture}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = NotFound;
                                            }} />
                                    </div>
                                    <div className='col-md-6 d-flex flex-column justify-content-between align-items-start'>
                                        <div className={selectEvent.id == item.CampaignCode ? '' : 'font-grey'}>
                                            <h5 className='weight-600 mb-0'>{item.CampaignName}</h5>
                                        </div>
                                        <div className='d-flex weight-500'>
                                            {item.EventTime + ': ' + getEventStart(item)}
                                        </div>
                                        <div className={selectEvent.id == item.CampaignCode ? 'w-600' : 'w-600 font-grey'}>
                                            <h5 className='weight-600 mb-0'>{t('price')} : {formatToCurrency(item.EventPrice)}</h5>
                                        </div>
                                    </div>
                                    <div className='col-md d-flex justify-content-end align-items-end'>
                                        <div className='w-75 text-capitalize'>
                                            {selectEvent.id !== item.CampaignCode && (
                                                <SorasoButton
                                                    text={t('buy_now')}
                                                    style={'primary'}
                                                    onClick={() => { onClick({ id: item.CampaignCode, value: item.CampaignName }) }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    )
                })}
            </ModalBody>
        </Modal >
    )
};

export default EventSelectModal
