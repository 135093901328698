import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import Table from './Table';
import Zone from './Zone';
import { useMediaQuery } from 'react-responsive'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import NotFound from '../img/notfound_new.png'

const ImageMap = (props) => {
    const { tablePlan, onSelectZone, children, onSelectTable, selectedTable } = props;

    // Page State
    const [selectedBox, setSelectedBox] = useState(-1);
    const [hoveredBox, setHoveredBox] = useState(null);
    const [boxes, setBoxes] = useState([]);
    const [newImageWidth, setNewImageWidth] = useState(0);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 661px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    // Use Ref
    const mapRef = useRef();

    // This function calculate X and Y
    const getPosition = () => {
        const el = mapRef.current ?? null;
        if (el !== null) {
            const rect = el.getBoundingClientRect() ?? null;
            if (rect !== null) {
                const imgWidth = rect.width == 0 ? tablePlan.AreaWidth : rect.width;
                const imgHeight = rect.height == 0 ? tablePlan.AreaHeight : rect.height;
                setNewImageWidth(imgWidth);
                const scaledBoxes = tablePlan.Details.map((box) => ({
                    Left: box.Left * (imgWidth / tablePlan.AreaWidth),
                    Top: box.Top * (imgWidth / tablePlan.AreaWidth),
                    BorderRadius: box.BorderRadius,
                    DisplayMergeTableNo: box.DisplayMergeTableNo,
                    MergeTableNo: box.MergeTableNo,
                    SeatCapacity: box.SeatCapacity,
                    SeqNo: box.SeqNo,
                    StatusColor: box.StatusColor,
                    TableHeight: box.TableHeight,
                    TableWidth: box.TableWidth,
                    TableNo: box.TableNo,
                    TableStatus: box.TableStatus,
                    TableName: box.TableName,
                    PackagePrice: box.PackageAmt
                }));
                setBoxes(scaledBoxes);
            }
        }
    };

    // Get the position of the red box in the beginning
    useEffect(() => {
        getPosition();
    }, [tablePlan]);

    useEffect(() => {
        getPosition();
        window.addEventListener("resize", getPosition);
    }, []);

    // Function
    const handleBoxClick = (tableNo) => {
        if (children === 'zone') {
            onSelectZone(tableNo);
        } else {
            onSelectTable(tableNo);
        }

    };

    const handleBoxMouseEnter = (index) => {
        setHoveredBox(index);
    };

    const handleBoxMouseLeave = () => {
        setHoveredBox(null);
    };

    const checkTableAdd = (table) => {
        let find = selectedTable.find((e) => e == table);
        return !find ? false : true;
    }

    const checkTableinCart = (table) => {
        return selectedTable.includes(table);
    }

    const checkTableAvailable = (table) => {
        if (children === 'zone') return true;
        if (table.TableStatus === 'VC') return true;
        return false;
    }

    return (
        <div style={{ height: '100%' }}>
            <div
                style={{
                    position: 'relative',
                    display: 'inline-block',
                    height: '100%',
                    cursor: 'grab',
                    userSelect: 'none',
                    width: isTabletOrMobile ? '100%' : ''
                }}
            >
                <div ref={mapRef}>
                    <LazyLoadImage
                        className='table-map-image'
                        src={tablePlan.Picture} // Replace with your image source
                        alt="Your Image"
                        width={isTabletOrMobile ? window.innerWidth - 50 : tablePlan.AreaWidth}
                        height={isTabletOrMobile ? (tablePlan.AreaHeight / tablePlan.AreaWidth) * (window.innerWidth - 50) : tablePlan.AreaHeight}

                        style={{
                            zIndex: 1,
                            width: isTabletOrMobile ? '100%' : '',
                        }}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = NotFound;
                        }}
                    />
                </div>
                {boxes.map((box, index) => (
                    index >= 0 && box.Top !== 0 ?
                        <div key={index}>
                            <div
                                key={index}
                                onClick={checkTableAvailable(box) ? () => handleBoxClick(box.TableNo) : null}
                                onMouseEnter={() => handleBoxMouseEnter(index)}
                                onMouseLeave={handleBoxMouseLeave}
                                className='canvas-box'
                                style={{
                                    left: box.Left,
                                    top: children === "zone" ? isMobile ? box.Top - 6 : box.Top : box.Top - 8,
                                    width: children === "zone" ? ((newImageWidth / tablePlan.AreaWidth) * box.TableWidth + (isMobile ? 20 : isTabletOrMobile ? 40 : 100)) + 'px' : ((newImageWidth / tablePlan.AreaWidth) * box.TableWidth + 5) + 'px',
                                    height: children === "zone" ? ((newImageWidth / tablePlan.AreaWidth) * box.TableWidth + (isMobile ? 4 : isTabletOrMobile ? 8 : 20)) + 'px' : ((newImageWidth / tablePlan.AreaWidth) * box.TableWidth + 1) + 'px',
                                    transform: `scale(${hoveredBox === index || selectedBox === index ? 1.05 : 1})`,
                                    transition: 'transform 0.3s ease-in-out',
                                    // zIndex: 10,
                                    borderRadius: box.BorderRadius,
                                    cursor: checkTableAvailable(box) ? 'pointer' : 'not-allowed',
                                }}>
                                {children === "zone" ?
                                    <Zone isTabletOrMobile={isTabletOrMobile} isMobile={isMobile} tableName={box.TableName} pkPrice={box.PackagePrice} /> :
                                    <Table status={checkTableinCart(box.TableName) ? 'CRT' : box.TableStatus} isaddCart={checkTableAdd(box.TableName)} />
                                }
                                {hoveredBox === index && box.TableStatus && !isTabletOrMobile && (
                                    <div className='tooltiptext'
                                        style={{
                                            // position: 'absolute',
                                            // color: 'black',
                                            // top: '-' + box.TableHeight / 2 + 'px'
                                            // top: '-' + box.TableHeight / 2 + 'px',
                                            // left: box.TableHeight / 3 + 'px',
                                            // zIndex: 1,
                                        }}
                                    >
                                        <div className="w-600" style={{ padding: '5px' }}>
                                            <span>{box.TableName}</span>
                                        </div>
                                    </div>
                                )}
                            </div >
                        </div>
                        : null
                ))}
            </div >
        </div >
    );
};

export default ImageMap;
