import React from 'react';
// import BackToTop from "react-back-to-top-button";
import { ReactComponent as ArrowUp } from "../img/chevron-down-79.svg"
import ScrollToTop from "react-scroll-to-top";


const BackToTopPackget = () => {
  return (
    // <BackToTop showOnScrollDown showAt={50} speed={3000} easing="easeInOutQuint">
    <ScrollToTop smooth style={{ zIndex: 999, padding:'0px' }} component={<ArrowUp style={{ transform: 'rotateX(180deg)' }} />} width='20'>
      {/* <button className="BackToTop">
        <img src={ArrowUp} alt="" style={{ width: "20px", transform: 'rotateY(180deg)' }} />
      </button> */}
    </ScrollToTop>
    // </BackToTop>
  )
}
export default BackToTopPackget;