import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { apiLink, coreApi } from '../constants/DataPublic';
import { UserLoginModel } from '../Models/AccountModel';
import { GuestLogin } from '../constants/BaseApi';
import { hashStringWithSHA256 } from '../component/Helpers/EncryptHelper';
import { GuestLoginService } from '../Services/AccountService';
import { getCartSession } from '../component/Helpers/BaseHelper';
import { getPackageCart } from '../Services/PackageService';

const useLogin = (props) => {
  let userLogin = {};
  const { lang } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState("");
  const [result, setResult] = useState(false);
  const [message, setMessage] = useState('');
  const [requireEmail, setRequireEmail] = useState('');

  const [cartQTy, setCartQTy] = useState();

  const navigate = useNavigate();

  function submitLogin(e) {
    e.preventDefault();
    setMessage('')
    setRequireEmail('')
    // Validate email
    // findUser();
    if(email !== null && email !== ''){
      setRequireEmail('')
    }else{
      setRequireEmail('warn_email')
    }
    if(password !== null && password !== ''){
      setMessage('')
    }else{
      setMessage('please_fill_password')
    }
    if (emailValidation() && email !== null && email !== '' && password !== null && password !== '') {
      longinUser();
    }
  }

  function submitForgot(e) {
    e.preventDefault();
    if (emailValidation()) {
      // Validate email
      findUserForgot();
      if (result) {
        console.log('Result: ', result);
      }
    }
  }

  function emailValidation() {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || email === '') {
      setRequireEmail('warn_email');
      return false;
    } else if (regex.test(email) === false) {
      setRequireEmail('invalid_email');
      return false;
    } else {
      return true;
    }
  }

  function refreshPage() {
    window.location.reload();
  }

  async function longinUser() {
    const payload = new UserLoginModel(email, hashStringWithSHA256(password))
    await GuestLoginService(payload, lang)
      .then((data) => {
        const items = data;
        console.log(items);
        if (items['Success']) {
          let userLogin = {
            fName: items['Result']['User'].FirstName,
            lName: items['Result']['User'].LastName,
            email: items['Result']['User'].Email,
            userId: items['Result']['User'].UserId,
            userCode: items['Result']['User'].UserCode,
            photo: items['Result']['User'].UserPicture,
            isLogin: true,
            channelCode: items['Result']['ChannelCode'],
            confirmationNo: '',
            idCardNo: '',
            phoneNo: '',
            type: 'user',
            guestDone: false,
          };
          sessionStorage.setItem('UserLogin', JSON.stringify(userLogin));
          setMessage('');
          refreshPage();
          let cart = getCartSession();
          if (cart !== null && cart.length > 0) {
            window.location.href = 'package/' + cart[0].Package[0].PackageCode + '/4';
          } else {
            navigate('/');
          }
        } else {
          console.log('Success = false');
          setMessage(items['Message']);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        // alert('Error: API /afcl/Account/finduser ', error)
        return false;
      });
  }

  async function findUser() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },

      body: JSON.stringify({ Email: email }),
    };
    await fetch(coreApi + '/afcl/Account/finduser', requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log(items);
        if (items['Success'] === true) {
          userLogin = {
            fName: items['Result'].FirstName,
            lName: items['Result'].LastName,
            email: items['Result'].Email,
            userId: items['Result'].UserId,
            userCode: items['Result'].UserCode,
            photo: items['Result'].UserPicture,
            isLogin: false,
            channelCode: '',
            confirmationNo: '',
            idCardNo: '',
            phoneNo: '',
            type: 'user',
            guestDone: false,
          };
          sessionStorage.setItem('UserLogin', JSON.stringify(userLogin));
          setRequireEmail('');
          navigate('/pass');
        } else {
          console.log('Success = false');
          setRequireEmail('email_not_found');
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        alert('Error: API /afcl/Account/finduser ', error)
        return false;
      });
  }

  async function findUserForgot() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'accept-language': lang },
      body: JSON.stringify({ Email: email }),
    };
    await fetch(coreApi + '/afcl/Account/finduser', requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log(items);
        if (items['Success'] === true) {
          userLogin = {
            fName: items['Result'].FirstName,
            lName: items['Result'].LastName,
            email: items['Result'].Email,
            userId: items['Result'].UserId,
            userCode: items['Result'].UserCode,
            photo: items['Result'].UserPicture,
            isLogin: false,
            channelCode: '',
            confirmationNo: '',
            idCardNo: '',
            phoneNo: '',
            type: 'user',
            guestDone: false,
          };
          sessionStorage.setItem('UserLogin', JSON.stringify(userLogin));
          console.log('Success = true');
          setMessage('');
          setResult(true);
        }
        else {
          console.log('Success = false');
          setMessage(items['Message']);
          setResult(false);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        // alert('Error: API /afcl/Account/finduser ', error)
        setResult(false);
      });
  }

  async function getUserCart(userCode, language) {
    console.log('getUserCart', userCode);
    if (userCode != '' && userCode != null && userCode != undefined) {
      // const requestOptions = {
      //   method: 'GET',
      //   headers: { 'accept-language': language },
      // };
      // await fetch(apiLink + '/afcl/Package/Cart/CheckOut/V2/' + userCode, requestOptions)
      //   .then(function (response) {
      //     return response.json();
      //   })
      await getPackageCart(userCode, language)
        .then(function (data) {
          const items = data;
          console.log('getUserCart', items);
          if (items['Success'] === true) {
            // Cart ใหม่
            let myCart = sessionStorage.getItem('MyCart');
            myCart = JSON.parse(myCart);

            // Cart ที่ Save ไว้ก่อนหน้า
            let allCart = items['Result']['Order'];

            let user = sessionStorage.getItem('UserLogin');
            user = JSON.parse(user);
            user.confirmationNo = allCart.ConfirmationNo;
            sessionStorage.setItem('UserLogin', JSON.stringify(user));

            // รวม Cart
            let saveCart = [];
            allCart.Packages.forEach((e) => {
              if (e.ItemCode === '') {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                      return c.PackageCode === e.PackageCode;
                    });

                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: '',
                    ItemName: '',
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: '',
                    TotalPrice: '',
                    VisitDate: null,
                    VisitTime: '',
                    VisitLocation: '',
                    step: 1,
                    status: 'normal',
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              } else {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                      return c.PackageCode === e.PackageCode && c.ItemCode === e.ItemCode;
                    });
                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: e.ItemCode,
                    ItemName: e.ItemName,
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: e.PriceUnit,
                    TotalPrice: parseInt(e.Qty) * parseInt(e.ItemPrice),
                    VisitDate: e.VisitDate,
                    VisitTime: e.VisitTime,
                    VisitLocation: e.VisitLocation,
                    step: 1,
                    status: 'normal',
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              }
            });
            myCart = myCart === null || myCart.length === 0 ? [...saveCart] : [...myCart, ...saveCart];
            setCartQTy(myCart.length);
            sessionStorage.setItem('MyCart', JSON.stringify(myCart));
            console.log("scheckk nulll", myCart)
          } else {
            console.log('Success = false');
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
          // alert('Error: API /afcl/Package/Cart/CheckOut/ ', error)
        });
    }
  }
  return { email, password, result, message, requireEmail, userLogin, cartQTy, setEmail, setPassword, setResult, setMessage, emailValidation, submitLogin, submitForgot, getUserCart };
};

export default useLogin;
