import React from 'react'
import SorasoButton from '../buttons/SorasoButton'
import { useTranslation } from 'react-i18next';
import NotFound from '../img/notfound_new.png'
import { formatToCurrency } from '../Helpers/BaseHelper';
import { getEventStart } from '../Helpers/EventHelper.jsx'
import CustomDatePicker from '../Tableplan/CustomDatePicker';

function BottomPopup(props) {

    const {
        selectValue,
        type,
        isActive,
        title,
        onClose,
        dataList,
        onValueChange,

        mobile,
        eventDateArray,
        startDate,
        setStartDate } = props

    const { t } = useTranslation();

    // console.log("selectValue" + type, selectValue)
    // console.log("dataList" + type, dataList)
    return (
        <>
            <div className={`background-trans ${isActive ? 'active' : ''}`} onClick={onClose}>
            </div>
            {type === 'radio' && (
                <div className={`container-bottom-popup ${isActive ? 'active' : ''}`}>
                    <div onClick={onClose} style={{ textAlign: 'right', margin: '20px 25px 0px 0px', fontSize: '18px', fontWeight: 'bold', float: 'right' }}>x</div>
                    <p className='title-bottom-popup'>{title}</p>
                    <div style={{
                        overflow: 'scroll',
                        height: '85%',
                    }}>
                        {dataList && dataList.map((item, index) => (
                            <>
                                {/* {console.log("item.id", item)} */}
                                {/* {console.log("selectValue", selectValue)} */}
                                <label for={index} style={{ display: 'flex', padding: '0px 25px' }}>
                                    <div className='content-bottom-popup' style={{ height: '70px', width: '80%' }}>
                                        <p style={{ margin: 0, fontSize: '14px', fontWeight: '600' }}>{item.value} </p>
                                        <p style={{ margin: 0, color: '#a1a1a1' }}>{item.description}</p>
                                    </div>
                                    <div className='radio-popup'>
                                        <input id={index} value={item.id} defaultValue={selectValue} checked={item.id === selectValue} type='radio' name='radio' onChange={() => onValueChange(item)} />
                                    </div>
                                </label>
                            </>
                        ))}
                    </div>
                </div>
            )}
            {type === 'event' && (
                <div className={`container-bottom-popup ${isActive ? 'active' : ''}`}>
                    <div onClick={onClose} style={{ textAlign: 'right', margin: '20px 25px 0px 0px', fontSize: '18px', fontWeight: 'bold', float: 'right' }}>x</div>
                    <p className='title-bottom-popup'>{title}</p>
                    <div style={{
                        overflow: 'scroll',
                        height: '85%',
                    }}>
                        {dataList && dataList.map((item, index) => (
                            <>
                                <div className={selectValue.id == item.CampaignCode ? 'event-select gradient-background mb-1' : 'event-select mb-1'}
                                    // onClick={() => { onClick({ id: item.CampaignCode, value: item.CampaignName }) }}
                                    key={index}
                                >
                                    <div className='row'>
                                        <div className='col-3 col-md-auto'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                            <img style={{
                                                width: '50px',
                                                height: '55px',
                                                borderRadius: '5px',
                                                margin: 'auto',
                                                objectFit: 'cover'
                                            }} src={item.EventPicture}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = NotFound;
                                                }} />
                                        </div>
                                        <div className='col-5 col-md-6 d-flex flex-column justify-content-between align-items-start' style={{ padding: 0 }}>
                                            <div className={selectValue.id == item.CampaignCode ? '' : 'font-grey'}>
                                                <p className='weight-600 mb-0' style={{ fontSize: '14px' }}>{item.CampaignName}</p>
                                            </div>
                                            {/* <div className='row'>
                                        <div className='col'>
                                            {item.EventDescription}
                                        </div>
                                    </div> */}
                                            <div className='d-flex weight-500'>
                                                {/* <p style={{ fontSize: '12px' }}>{item.EventTime + ': ' + getEventStart(item)}</p> */}
                                                <p style={{ fontSize: '12px' }}>{getEventStart(item)}</p>
                                            </div>
                                            <div className={selectValue.id == item.CampaignCode ? 'w-600' : 'w-600 font-grey'}>
                                                <p className='weight-600 mb-0' style={{ fontSize: '14px' }}>{t('price')} : {formatToCurrency(item.EventPrice)}</p>
                                            </div>
                                        </div>
                                        <div className='col-4 col-md d-flex justify-content-center align-items-center'>
                                            <div className='w-100 text-capitalize'>
                                                {selectValue.id !== item.CampaignCode && (
                                                    <SorasoButton
                                                        mobileH={'35px'}
                                                        text={t('buy_now')}
                                                        style={'primary'}
                                                        onClick={() => { onValueChange({ id: item.CampaignCode, value: item.CampaignName }) }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            )}

            {type == 'date' && (
                <>
                    <div className={`container-bottom-popup ${isActive ? 'active date' : ''}`}>
                        <div onClick={onClose} style={{ textAlign: 'right', margin: '10px 25px 0px 0px', fontSize: '18px', fontWeight: 'bold', float: 'right' }}>x</div>
                        <p className='title-bottom-popup'>{title}</p>
                        <div style={{
                            height: '85%',
                            overflow: 'scroll',
                        }}>
                            <CustomDatePicker
                                isActive={isActive}
                                mobile={mobile}
                                eventDateArray={eventDateArray}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                setOpenBottomPopupDate={onClose}
                                onDateChange={onValueChange}
                            />
                        </div>
                    </div>
                </>
            )}

        </>
    )
}

export default BottomPopup