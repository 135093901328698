// AlertProvider.js
import React, { createContext, useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";

const SwalAlertContext = createContext();

export const useAlert = () => {
  return useContext(SwalAlertContext);
};

export const SwalAlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const { t } = useTranslation();

  const addAlert = (message, type = 'info', onOK, onCancel, customImage) => {
    const newAlert = { message, type };
    setAlerts([...alerts, newAlert]);

    const swalOptions = {
      title: message,
    };
    console.log("customImage", customImage)
    // Use a custom icon if provided, otherwise use the specified type
    if (customImage) {
      swalOptions.content = customImage; // Corrected this line
    } else {
      swalOptions.icon = type;
    }

    if (onCancel) {
      swalOptions.showCancelButton = true;
      swalOptions.confirmButtonText = t('confirm');
      swalOptions.cancelButtonText = t('Cancel');
    } else {
      swalOptions.confirmButtonText = t('confirm');
    }

    Swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        onOK && onOK();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        onCancel && onCancel();
      }
    });
  };

  const removeAlert = (index) => {
    const updatedAlerts = [...alerts];
    updatedAlerts.splice(index, 1);
    setAlerts(updatedAlerts);
  };

  return (
    <SwalAlertContext.Provider value={{ alerts, addAlert, removeAlert }}>
      {children}
    </SwalAlertContext.Provider>
  );
};
