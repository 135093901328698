import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import useLogin from '../customHook/useLogin'
import { useGlobalState } from '../global'

import { ReactComponent as Google } from "../component/img/btn-google.svg";

import "../component/css/style.css";
import "../component/css/custom.css";
import { CheckExternalLoginModel } from "../Models/AccountModel";
import { ExternalGuestCheckLoginService } from "../Services/AccountService";
import Loading from "./Loading";
import { getCartSession } from './Helpers/BaseHelper';

const GGLogin = (props) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  let { getUserCart } = useLogin({ lang: '' })
  const [needRegist, setNeedRegist] = useState(false)
  const [routePath, setRoutePath] = useGlobalState('route');
  const { language, ggtext } = props
  const [loading, setLoading] = useState(false);
  let myCart = getCartSession();


  let itCrossCam = sessionStorage.getItem('itCrossCampaign');
  itCrossCam = JSON.parse(itCrossCam);

  const handleFailure = (res) => {
    console.log("LOGIN FAILED! res: ", res);
  };

  const handleLogin = async (res) => {
    setLoading(true);
    console.log("LOGIN SUCCESS! res: ", res);
    await fetch("https://www.googleapis.com/oauth2/v3/userinfo", { method: "GET", headers: { "Authorization": res.token_type + ' ' + res.access_token }, }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      console.log(data)
      let ggUser = data;
      props.getGGUser(ggUser)
      // const requestOptions = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     "Provider": "Google",
      //     "ProviderKey": ggUser.sub,
      //     "Email": ggUser.email,
      //     "PushKey": "",
      //     "DeviceType": "",
      //     "DeviceKey": ""
      //   }),
      // };

      let payload = new CheckExternalLoginModel("Google", ggUser.sub, ggUser.email);
      await ExternalGuestCheckLoginService(payload, language)
        .then(function (data) {
          setLoading(false);
          const items = data;
          if (items["Result"].NeedRegister) {
            setNeedRegist(true)
          } else {
            props.setUserCode(items["Result"].User.UserCode)
            let userLogin = {
              fName: items["Result"].User.FirstName,
              lName: items["Result"].User.LastName,
              email: items["Result"].User.Email,
              userId: items["Result"].User.UserId,
              userCode: items["Result"].User.UserCode,
              photo: items["Result"].User.UserPicture,
              isLogin: true,
              channelCode: items["Result"].ChannelCode
            }
            setLoading(false);
            sessionStorage.setItem('UserLogin', JSON.stringify(userLogin))
            if (myCart && myCart.length > 0) {
              // navigate("/");
              window.location.href = '/package/' + myCart[0].Package[0].PackageCode + '/4';
            } else {
              window.location.href = '/';
            }
          }
          props.getNeedRegist(items["Result"].NeedRegister)
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);
          // alert('Error: API /afcl/Account/external/checklogin ', error)
        });
    });
  };

  const Login = useGoogleLogin({
    onSuccess: (res) => handleLogin(res),
    onFailure: (err) => handleFailure(err),
  })

  return (
    <div>
      {/* <a href={itCrossCam ? baseLink + '/' : (baseLink + routePath == "" ? '/' : baseLink + routePath)}> */}
      <button onClick={() => Login()} className="btn btn-link btnloginsocial ">
        <Google />
        {/* <span className="picloginsocial"></span><span className="ms-4">{t(ggtext)}</span> */}
      </button>
      {/* </a> */}
      <Loading loading={loading} />
    </div>
  );
};

export default GGLogin;
