import React from 'react'
import { Modal } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';

import closeButton from '../img/button_close.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Markup } from 'interweave';
import { formatToCurrency } from '../Helpers/BaseHelper';
import NotFound from '../img/notfound_new.png'
import { checkEventDisable } from '../Helpers/EventHelper';
import SorasoButton from '../buttons/SorasoButton';
import { useMediaQuery } from 'react-responsive';
import TextStrike from '../input/TextStrike';
import CaretDown from '../img/caret_down_icon.svg';

const PackageSelectModal = (props) => {
    const { showModal, onCloseModal, onClick, isShowButton, hoveredBox,
        dataEvent, pictureList, name, description, termAndCondition, price,
        onConfirm, oldPrice, discountPrice, packageInfo
    } = props;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const getImage = (pic) => {
        if (pic && pic.length > 0) {
            const picSize = pic.length < 2 ? 'landingSmall' : 'landingLarge';
            const findPicture = pic.find(x => x.PictureSize === picSize);
            if (findPicture) {
                return findPicture.PictureUrl;
            }
        }
        return "";
    };

    let isPicString = typeof pictureList === 'string';

    // Translation
    const { t } = useTranslation();
    // const tertms_markup = "<div>1. โปรโมชั่นสำหรับออนไลน์เท่านั้น</div><div>2. บัตรรายวันสามารถใช้งานได้เต็มวันก่อนหมดอายุการใช้งาน 31 ธันวาคม 2565</div><div>3. บัตรโปรโมชั่นราคาเดียวกันทั้งเด็กและผู้ใหญ่ (นับตั้งแต่ 3 ปีเป็นต้นไป)</div><div>4. บัตรที่ชำระเงินในใบเสร็จเดียวกันต้องนำมาใช้งานในวันเดียวกัน ไม่สามารถแยกใช้งานวันอื่นได้</div><div>5. บัตรที่ชำระเงินแล้วไม่สามารถขอคืนเงิน หรือแลกเปลี่ยนเป็นเงิน หรือเปลี่ยนเป็นโปรโมชั่นอื่นๆได้</div><div>6. บัตรนี้ไม่สามารถใช้กับอีเวนท์พิเศษ หรือ คอนเสิร์ตได้</div><div>7. ทางผู้จำหน่ายไม่รับผิดชอบต่อความเสียหายที่เกิดขึ้นจากความผิดพลาดของผู้ใช้ จากการเผยแพร่ บัตรปลอม การสูญหาย หรือการซื้อบัตรจากช่องทางอื่นๆ</div>"
    return (
        <>
            {dataEvent !== undefined && dataEvent !== null && (
                <Modal show={showModal} onHide={onCloseModal} style={{ cursor: 'default' }} className='table-package-modal'>
                    <Modal.Header>
                        {/* <Modal.Title className='mb-0'>STANDARD BED - A101</Modal.Title> */}
                        <ReactSVG src={closeButton} className="col-md svg-icon text-end w-100" onClick={onCloseModal} style={{ cursor: 'pointer' }} />
                    </Modal.Header>
                    <Modal.Body>
                        <LazyLoadImage
                            className='package-image mb-1'
                            alt={'package-image'}
                            src={isPicString ? pictureList : getImage(pictureList)}
                            width={'100%'}
                            height={'50%'}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = NotFound;
                            }}
                        />
                        <div className='package-description p-4'>
                            <h4 className='mb-3 text-79' style={{ fontWeight: '700' }}>{name}</h4>
                            <p className='weight-600'>{description}</p>
                            <div className="mb-4">
                                <p className="d-flex justify-content-start font-14 mb-2 weight-600 text-decoration-underline">
                                    {t('terms_conditions')}{' '}
                                </p>
                                <div className="text-left mb-3" style={{ height: isTabletOrMobile ? '170px' : 'fit-content', cursor: 'default', overflow: 'auto', position: 'relative' }}>
                                    <Markup content={termAndCondition} />
                                    {/* <Markup content={tertms_markup} /> */}
                                </div>

                                {packageInfo && (
                                    <>
                                        {/* Item Collapse*/}
                                        <p
                                            className="d-flex justify-content-start font-14 mb-1 weight-700 text-decoration-underline"
                                            data-bs-toggle="collapse"
                                            href={'#includes' + packageInfo.PackageCode + 'modal'}
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="includes1"
                                        >
                                            {/* <img src={TermIcon} className="TermIcon" /> */}
                                            {t('overview')}
                                            <span><img src={CaretDown} /></span>
                                        </p>
                                        <div className="collapse" id={'includes' + packageInfo.PackageCode + 'modal'}>
                                            {packageInfo.PackageItemLists.map((e, i) => {
                                                return (
                                                    <p className='ps-3'>
                                                        <Markup key={i} content={e.ItemType == 'Ticket' ? '● ' + e.Qty + ' ' + e.ItemName : '+ ' + e.Qty + ' ' + e.ItemName} />
                                                    </p>
                                                );
                                            })}

                                        </div>
                                        {/* Item Collapse*/}
                                    </>
                                )}
                                {isShowButton ? (
                                    <div className={isTabletOrMobile ? 'row align-items-center p-3 shadow p-2 mb-5 bg-body rounded' : 'row align-items-center mt-5'}>
                                        <div className="col-6">
                                            <h4 className='text-79' style={{ fontWeight: '700' }}>{t('thb')} {formatToCurrency(price)}</h4>
                                        </div>
                                        {isTabletOrMobile ? (<div className="col-6">
                                            <SorasoButton
                                                text={t('buy_now')}
                                                onClick={onConfirm}
                                                style={'primary'}
                                                disabled={checkEventDisable(dataEvent)}
                                            />
                                        </div>) : (<div className="col">
                                            <div className='row justify-content-end align-items-center'>
                                                <div className="col-6 ">
                                                    <SorasoButton
                                                        text={t('buy_now')}
                                                        onClick={onConfirm}
                                                        style={'primary'}
                                                        disabled={checkEventDisable(dataEvent)}
                                                    />
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>

                                ) : (
                                    <>
                                        <h4 className='mt-5 text-79' style={{ fontWeight: '600' }}>{t('thb')} {formatToCurrency(price)}</h4>
                                        {discountPrice && (
                                            <>
                                                <p className="oldPrice weight-400 mb-1 ps-5 text-strike">{oldPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                                <div className="mb-1" style={{ minHeight: 40, justifyContent: 'start', display: 'flex' }}>

                                                    <div className="ps-2 weight-500 color-black" style={{ fontSize: 18, textAlign: 'start' }}>{t('save_price') + ' ' + discountPrice.toLocaleString()} </div>

                                                </div>
                                            </>
                                        )}
                                    </>)}
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
};

export default PackageSelectModal
