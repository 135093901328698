import React from 'react';
import { ReactComponent as CaretDown } from '../img/chevron-down-79.svg'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function CustomDropdown(props) {
  const { options, selectedValue, setSelectedValue, errorText, mobile } = props
  const { t } = useTranslation();


  const handleItemClick = (option) => {
    console.log("OPTION", option)
    setSelectedValue(option);
  };

  const dropDownitemClass = (value, index) => {
    var c = "dropdown-item";
    if (value === selectedValue) {
      c += " gradient-background";
    }
    return c
  }

  return (

    <div className="dropdown">
      <button className="btn dropdown-toggle"
        style={{
          display: 'flex',
          alignItems: 'center',
          boxShadow: 'none',
          minWidth: mobile ?? '200px',
          maxWidth: mobile ?? '250px',
          minHeight: '35px',
          height: mobile ? '38px' : '45px',
          wordBreak: 'break-word'
        }}
        type="button"
        id={"dropdownMenuButton1"}
        data-bs-toggle={mobile ? null : "dropdown"}
        aria-expanded="false"
        disabled={(options == null || options.length === 0) ? true : false}
      >
        <p className='m-auto text-capitalize dropdown-text'
          style={{ width: 'inherit', textOverflow: 'ellipsis', overflow: 'hidden', textTransform: 'capitalize', color: '#000', lineHeight: '2' }}>
          {selectedValue === undefined ? (t('zone_all')) : (selectedValue || errorText)}
        </p>
        {/* <i class="fa fa-angle-down" style={{ fontSize: '20px', marginLeft: '5px', color: '#000' }}></i> */}
        <CaretDown />
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        {/* <li className='gradient-text' style={{ cursor: 'default', padding: '.5rem 1rem', fontSize: '14px', fontFamily: 'Montserrat', fontWeight: '600' }}>{t('select_zone')}</li> */}
        {options.map((option, index) => (
          <li key={option.id}
            // style={{ borderTop: '1px solid #F1F1F1' }}
          >
            <div className={dropDownitemClass(option.value, index)} onClick={() => handleItemClick(option)}>
              {option.value}
            </div>
          </li>
        ))}
      </ul>
    </div >
  );
}

export default CustomDropdown;
