import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Markup } from 'interweave';
import { gapi, loadAuth2 } from 'gapi-script';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import 'react-responsive-modal/styles.css';

//Components
import GGLogin from '../component/GGLogin';
import FBLogin from '../component/FBLogin';

//Image
import logo from '../component/img/logo_soraso.png';
import { ReactComponent as Close } from '../component/img/close.svg';
import { ReactComponent as Vector } from '../component/img/vector.svg';

//css
import '../component/css/style.css';
import '../component/css/custom.css';

import useLogin from '../customHook/useLogin';
import showTerm from '../customHook/showTerm';
import { baseLink, clientId } from '../constants/DataPublic';
import { useGlobalState } from '../global'
import SorasoButton from '../component/buttons/SorasoButton';
import { useMediaQuery } from 'react-responsive';


const Signin = (props) => {
  const navigate = useNavigate();
  const { language } = props;

  const [routePath, setRoutePath] = useGlobalState('route');

  const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

  let user = sessionStorage.getItem('UserLogin');
  user = JSON.parse(user);

  useEffect(() => {
    if (user != null && user.isLogin) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    let checkRoute = ['/signin', '/pass', '/forget', '/changepw', '/signup', '/setpw', '/ticket', '/guestCheckout', '/done', '/visitorInfo', '/payment']
    if (checkRoute.includes(routePath)) {
      console.log("Default signin")
    } else {
      console.log("Go!! signin")
    }
  }, [])

  // Login with Email
  let { message, requireEmail, setEmail, setPassword, submitLogin } = useLogin({ lang: language });

  // Show Terms & Conditions
  let { open, subject, terms, agree, setAgree, onOpenModal, onCloseModal, getTerms, onConfirm } = showTerm();

  const [ggUser, setGGUser] = useState({});
  const [fbUser, setFBUser] = useState({});
  const [needRegist, setNeedRegist] = useState(false);

  const getGGUser = (ggUser) => {
    setGGUser(ggUser);
    props.getGGUser(ggUser);
  };

  const getFBUser = (fbUser) => {
    setFBUser(fbUser);
    props.getFBUser(fbUser);
  };

  const getNeedRegist = (needRegist) => {
    setNeedRegist(needRegist);
  };

  useEffect(() => {
    if (needRegist) {
      getTerms(language);
      setNeedRegist(false);
    }
  }, [needRegist]);

  // Login with Google
  useEffect(async () => {
    await loadAuth2(gapi, clientId, "");
    // function start() {
    //   gapi.client.init({
    //     clientId: clientId,
    //     scope: '',
    //   });
    // }
    // gapi.load('client:auth2', start);
  }, []);

  //keydown event
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      passwordInputRef.current.focus();
    }
  };

  const handleKeyDownLogin = (e) => {
    if (e.keyCode === 13) {
      submitLogin(e);
    }
  };

  const setUserCode = (userCode) => {
    props.setUserCode(userCode);
  };

  // ภาษา
  const { t } = useTranslation();

  return (
    <div>
      <div className="page page-steps" style={{ minHeight: '100vh' }}>
        <div className="section py-5 ">
          <div className="container">
            <div className="row signin-container">
              {/* <div className="logosignin">
                <img src={logo} id="logosignin" />
              </div> */}
              <div className="col-xl-5 col-lg-6 col-md-7 col-sm-6 box-body box-border box-signin signupradius" style={{ margin: '0 auto', borderRadius: '10px', width: isMobile ? "340px" : "" }}>
                <div className="mb-3">
                  <h2 className="main-title mb-3">{t('sign_in_or_create_an_account')}</h2>
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    {t('email')}
                  </label>
                  <input
                    type="email"
                    pattern="/^([w-]+.?){0,2}[w-]+@([w-]+.?){0,2}[w-]+$/"
                    className="form-control mb-2"
                    id="exampleFormControlInput1"
                    placeholder={t('email_here')}
                    style={{ borderRadius: "10px" }}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                    ref={emailInputRef}
                    required
                  />
                  <span className="text-danger">{t(requireEmail)}</span>
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    {t('password')}
                  </label>
                  <input
                    type="password"
                    className="form-control mb-2"
                    id="password"
                    placeholder={t('password')}
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=\x22*_~=`[]:;'<>?,.\u007C\u0028\u0029\u002D\u002F\u005C]).{8,14}"
                    required
                    style={{ borderRadius: "10px" }}
                    onChange={(e) => setPassword(e.target.value)}
                    ref={passwordInputRef}
                    onKeyDown={handleKeyDownLogin}
                  />
                  <span className="text-danger">{t(message)}</span>
                </div>
                <div className="mb-3">
                  {/* <Button type="button" className="btn btn-link btn-lb text-capitalize " onClick={submitLogin}>
                    {t('login')}
                  </Button> */}
                  <SorasoButton
                    text={t('continue')}
                    style='primary'
                    onClick={submitLogin}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <p className="mb-0" style={{ width: '50%' }}>
                    <a href={baseLink + routePath + '/forget'} className="font-10 weight-600 text-79 text-fotgot-boarder" style={{ textDecoration: 'underline' }}>
                      {t('forgot_password')}
                    </a>
                  </p>
                  <p className="mb-0" style={{ width: '50%', textAlign: 'right' }} >
                    <a href='#' className="font-10 weight-600 text-79 text-fotgot-boarder" onClick={() => getTerms(language)} style={{ textDecoration: 'underline' }}>
                      {t('account_create')}
                    </a>
                  </p>
                  <Modal show={open} className="col-12 signinpage popupterm">
                    <div style={{ textAlign: 'right' }}>
                      <Close className="popup-close" onClick={onCloseModal} />
                    </div>
                    <div className="logosignin">
                      <img src={logo} id="logosignin" />
                    </div>

                    <h5 className="text-center mb-5">{subject}</h5>
                    <Markup content={" " + terms} />
                    <div className="text-center mb-5">
                      <input type="checkbox" className="form-check-input" id="vehicle1" name="vehicle1" onChange={(e) => setAgree(e.target.checked)} />
                      <label hntmlFor="vehicle1" className="form-check-label" style={{ padding: '.2rem 0 0 1rem' }}>
                        <u>{t('i_agree')}</u>
                      </label>
                    </div>
                    <div className="col-5" style={{ margin: '0 auto', paddingTop: '1em' }}>
                      {/* <Button type="button" className="btn btn-link btn-db text-capitalize" onClick={onConfirm} disabled={!agree}>
                        {t('confirm')}
                      </Button> */}
                      <SorasoButton
                        text={t('confirm')}
                        style='primary'
                        onClick={onConfirm}
                        disabled={!agree}
                      />
                    </div>
                  </Modal>
                </div>
                <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 row align-items-center">
                  <div className="relative">
                    <hr />
                    <span className="or bg-white">{t('or_use')}</span>
                  </div>
                </div>

                <div className="mb-3 row g-3 align-items-center">
                  <div className='col-6 d-flex justify-content-center'>
                    <div className="gustloginFB">
                      <FBLogin language={language} fbtext={t('facebook')} getFBUser={getFBUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode} />
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <div className="gustloginGG">
                      <GGLogin language={language} ggtext={t('google')}
                        getGGUser={getGGUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode} />{/*ggtext={t('google')}*/}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <Modal show={open} className="col-12 signinpage popupterm">
                    <div style={{ textAlign: 'right' }}>
                      <button type="button" class="btn-close" aria-label="Close" onClick={onCloseModal}></button>
                      {/* <Close className="popup-close" onClick={onCloseModal} /> */}
                    </div>
                    <div className="logosignin">
                      <img src={logo} id="logosignin" />
                    </div>

                    <h5 className="text-center mb-5">{subject}</h5>
                    <Markup content={" " + terms} />
                    <div className="text-center mb-5">
                      <input type="checkbox" className="form-check-input" id="vehicle1" name="vehicle1" onChange={(e) => setAgree(e.target.checked)} />
                      <label hntmlFor="vehicle1" className="form-check-label" style={{ padding: '.2rem 0 0 1rem' }}>
                        <u>{t('i_agree')}</u>
                      </label>
                    </div>
                    <div className="col-5" style={{ margin: '0 auto', paddingTop: '1em' }}>
                      {/* <Button type="button" className="btn btn-link btn-db text-capitalize " onClick={onConfirm} disabled={!agree}>
                        {t('confirm')}
                      </Button> */}
                      <SorasoButton
                        text={t('confirm')}
                        style='primary'
                        onClick={onConfirm}
                        disabled={!agree}
                      />
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="d-lg-block mt-5 col-xl-5 col-lg-6 col-md-7 col-sm-6 bg-79-sand" style={{ margin: '0 auto', width: isMobile ? "340px" : "" }}>
              {/* <div className="d-none d-lg-block mt-5 "> */}
              <hr />
              <h6 className="font-20 weight-800 mb-0">{t('need_help')}</h6>
              <p className="font-14 weight-600">
                {t('send_us')}
                {/* <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&online@columbiapicturesaquaverse.com.com&body=I would like learn more about check out . Could you please send me more details at the earliest?"> */}
                {/* <a href="mailto:online@columbiapicturesaquaverse.com&subject=test subject&body=Dear Sir/Madam %0A%0A I would like learn more about check out . Could you please send me more details at the earliest?" target="_blank"> */}
                <a className='text-color' href="mailto:seen.samui@seenbeachclubsamui.com?subject=Guest checkout inquiry letter&body=Dear Sir/Madam %0A%0A I would like learn more about check out . Could you please send me more details at the earliest? %0D%0A Thank you for your prompt response. %0A%0A Yours sincerely," target="_blank">
                  {/* <a href="mailto:online@columbiapicturesaquaverse.com"> */}
                  {t('email')}
                </a>
                &nbsp;{t('or_callus')}
                <a className='text-color' href="tel:+6677956800">+66(0)77956800</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
