import React, { useEffect, useState } from 'react'
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import noImg from '../img/no_image.png';
import { ReactComponent as Verified } from "../../component/img/check-green.svg";
import SorasoButton from '../buttons/SorasoButton';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TextStrike from '../input/TextStrike';
import { useMediaQuery } from 'react-responsive';
import CaretDown from '../img/caret_down_icon.svg';

export const SubItem = (props) => {

    const { itemSubList } = props
    const { currentStep } = props
    const { cartUpdate } = props

    let myCart = sessionStorage.getItem('MyCart')
    myCart = JSON.parse(myCart)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' })

    const [picturePath, setPicturePath] = useState(noImg)

    const [netPrice, setNetPrice] = useState(0)
    const [itemQty, setItemQty] = useState(1)
    const [selectedI, setSelectedI] = useState(false)
    const [cartActive, setCartActive] = useState('')
    const [editI, setEditI] = useState(false)

    useEffect(() => {
        // console.log("SubItem: ", itemSubList);
        if (itemSubList.PackagePictureLists.length > 0) {
            let picture = itemSubList.PackagePictureLists.filter(e => e.PictureSize.toLowerCase() === "small")[0]
            if (picture != null)
                setPicturePath(picture.PictureUrl)
            else
                setPicturePath(noImg)
        }

        if (itemSubList.IsDiscount) {
            setNetPrice(itemSubList.PackagePrice - itemSubList.DiscountPrice)
        } else {
            setNetPrice(itemSubList.PackagePrice)
        }

        let pIndex = -1;
        if (myCart !== null) {
            pIndex = myCart[0].Package.findIndex(e => {
                return (e.PackageCode === itemSubList.PackageCode)
            })
        }

        // const pIndex = myCart[0].Package.findIndex(e => {
        //     return (e.PackageCode === itemSubList.PackageCode)
        // })

        if (pIndex !== -1) {
            // ถ้ามี Package อยู่ใน Cart
            setItemQty(myCart[0].Package[pIndex].Qty)
            // เช็คว่า Package อยู่ใน Mode Edit หรือไม่
            if (myCart[0].Package[pIndex].status === "edit") {
                setSelectedI(false)
                setCartActive('')
                setEditI(true)
            } else {
                setSelectedI(true)
                setCartActive('active')
            }
        } else {
            // ถ้าไม่มี Package อยู่ใน Cart
            setSelectedI(false)
            setCartActive('')
            setEditI(false)
        }
    }, [itemSubList])

    useEffect(() => {
        if (cartUpdate.PackageCode === itemSubList.PackageCode) {
            setItemQty(cartUpdate.Qty)
        }
    }, [cartUpdate])

    const addToCart = (item, isEdit) => {
        props.onAddToCart(item, isEdit)
    }

    // ภาษา
    const { t } = useTranslation();

    return (
        <div className="col-lg-6 mb-4 d-flex" style={{ minHeight: '400px', height: 'fit-content' }}>
            <div className={`card-flip h-100 ${cartActive}`}>
                {/* <img src={picturePath} className="card-img-top" alt="Card Image" /> */}
                <LazyLoadImage
                    src={picturePath} className="card-img-top" alt="Card Image"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = noImg;
                    }}
                />

                <div className="card-body d-flex flex-column" style={{ minHeight: '120px'}}>
                    <h5 className="card-title mb-1 lh-1 text-79">{itemSubList.PackageName}</h5>
                    <p className="card-text mb-2 font-16  weight-500"><Markup content={itemSubList.PackageDescription} /></p>

                    {/* Item Collapse*/}
                    <p
                        className="d-flex justify-content-start font-14 mb-1 weight-700 text-decoration-underline"
                        data-bs-toggle="collapse"
                        href={'#includes' + itemSubList.PackageCode}
                        role="button"
                        aria-expanded="false"
                        aria-controls="includes1"
                    >
                        {/* <img src={TermIcon} className="TermIcon" /> */}
                        {t('overview')}
                        <span><img src={CaretDown} /></span>
                    </p>
                    <div className="collapse" id={'includes' + itemSubList.PackageCode}>
                        {itemSubList.PackageItemLists.map((e, i) => {
                            return (
                                <p className='ps-3'>
                                    <Markup key={i} content={e.ItemType == 'Ticket' ? '● ' + e.Qty + ' ' + e.ItemName : '+ ' + e.Qty + ' ' + e.ItemName} />
                                </p>
                            );
                        })}

                    </div>
                    {/* Item Collapse*/}
                </div>

                <div className="card-footer">
                    {/* {itemSubList.IsDiscount && (
                        <div className="mb-4">
                            <p className="font-24 weight-900 font-grey mb-0 lh-1">{t('thb')} {netPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<span className="font-14 weight-600 color-black">{itemSubList.PriceUnit}</span></p>
                        </div>
                    )} */}
                    {/* {!itemSubList.IsDiscount && (
                        <div className="mb-4">
                            <p className="weight-400">{netPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</p>
                        </div>
                    )} */}
                    <div className="mb-2">
                        <p className="font-24 weight-700 text-79 mb-2 lh-1">{netPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')} <span className='font-14 weight-600'>/ {t('serve')}</span> <span className="font-14 weight-600 color-black">{itemSubList.PriceUnit}</span></p>
                        {itemSubList.IsDiscount ? (
                            <>
                                <p className="oldPrice weight-400 mb-1 ps-5 text-strike">{itemSubList.PackagePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                {/* <TextStrike text={itemSubList.PackagePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} /> */}
                                <div className="mb-1" style={{ minHeight: 40, justifyContent: 'start', display: 'flex' }}>
                                    <div className="ps-2 weight-500 color-black" style={{ fontSize: 18, textAlign: 'start' }}>{t('save_price') + ' ' + itemSubList.DiscountPrice.toLocaleString()} {t('thb')}</div>

                                </div>
                            </>
                        ) :
                            <>
                                {!isTabletOrMobile && (
                                    <div>
                                        <p className="oldPrice weight-400 mb-1 ps-5 text-strike" style={{ minHeight: 20, justifyContent: 'start', display: 'flex' }}></p>
                                        <div className="mb-1" style={{ minHeight: 40, justifyContent: 'start', display: 'flex' }}>
                                            <div className="ps-2 weight-500 color-black" style={{ fontSize: 18, textAlign: 'start' }}></div>

                                        </div>
                                    </div>
                                )}
                            </>
                        }
                    </div>
                    {!selectedI ?
                        <div className='row'>
                            <div className='col-md-6'>
                                {!selectedI && (
                                    <div className="input-group mx-auto my-3">

                                        <span className="input-group-btn">
                                            <button type="button" className="btn  btn-number minus" data-type="minus" disabled={selectedI} data-field="quant[1]" onClick={e => {
                                                if (itemQty > 1)
                                                    setItemQty(itemQty => itemQty - 1)
                                                else setItemQty(1)
                                            }}>
                                                <span className="material-icons">remove</span>
                                            </button>
                                        </span>

                                        {/* <input type="text" name="quant[1]" className="form-control input-number" style={{ marginLeft: "-3px" }} value={itemQty} defaultValue={1} disabled /> */}
                                        <div className='mx-auto weight-700 font-black'>{itemQty ?? 1}</div>

                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-number" data-type="plus" disabled={selectedI} data-field="quant[1]" onClick={e => { setItemQty(itemQty => itemQty + 1) }}>
                                                <span className="material-icons">add</span>
                                            </button>
                                        </span>


                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 d-flex align-items-center'>
                                {!selectedI && (
                                    <div className="text-center w-100">
                                        {/* <SorasoButton className="btn btn-blue btn-not-full text-white mt-auto mx-auto align-self-start text-capitalize" */}
                                        <SorasoButton
                                            text={t('addtocart')}
                                            onClick={() => {
                                                addToCart(
                                                    {
                                                        PackageCode: itemSubList.PackageCode,
                                                        PackageName: itemSubList.PackageName,
                                                        ItemCode: "",
                                                        ItemName: "",
                                                        Qty: itemQty,
                                                        Tax: 0,
                                                        ItemPrice: netPrice,
                                                        ItemDiscount: itemSubList.IsDiscount ? itemSubList.DiscountPrice : 0,
                                                        PriceName: itemSubList.PriceName,
                                                        PriceUnit: '',
                                                        TotalPrice: itemQty * netPrice,
                                                        VisitDate: null,
                                                        VisitTime: '',
                                                        VisitLocation: '',
                                                        step: currentStep,
                                                        status: 'normal',
                                                        isMain: false
                                                    }, editI
                                                )
                                                if (itemQty > 0) {
                                                    setSelectedI(true)
                                                    setCartActive('active')
                                                }
                                            }}
                                            style={'primary'}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        : (<div className='d-flex-row justify-content-center'>
                            <p className='text-center font-16 weight-800' style={{ color: '#000' }}>{itemQty}</p>
                            {selectedI && (
                                <div className="text-center">
                                    <Verified className="img-" />
                                </div>
                            )}
                        </div>)
                    }
                </div>
            </div>

        </div>
    )
}
