import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import closeButton from '../img/button_close.svg';
import SorasoButton from '../buttons/SorasoButton';

export default function AlertModal(props) {
    const { header, openModal, onCloseModal, onConfirm, onCancle, children, isShowCancle, isShowClose } = props;
    const { t } = useTranslation();
    return (
        <div>
            <Modal show={openModal} onHide={onCloseModal} style={{ cursor: 'default' }} className='alert-modal' backdrop='static' keyboard={false}>
                <ModalHeader className='w-100 d-flex justify-content-end'>
                    <ReactSVG src={closeButton} className="col-md svg-icon text-end" onClick={onCloseModal} style={{ cursor: 'pointer', display: isShowClose ?? true ? '' : 'none' }} />
                </ModalHeader>
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <div className='row' style={{ width: '100%', rowGap: '10px' }}>
                        <div className='col'>
                            <SorasoButton text={t('confirm')} onClick={onConfirm} style='primary' />
                        </div>
                        {isShowCancle && (
                            <div className='col'>
                                <SorasoButton text={t('cancel')} onClick={onCloseModal} style='outline' />
                            </div>
                        )}
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    )
}
