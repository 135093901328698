
import { AllPayment, CheckPaymentStatus } from '../constants/BaseApi';
import { makeApiRequest } from './BaseApiService';

export async function allPayment(model, language) {
    return makeApiRequest(AllPayment, language, 'post', model);
}

export async function checkPaymentStatus(model, language) {
    return makeApiRequest(CheckPaymentStatus, language, 'post', model);
}

