import React from 'react'
import CustomDatePicker from '../component/Tableplan/CustomDatePicker';
import CustomDropdown from '../component/Tableplan/ZoneDropDown';
import EventSelectDropDown from '../component/Tableplan/EventSelectDropDown';
import ScanPromoButton from '../component/buttons/ScanPromoButton';
import SorasoRefresh from '../component/img/soraso_refresh_79.svg';
import { useTranslation } from 'react-i18next'
import AlertModal from './modal/AlertModal';
import { useState, useRef } from 'react';
import {
    convertDateToISO,
    delay,
    getCartSession,
    getVoucherSession,
    getDateSession,
    setDateSession,
    getEventSession,
    setEventSession,
    resetBookingSesstion,
    removeSession
} from './Helpers/BaseHelper';
import { useGlobalState } from '../global';
import { BlockTableModel } from '../Models/TableBookModel';
import { UnBlocktable } from '../Services/TableBookService';
import { useNavigate } from 'react-router-dom';
import BottomPopup from './modal/BottomPopup';
import EventSelectModal from './modal/EventSelectModal';

const SelectZoneBar = (props) => {
    const { isEventPage, isTablePlanPage } = props;

    const { t } = useTranslation();
    const customDateRef = useRef();
    const voucherSession = getVoucherSession()
    const myCart = getCartSession();
    const dateSession = getDateSession();
    const eventAndZone = getEventSession();
    const navigate = useNavigate();

    const [showAlert, setShowAlert] = useState(false);
    const [alertCallback, setAlertCallback] = useState({});
    const [openEventModal, setOpenEventModal] = useState(false);
    const [openBottomPopup, setOpenBottomPopup] = useState({
        zone: false,
        event: false,
        date: false,
    });
    const [languageGlobal] = useGlobalState('language');

    // console.log("props.selectedValueZone", props.selectedValueZone)

    const openConfirmationModal = () => {
        return new Promise((resolve) => {
            setShowAlert(true);

            const handleConfirm = () => {
                resolve(true); // User confirmed the change
            };

            const handleCancel = () => {
                resolve(false); // User canceled the change
            };

            // Set the ConfirmationModal callbacks
            setAlertCallback({ confirm: handleConfirm, cancel: handleCancel });
        });
    };

    const handleOnConfirmModal = (date = dateSession, deleteBlock) => {
        openConfirmationModal()
            .then(async (confirmed) => {
                if (confirmed) {
                    if (deleteBlock) {
                        await CallUnblockAllTable(date)
                    }

                    resetBookingSesstion();
                    removeSession('MyCart');
                    removeSession('Voucher');
                    // console.log("dateSession", dateSession);
                    // console.log("date", date);
                    const dateNew = date ? convertDateToISO(date) : dateSession;
                    setDateSession(dateNew);
                    setShowAlert(false)
                    window.location.href = '/';
                    // onRefreshPlan()
                } else {
                    // User canceled, do nothing
                    setShowAlert(false)
                }
            })
            .catch((error) => {
                // Handle errors or user cancelation
                setShowAlert(false)
                console.error(error);
            });
    }


    const handleOnDateChange = (date) => {
        // Clear State
        removeSession('EventState');
        removeSession('CurrentEvent');

        let dateFromPicker = new Date(date);
        let dateFromSession = new Date(dateSession);
        if (myCart && myCart.length > 0 && convertDateToISO(dateFromPicker) !== convertDateToISO(dateFromSession)) {
            handleOnConfirmModal(date, true)
        } else {
            if (voucherSession && convertDateToISO(date) !== voucherSession?.EventDate.split('T')[0]) {
                if (myCart && myCart.length > 0) {
                    handleOnConfirmModal(date, true)
                } else {
                    handleOnConfirmModal(date, false)
                }

            } else {
                if (convertDateToISO(dateFromPicker) !== convertDateToISO(dateFromSession) && !isEventPage) {
                    window.location.href = '/';
                }
                //เรียกใช้ function ใน CustomDatePicker (child component)
                customDateRef?.current?.setDate(date)
                setOpenBottomPopup({ date: false })
                setOpenBottomPopup({ zone: false })
                setOpenBottomPopup({ event: false })
            }
        }
    };

    const handleZoneChange = (data) => {
        const zoneId = data.id;
        // console.log("handleZoneChange", zoneId)
        // console.log("handleZoneChange data", data)
        if (myCart != null && myCart.length > 0 && eventAndZone.selectZone !== zoneId) {
            // console.log('handleZoneChange if');
            openConfirmationModal()
                .then(async (confirmed) => {
                    if (confirmed) {
                        await CallUnblockAllTable();
                        await clearSessions()
                        // console.log('handleZoneChange comfirm');
                        if (zoneId !== null && zoneId !== undefined && zoneId !== "") {
                            sessionStorage.setItem('CurrentEvent', JSON.stringify({ selectZone: zoneId, selectEvent: props.selectedValueEvent }))
                            navigate('/table', { state: { selectZone: zoneId, selectEvent: props.selectedValueEvent } });
                        }
                        if (window.location.pathname.split('/')[1].toLocaleLowerCase() === 'table') {
                            navigate('/', { replace: true });
                            sessionStorage.setItem('CurrentEvent', JSON.stringify({ selectZone: zoneId, selectEvent: props.selectedValueEvent }))
                            navigate('/table', { state: { selectZone: zoneId, selectEvent: props.selectedValueEvent } });
                        }
                        setShowAlert(false);
                        // window.location.href = '/';
                        // window.location.reload();

                    } else {
                        // User canceled, do nothing
                        setShowAlert(false)
                    }
                })
                .catch((error) => {
                    // Handle errors or user cancelation
                    console.error(error);
                });
        } else {
            // console.log('handleZoneChange else');
            if (voucherSession && myCart && myCart.length > 0) {
                handleOnConfirmModal(null, false)
            } else {
                props.setSelectedZone(data);
                if (zoneId !== null && zoneId !== undefined && zoneId !== "") {
                    sessionStorage.setItem('CurrentEvent', JSON.stringify({ selectZone: zoneId, selectEvent: props.selectedValueEvent }))
                    navigate('/table', { state: { selectZone: zoneId, selectEvent: props.selectedValueEvent } });
                }
                setOpenBottomPopup({ date: false })
                setOpenBottomPopup({ zone: false })
                setOpenBottomPopup({ event: false })

                // if (!isTablePlanPage) {
                //     window.location.href = '/';
                // }
            }

        }
    }

    async function CallUnblockAllTable(date) {
        setShowAlert(false)
        if (myCart && myCart?.length < 1) return;
        const payload = new BlockTableModel(
            myCart?.map((e) => {
                return { TableNo: e.TableNo }
            })
        )
        await delay(1000);
        try {
            await UnBlocktable(payload, languageGlobal ?? 'EN')
                .then((response) => {
                    if (response.Success) {
                        const data = response.Result;
                        resetBookingSesstion();
                        removeSession('MyCart');
                        removeSession('Voucher');
                        if (date) setDateSession(convertDateToISO(date));
                        // console.log(data);
                        delay(1500);
                        // window.location.reload();
                    } else {
                        throw (response.Result.Message);
                    }
                }).catch((error) => {
                    console.log(error);
                    throw error;
                })
        } catch (error) {
            console.log(error);
            return null;
        }
    }


    const onEventSelected = async (event) => {
        // console.log("onEventSelected test", event);
        let currentEvent = JSON.parse(sessionStorage.getItem("CurrentEvent"));
        // console.log(currentEvent);
        if (currentEvent !== null && (currentEvent.selectEvent.id !== event.id) && (myCart !== null && myCart.length > 0)) {
            // console.log("onEventSelected", event.id !== activeEvent.id)
            setOpenBottomPopup({ event: false })
            setOpenEventModal(false)
            openConfirmationModal()
                .then(async (confirmed) => {
                    if (confirmed) {
                        // User confirmed the change
                        // console.log("onEventSelected", confirmed, event.id);
                        await CallUnblockAllTable();
                        await clearSessions();
                        props.setActiveEvent(event);
                        // await delay(1000);
                        window.location.href = '/';
                        props.onRefreshPlan(convertDateToISO(props.startDate), event.id)
                        setShowAlert(false);
                    } else {
                        // User canceled, do nothing
                        setShowAlert(false)
                    }
                })
                .catch((error) => {
                    // Handle errors or user cancelation
                    console.error(error);
                });
        } else {
            // sessionStorage.removeItem('CurrentEvent');

            if (voucherSession) {
                removeSession('Voucher');
            }

            if (eventAndZone) {
                eventAndZone.selectEvent = event
                setEventSession(eventAndZone);
            } else {
                let isEvent = { selectEvent: null }
                isEvent.selectEvent = event
                setEventSession(isEvent);
            }

            props.setActiveEvent(event);
            setOpenEventModal(false)
            setOpenBottomPopup({ event: false })
            // window.location.href = '/';
            props.onRefreshPlan(convertDateToISO(props.startDate), event.id);
        }
    }

    const clearSessions = async () => {
        sessionStorage.removeItem('MyCart');
        sessionStorage.removeItem('CurrentEvent');
        props.setActiveEvent({ id: 0, value: "" });
        props.getCartItem(0);
    };

    // console.log("props.selectedValueEvent", props.selectedValueEvent);


    return (
        <>
            <div className='align-items-center justify-content-center secondary-background'
                style={{
                    width: props.isMobile ? 'unset' : '100%',
                    height: 'fit-content',
                    padding: '1.2rem',
                    zIndex: 1,
                    rowGap: '10px'
                }}>
                <div className='d-flex align-items-center justify-content-center' style={{ height: '50%', gap: '7px' }}>
                    <div className={`${props.isEventPage ? 'col-6 justify-content-center col-md-6' : 'col-4 col-md-auto'} d-flex align-items-center justify-content-xl-center`} style={{ height: '50%' }}>
                        <div className='card-menu border-drop-shadow'>
                            <CustomDatePicker
                                isEventPage={props.isEventPage}
                                mobile={props.isMobile}
                                eventDateArray={props.eventDateArray}
                                startDate={props.startDate}
                                setStartDate={props.setStartDate}
                                setOpenBottomPopupDate={() => setOpenBottomPopup({ date: true })}
                                onDateChange={handleOnDateChange}
                                ref={customDateRef}
                            />
                        </div>
                    </div>
                    {!props.isEventPage && (
                        <>
                            <div className='col-4 col-md-auto d-flex align-items-center' style={{ height: '50%' }}>
                                <div className='card-menu border-drop-shadow'
                                    style={{ width: 'fit-content' }}
                                    onClick={() => setOpenBottomPopup({ zone: true })}
                                >
                                    <CustomDropdown
                                        mobile={props.zoneMobileSize}
                                        options={props.optionsZone}
                                        selectedValue={props.selectedValueZone?.value}
                                        setSelectedValue={handleZoneChange}
                                        errorText={props.errorTextZone}
                                    />
                                </div>
                            </div>
                            <div className='col-4 col-md-auto d-flex align-items-center'>
                                <div className='card-menu border-drop-shadow'
                                    onClick={() => setOpenBottomPopup({ event: true })}>
                                    <EventSelectDropDown
                                        mobile={props.eventMobile}
                                        onClick={() => setOpenEventModal(true)}
                                        selectedValue={props.selectedValueEvent}
                                        options={props.options}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {/* {props.isEventPage && (
                        <>
                            <div className='col-auto col-md-6 d-flex align-items-center justify-content-center justify-content-xl-start'>
                                <ScanPromoButton
                                    isEventPage={props.isEventPage}
                                    align={'start'}
                                    onRefreshPlan={props.onRefreshPlan}
                                    mobileH={props.scanMobileH}
                                    language={props.language}
                                />
                            </div>
                        </>
                    )}

                    {!props.isMobile && !props.isEventPage && (
                        <>
                            <div className='col-4 col-md-auto d-flex align-items-center'>
                                <ScanPromoButton
                                    isEventPage={props.isEventPage}
                                    onRefreshPlan={props.onRefreshPlan}
                                    mobileH={props.scanMobileH}
                                    language={props.language}
                                    align={'center'}
                                />
                            </div>
                        </>
                    )} */}
                </div>
                {/* {props.isMobile && !props.isEventPage && (
                    <div className='d-flex align-items-center justify-content-center' style={{ height: '50%', gap: '1%', marginTop: '15px', marginRight: '10px' }}>
                        <div className='col-md-auto d-flex align-items-center'>
                            <ScanPromoButton
                                isEventPage={props.isEventPage}
                                align={'end'}
                                onRefreshPlan={props.onRefreshPlan}
                                mobileH={props.scanMobileH}
                                language={props.language}
                            />
                        </div>
                    </div>
                )} */}
            </div>

            {props.isMobile && (
                <>
                    {/* popup สำหรับ mobile -----------------------------*/}
                    <BottomPopup
                        type={'date'}
                        isActive={openBottomPopup.date}
                        mobile={true}
                        eventDateArray={props.eventDateArray}
                        startDate={props.startDate}
                        setStartDate={props.setStartDate}
                        onValueChange={handleOnDateChange}
                        onClose={(e) => setOpenBottomPopup({ date: false })}
                    />
                    {!props.isEventPage && (
                        <>
                            <BottomPopup
                                type={'radio'}
                                isActive={openBottomPopup.zone}
                                selectValue={props.selectedValueZone?.id}
                                onValueChange={handleZoneChange}
                                onClose={(e) => setOpenBottomPopup({ zone: false })}
                                title={t('select_zone')}
                                dataList={props.optionsZone}
                            />
                            <BottomPopup
                                type={'event'}
                                selectDate={props.startDate.toLocaleDateString("en-US", props.options)}
                                selectValue={props.selectedValueEvent}
                                isActive={openBottomPopup.event}
                                onValueChange={onEventSelected}
                                onClose={(e) => setOpenBottomPopup({ event: false })}
                                title={t('event') + ' : ' + props.startDate.toLocaleDateString(languageGlobal, props.options)}
                                dataList={props.eventItems}
                            />
                        </>
                    )}
                </>
            )}

            {/* modal เลือก Event */}
            {props.eventItems && props.startDate && (
                <EventSelectModal
                    showModal={props.isMobile ? false : openEventModal}
                    onCloseModal={() => setOpenEventModal(false)}
                    selectDate={props.startDate.toLocaleDateString("en-US", props.options)}
                    event={props.eventItems}
                    onClick={onEventSelected}
                    selectEvent={props.selectedValueEvent}
                />
            )}


            {/* เช็คเมื่อมี package ใน cart แล้วจะเปลี่ยนวันที่ --- มี voucher ด้วย */}
            <AlertModal
                openModal={showAlert}
                onCloseModal={alertCallback.cancel}
                onConfirm={alertCallback.confirm}
                isShowCancle
                >
                <div className={`d-flex flex-column ${props.isMobile ? '' : 'justify-content-around'}  align-items-center`} style={{ height: '100%' }}>
                    <div className='mb-3'>
                        <img src={SorasoRefresh} />
                    </div>
                    <div style={{ fontWeight: 'bold', paddingTop: props.isMobile && '15px' }}>
                        {t('remove_alert_1')}
                    </div>
                    <div style={{ fontWeight: 'bold', paddingTop: props.isMobile && '15px' }}>
                        {t('remove_alert_2')}
                    </div>
                    {voucherSession && (
                        <div style={{ fontWeight: 'bold', paddingTop: props.isMobile && '15px' }}>
                            {t('remove_voucher_warning')}
                        </div>
                    )}
                </div>
            </AlertModal>
        </>
    )
}

export default SelectZoneBar