import CryptoJS from "crypto-js";

// Encrypt

export const hashStringWithSHA256 = (input) => {
    const hashed = CryptoJS.SHA256(input).toString();
    return hashed;
};

/* --------------------- How To Use ---------------------
  const handleHash = () => {
    const hashed = hashStringWithSHA256(text);
    setHashedText(hashed);
  };
 --------------------- How To Use --------------------- */
