import React, { createContext, useContext, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

export function ToastProvider({ children }) {
    const showToast = (message, type = null) => {
        // Use react-toastify to show the toast
        if (type !== null) {
            toast[type](message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
            toast(message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            <ToastContainer
                hideProgressBar={true}
                rtl={false}
                // theme="light"
                limit={1}
            />
        </ToastContext.Provider>
    );
}

export function useToast() {
    return useContext(ToastContext);
}
