import { ApplyVoucher, CartCheckOutOrder, CheckOutOrder, GetActivePayment, GetFBAddOn, GetPackageAddOn, GetPackageTicket, SaveOrder, VerifyPromotionCode } from '../constants/BaseApi';
import { makeApiRequest } from './BaseApiService';

export async function getPackage(language) {
    return makeApiRequest(GetPackageAddOn, language);
}

export async function checkPackagePromotion(language, promoCode) {
    return makeApiRequest(VerifyPromotionCode + promoCode, language);
}

export async function getFB(language) {
    return makeApiRequest(GetFBAddOn, language);
}

export async function checkoutOrderService(userCode, params, language) {
    const url = `${CheckOutOrder}${userCode}`;
    return makeApiRequest(url, language, 'get', null, { params });
}

export async function saveOrder(model, language, voucherData) {
    const url = voucherData ? ApplyVoucher : SaveOrder;
    return makeApiRequest(url, language, 'post', model);
}

export async function getPackageTicket(language) {
    return makeApiRequest(GetPackageTicket, language);
}

export async function getPackageActivePayment(language) {
    return makeApiRequest(GetActivePayment, language);
}

export async function getPackageCart(userCode, language) {
    return makeApiRequest(CartCheckOutOrder + userCode, language);
}

