import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import NotFound from '../img/notfound_new.png';
import { checkEventDisable, getEventStart } from '../Helpers/EventHelper.jsx'
import { formatToCurrency } from '../Helpers/BaseHelper';
import SorasoButton from '../buttons/SorasoButton';
import PackageSelectModal from '../modal/PackageSelectModal.jsx';
import { t } from 'i18next';

export default function EventCard(props) {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const { event, onEventClick } = props;
    const [openModal, setOpenModal] = useState(false);

    let dateTime = getEventStart(event);

    return (
        <div className={isTabletOrMobile ? 'event-card card' : 'event-card'}>
            <div className={isTabletOrMobile ? 'event-image mb' : 'event-image'}>
                <img src={event.EventPicture} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = NotFound;
                }} className='mb-1' />
            </div>
            <div className='event-detail' style={{ padding: isTabletOrMobile ? '1.5em' : '20px' }}>
                <p className='mb-1 font-grey' style={{ fontSize: isTabletOrMobile ? '18px' : '20px', fontWeight: 'bold', }}>{event.CampaignName}</p>
                <p className='d-flex justify-content-start align-items-center' style={{ fontSize: '14px', wordWrap: 'break-word' }}>
                    {event.EventTime + ': ' + dateTime}
                </p>
                <p style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer', paddingBottom: '5px' }} onClick={() => setOpenModal(true)}>
                    {t('terms_conditions')}{' '}
                </p>
                <div className='row justify-content-center align-items-center'>
                    <div className="col " style={{ width: isTabletOrMobile ? '125px' : '' }}>
                        <b className='font-grey' style={{ fontSize: isTabletOrMobile ? '16px' : '20px', fontWeight: 'bold' }}>{t('thb')} {formatToCurrency(event.EventPrice)}</b>
                    </div>
                    <div className="col-6">
                        <SorasoButton
                            text={t('buy_now')}
                            onClick={() => onEventClick(event)}
                            style={'primary'}
                            disabled={checkEventDisable(event)}
                        />
                    </div>
                </div>
            </div>
            <PackageSelectModal
                showModal={openModal}
                onCloseModal={() => setOpenModal(false)}
                dataEvent={event}
                pictureList={event.EventPicture}
                name={event.CampaignName}
                description={event.EventTime + ': ' + dateTime}
                termAndCondition={event.EventDescription}
                price={event.EventPrice}
                isShowButton={true}
                onConfirm={() => onEventClick(event)}
            />
        </div>
    )
}
