import React, {useEffect, useState} from 'react'
import { cabanaPackageGroupTypeCode, transportPackageGroupTypeCode } from '../../constants/DataPublic'
import { SubItem } from './SubItem'
import { SubItemFlip } from './SubItemFlip'

export const SubItemGroup = (props) => {

    const {packageCode} = props
    const {itemSubGroup} = props
    const {itemList} = props
    const {currentStep} = props
    const {cartUpdate} = props

    const [itemSubList, setItemSubList] = useState([])
    const [flip, setFlip] = useState(false)

    useEffect(() => {
        let sList = itemList.filter(e => e.PackageSubTypeCode === itemSubGroup)
        console.log("SList, ", itemSubGroup, sList)
        setItemSubList(sList) //เปลี่ยนมา set itemList ให้ก่อน ตอนแรกมันเป็น sList

        // sList.PackageItemLists.forEach((e) => {
        //     if(e.IsVisitDate == true){
        //         setFlip(true)
        //     }
        // })
    }, [itemList])

    const onAddToCart = (itemCart, isEdit) =>{
        props.onAddToCart(itemCart, isEdit)
    }
   
    return (
        <>
            <div className="box box-main mb-0 mt-3">
                <div className="row gy-3">
                    {
                        itemSubList.map((e,index) => {
                            let flipp = false
                            // if(e.PackageItemLists.map(e => {
                            //     if(e.IsVisitDate == true){
                            //         flipp = true
                            //     } 
                            // }))
                            // if (e.PackageGroupTypeCode === cabanaPackageGroupTypeCode || e.PackageGroupTypeCode === transportPackageGroupTypeCode){
                            if (flipp){
                                return <SubItemFlip key={index} index={index} itemSubList={e} currentStep={currentStep} packageCode={packageCode} cartUpdate={cartUpdate} onAddToCart={onAddToCart}/>
                            
                            } else {
                                return <SubItem key={index} itemSubList={e} currentStep={currentStep} cartUpdate={cartUpdate} onAddToCart={onAddToCart}/>
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
}
