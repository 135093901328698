import '../component/css/landindcss.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';


import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageLoader } from "react-image-and-background-image-fade";
import { apiLink } from '../constants/DataPublic';
import { convertDateToISO, delay, getBookingSession, getCartSession, getCountCart, getDateSession, getErrorMessage, getEventSession, getVoucherSession, setDateSession, setEventSession, setEventStateSession } from '../component/Helpers/BaseHelper';
import { useMediaQuery } from 'react-responsive'
import 'react-toastify/dist/ReactToastify.css';
import SelectZoneBar from '../component/SelectZoneBar';
import ControlsZoomPinch from '../component/buttons/ControlsZoomPinch';

import { useGlobalState } from '../global'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ImageMap from '../component/Tableplan/ImageMap';
import Loading from '../component/Loading';
import { GetPackageByEvent } from '../constants/BaseApi';
import { useToast } from '../component/Helpers/ToastContext';
import { useLocation, useNavigate } from 'react-router-dom';
import PackageCard from '../component/Tableplan/PackageCard';
import { useAlert } from '../component/Helpers/SwalAlertContext';
import SorasoRefresh from '../component/img/soraso_refresh_79.svg';
import AlertModal from '../component/modal/AlertModal';
import { BlockTableModel, GetTablePlanLookupModel } from '../Models/TableBookModel';
import { UnBlocktable, getEventAndZoneLookup, getEventDate, getPackageByEvent, getTablePlanLookup } from '../Services/TableBookService';

//Component
const TablePlanPage = (props) => {
  const { language } = props;
  const { state } = useLocation();
  const [hardLink1, setHardlink1] = useState({});
  const [packageTicket, setPackageTicket] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);

  const { addAlert } = useAlert();
  const { showToast } = useToast();
  const [showAlert, setShowAlert] = useState(false);
  const [alertCallback, setAlertCallback] = useState({});
  const { t } = useTranslation();

  // Date 
  const [startDate, setStartDate] = useState();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Event
  let eventAndZone = getEventSession();

  const [eventDateArray, setEventDateArray] = useState([]);

  // Zone DropDown State

  const [tablePlan, setTablePlan] = useState();
  const [outlet, setOutlet] = useState();
  const [zone, setZone] = useState([]);
  const [selectedZone, setSelectedZone] = useState({
    id: "",
    value: null
  });


  // Event State
  const [eventItems, setEventItems] = useState(null);

  const [activeEvent, setActiveEvent] = useState({ id: 0, value: "" },);
  const [eventDetails, setEventDetails] = useState();

  useEffect(() => {
  }, [activeEvent])

  const [openEventModal, setOpenEventModal] = useState(false);

  const onShowEventModal = () => {
    setOpenEventModal(true);
  }


  // const onEventSelected = (event) => {
  //   let currentEvent = JSON.parse(sessionStorage.getItem('CurrentEvent'));
  //   onCloseEventModal();
  //   openConfirmationModal()
  //     .then(async (confirmed) => {
  //       if (confirmed) {
  //         currentEvent.selectEvent = event;
  //         await CallUnblockAllTable();
  //         setShowAlert(false);
  //         setActiveEvent(event);
  //         sessionStorage.removeItem('BookingInfo');
  //         sessionStorage.removeItem('MyCart');
  //         sessionStorage.setItem('CurrentEvent', JSON.stringify(currentEvent));
  //         window.location.href = '/';
  //       } else {
  //         setShowAlert(false);
  //         console.log("Cancle")
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle errors or user cancelation
  //       onCloseEventModal();
  //       console.error(error);
  //     });
  // }

  // Package State
  const [packageEvent, setPackageEvents] = useState();

  // Table
  const [selectedTable, setSelectedTable] = useState([]);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const navigate = useNavigate();

  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (eventAndZone === null) window.location.href = '/';
    var _tempdate = getDateSession();
    if (_tempdate != null && _tempdate != "") {
      _tempdate += 'T00:00:00';
      setStartDate(new Date(_tempdate));
    }
    getEventCalendar();

    if (myCart !== null && myCart.length > 0) {
      let table = [];
      myCart.map((e) => table.push(e.TableNo));
      setSelectedTable(table);
    }
  }, [])

  useEffect(() => {
    if (cart.length > 0) sessionStorage.setItem('MyCart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    // getPackageTicket();
    // getAllPackage();
    if (startDate != null, outlet != null) {
      getEventByDate(convertDateToISO(startDate), "");
    }

  }, [language]);

  useEffect(() => {
    if (selectedZone != null && outlet) {
      getTablePlan();
      if (myCart == null || myCart.length < 1) {
        setSelectedTable([])
      }
    }
  }, [selectedZone])

  useEffect(() => {
    // checkDateChange();
    if (startDate !== undefined && startDate !== null) {
      let date = new Date(startDate);
      getEventByDate(convertDateToISO(date), "");
      setDateSession(convertDateToISO(date));
    }
  }, [startDate])

  useEffect(() => {
    // console.log("eventAndZone if ", eventAndZone)
    if (activeEvent !== null && eventItems !== null && eventAndZone !== null) {
      const eventDetail = eventItems.find((item) => item.CampaignCode === eventAndZone.selectEvent.id);

      setEventDetails(eventDetail);
    }
  }, [activeEvent, eventAndZone])

  const transformComponentRef = useRef();

  const getEventCalendar = async () => {
    setLoading(true);
    await delay(1000);
    try {
      await getEventDate('en')
        .then(data => {
          // Handle the response data
          const date = data.Data.map((item) => new Date(item.EventDate));
          setEventDateArray(date);
          setLoading(false);
        })
        .catch(error => {
          // Handle errors
          console.error('Error from GetEventDate:', error);
          throw error;
        });

    } catch (error) {
      setLoading(false);
      console.log(error);
      showToast(error.message, 'error');
    }
  }

  const getEventByDate = async (date, eventCode) => {
    // console.log("getEventByDate", date)
    eventAndZone = getEventSession();
    if (eventCode === null) { eventCode = "" }

    let params = {
      eventDate: date,
    };

    if (eventCode) {
      params.eventCode = eventCode;
    } else {
      if (eventAndZone) {
        params.eventCode = eventAndZone.selectEvent.id;
      }
    }
    // console.log("params", params)
    try {
      await getEventAndZoneLookup(params, language ?? 'th')
        .then(async (response) => {
          if (response.Status === "Ok") {
            const data = response.Data;
            var zonesData = [];
            data.Zones.map((item) => {
              zonesData.push({ id: item.ZoneCode, value: item.ZoneName });
            });
            setZone(zonesData);
            setEventItems(data.Events)
            setOutlet({
              OutletCode: data.OutletCode,
              OutletName: data.OutletName
            })
            if (zonesData.length > 0) {
              if (eventAndZone != null) {
                let zoneName = zonesData.find((item) => item.id === eventAndZone.selectZone);
                let eventName = eventAndZone.selectEvent.value;
                data.Events.forEach((event) => {
                  // console.log(event.CampaignCode === eventAndZone.selectEvent.id)
                  if (event.CampaignCode === eventAndZone.selectEvent.id) eventName = event.CampaignName;
                });

                // console.log(eventName[0])

                eventAndZone.selectEvent.value = eventName;
                setEventSession(eventAndZone);

                setActiveEvent({ id: eventAndZone.selectEvent.id, value: eventName });
                setSelectedZone(zoneName);
                // console.log("setSelectedZone if", zoneName)
                console.log("eventAndZone if", { id: eventAndZone.selectEvent.id, value: eventName })
                return;
              }

              setSelectedZone(zonesData[0].value);
              // console.log("setSelectedZone else", zonesData[0].value)
            }
          } else {
            throw (getErrorMessage(response.Errors));
          }
        }).catch((error) => {
          throw error;
        });
    } catch (error) {
      console.log("error", error)
      setZone([]);
      setSelectedZone(null);
      setEventItems(null);
      setActiveEvent("");
      setLoading(false);
      console.log(error);
      showToast(error.message, 'error');
    }
  }

  const getTablePlan = async () => {
    // console.log("getTablePlan", getTablePlan)
    const outletCode = outlet.OutletCode;
    const zoneCode = selectedZone.id;
    let date = getDateSession();

    setLoading(true);

    const payload = new GetTablePlanLookupModel(outletCode, activeEvent.id, zoneCode, date);

    try {
      await getTablePlanLookup(payload, language ?? 'EN').then(async (response) => {
        if (response.Success) {
          const data = response.Result;
          setTablePlan(data);
          const myCart = getCartSession();
          if (myCart !== null) {
            props.getCartItem(getCountCart(myCart));
          }
          await delay(1000);
          await getPackage(activeEvent.id, eventAndZone == null ? eventAndZone.selectZone : selectedZone.id);
        } else {
          throw (getErrorMessage(response.Errors));
        }
      }).catch((error) => {
        throw error;
      })
    } catch (error) {
      setLoading(false);
      console.log(error);
      setLoading(false);
      showToast(error.message, 'error');
    }
  }

  const getPackage = async (eventCode, zoneCode) => {
    const params = {
      'eventCode': eventCode,
      'zoneCode': zoneCode
    };

    try {
      await getPackageByEvent(params, language ?? 'EN')
        .then((response) => {
          if (response.Status === "Ok") {
            const data = response.Data;
            setPackageEvents(data[0].Packages)
            setLoading(false);
          } else {
            throw (response.Errors === undefined ? "Error: " + GetPackageByEvent : getErrorMessage(response.Errors));
          }
        })
        .catch((error) => {
          throw error;
        })
    } catch (error) {
      setLoading(false);
      console.log(error);
      showToast(error.message, 'error');
    }
  }

  const onSelectTable = async (e) => {
    // console.log("onSelectTable", e)
    let filter = selectedTable.find((item) => item === e);
    const voucherData = getVoucherSession();
    if (voucherData && selectedTable.length > 0) {
      if (filter) return
      addAlert(t('select_table_warn'), 'warning');
      return;
    }

    if (!filter) {
      setSelectedTable(old => [...old, e])
    }
  }


  const NoEven = () => {
    // return <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}> <h2 className=''>{t('no_data')}</h2> </div>
    return <></>
  }


    ;

  return (
    <div className="page homepage">
      {/* Slide Banner */}
      {/* <div className="section section-video"> */}
      {/* <div className="wrapper slidervideo" > */}
      <ImageLoader src={'https://ohhsome.agency/buytickets/img/bg-slider.jpg'} transitionTime='0s'>
        {({ hasLoaded, shouldShowLoader, hasFailed }) => (
          <>
            {shouldShowLoader && !hasFailed && (
              <div className='banner-loading' style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                <h2 style={{ color: 'rgb(112 112 112)' }}> Loading ... </h2>
              </div>
            )}

            {hasFailed && (
              <div className='AwesomeoLoader__failed'>
                Loading Fail :(
              </div>
            )}

            {hasLoaded && (
              <>
                {isDesktopOrLaptop &&
                  <>
                    <div className='tableplan '>
                      <div className='tableplan-header d-flex justify-content-center'>
                        <SelectZoneBar
                          isTablePlanPage={true}
                          isMobile={false}
                          eventDateArray={eventDateArray}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          optionsZone={zone}
                          selectedValueZone={selectedZone}
                          // setSelectedValueZone={onSetSelectZone}
                          // onEventSelected={onEventSelected}
                          eventItems={eventItems}
                          errorTextZone={t('zone_all')}
                          onClickEvent={onShowEventModal}
                          selectedValueEvent={activeEvent}
                          scanMobileH={isTabletOrMobile ? 25 : 30}
                          // onRefreshPlan={(date, event) => { console.log("eventDAteeee", date + event); getEventByDate(convertDateToISO(startDate), event ?? null) }}

                          onRefreshPlan={(event) => {
                            setEventStateSession(event);
                            navigate("/");
                          }}
                          getCartItem={props.getCartItem}
                          setActiveEvent={setActiveEvent}
                          setSelectedZone={setSelectedZone}
                          setSelectedTable={setSelectedTable}
                        />
                      </div>
                      {tablePlan && packageEvent && tablePlan != null ? (
                        <div className='row p-3 w-100'>
                          <div className='col-xl-9 col-lg-12 col-md-6'>
                            <div className='zone-tablemap d-inline-block mb-2 gradient-background'>{selectedZone.value}</div>
                            <div className='tableplan-image text-center'>
                              <TransformWrapper
                                disablePadding={true}
                                initialScale={1}
                                initialPositionX={0}
                                initialPositionY={0}
                                ref={transformComponentRef}
                              >

                                {() => (
                                  <React.Fragment>
                                    <div className='content' style={{
                                      position: 'relative'
                                    }}>
                                      <TransformComponent wrapperStyle={{ maxWidth: '100%', display: 'block !important' }}>
                                        {/* <ScrollContainer hideScrollbars={false} style={{ borderRadius: '5px' }}> */}
                                        {/* <div className='content'> */}
                                        <ImageMap
                                          tablePlan={tablePlan}
                                          onSelectTable={onSelectTable}
                                          selectedTable={selectedTable}
                                        // onSelectZone={onSelectZone}
                                        />
                                        {/* </div> */}
                                        {/* </ScrollContainer> */}
                                      </TransformComponent>
                                      <ControlsZoomPinch />
                                    </div>
                                  </React.Fragment>
                                )}
                              </TransformWrapper>
                            </div>
                          </div>
                          <div className='col-xl-3 col-lg-12 col-md-6 p-0'>
                            {eventItems && eventItems.length > 0 && (
                              <div className='pkcard-right custom-scroll' style={{ minHeight: '40vh', maxHeight: '80vh', overflow: 'auto' }}>
                                {packageEvent && (
                                  <PackageCard
                                    packageEvent={packageEvent}
                                    selectedTable={selectedTable}
                                    setSelectedTable={setSelectedTable}
                                    event={eventDetails}
                                    startDate={startDate}
                                    getTablePlan={getTablePlan}
                                    tablePlan={tablePlan.Details}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : NoEven()}
                    </div>
                  </>
                }

                {isTabletOrMobile &&
                  <>
                    <div className='tableplan'>
                      <div className='tableplan-header'>
                        <SelectZoneBar
                          isMobile={true}
                          eventDateArray={eventDateArray}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          optionsZone={zone}
                          selectedValueZone={selectedZone}

                          errorTextZone={t('zone_all')}

                          selectedValueEvent={activeEvent}
                          getCartItem={props.getCartItem}
                          setActiveEvent={setActiveEvent}
                          setSelectedZone={setSelectedZone}
                          setSelectedTable={setSelectedTable}

                          eventItems={eventItems}
                          // onEventSelected={onEventSelected}
                          options={options}

                          datePickerMobileSize={'30vw'}
                          zoneMobileSize={'30vw'}
                          eventMobile={'30vw'}
                          scanMobileH={isTabletOrMobile ? 25 : 30}

                          // onRefreshPlan={(date, event) => getEventByDate(convertDateToISO(startDate), event ?? null)}
                          onRefreshPlan={(event) => {
                            setEventStateSession(event);
                            navigate("/");
                          }}
                        />
                      </div>
                      {tablePlan && packageEvent && tablePlan != null ? (
                        <div className='row p-3' style={{ margin: 'auto', padding: '10px' }}>
                          <div className='col-12 p-0'>
                            <div className='zone-tablemap d-inline-block mb-2 gradient-background'>{selectedZone.value}</div>
                            <div className='tableplan-image text-center'>
                              <TransformWrapper
                                disablePadding={true}
                                initialScale={1}
                                initialPositionX={0}
                                initialPositionY={0}
                                ref={transformComponentRef}
                              >

                                {() => (
                                  <React.Fragment>
                                    <div className='content' style={{
                                      position: 'relative'
                                    }}>

                                      <TransformComponent wrapperStyle={{ maxWidth: '100%', display: 'block !important' }}>
                                        <ImageMap
                                          tablePlan={tablePlan}
                                          onSelectTable={onSelectTable}
                                          selectedTable={selectedTable}
                                        />
                                      </TransformComponent>
                                      <ControlsZoomPinch />
                                    </div>
                                  </React.Fragment>
                                )}
                              </TransformWrapper>
                            </div>
                          </div>
                          <div className='col p-0'>
                            {eventItems && eventItems.length > 0 && (
                              <div className='pkcard-right custom-scroll' style={{ minHeight: '30vh', marginTop: '15px', overflow: 'auto' }}>
                                {packageEvent && (
                                  <PackageCard
                                    mobile={true}
                                    packageEvent={packageEvent}
                                    selectedTable={selectedTable}
                                    setSelectedTable={setSelectedTable}
                                    event={eventDetails}
                                    startDate={startDate}
                                    getTablePlan={getTablePlan}
                                    tablePlan={tablePlan.Details}
                                    getCartItem={props.getCartItem}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : NoEven()}
                    </div>
                  </>
                }
              </>
            )}
          </>
        )}
      </ImageLoader>


      <Loading loading={loading} />

      {showAlert && (
        <AlertModal
          openModal={showAlert}
          onCloseModal={alertCallback.cancel}
          onConfirm={alertCallback.confirm}
          isShowCancle
        >
          <div className='d-flex flex-column justify-content-around align-items-center' style={{ height: '100%' }}>
            <div className='mb-3'>
              <img src={SorasoRefresh} />
            </div>
            <div className='' style={{ fontWeight: 'bold' }}>
              {t('remove_alert_1')}
            </div>
            <div className='' style={{ fontWeight: 'bold' }}>
              {t('remove_alert_2')}
            </div>
          </div>
        </AlertModal>
      )}

    </div >
  );
};

export default TablePlanPage;
