import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { gapi, loadAuth2 } from 'gapi-script';

//Components
import GGLogin from '../component/GGLogin';
import FBLogin from '../component/FBLogin';
import StepBar from '../component/Ticket/StepBar';
import showTerm from '../customHook/showTerm';
import { Markup } from 'interweave';
import { FormSelect, Modal } from 'react-bootstrap';
import { ReactComponent as Close } from '../component/img/close.svg';
import { ReactComponent as Vector } from '../component/img/vector.svg';
import Button from 'react-bootstrap/Button';
import logo from '../component/img/soraso_logo.png';
import SorasoRefresh from '../component/img/refresh_79.svg';

import useLogin from '../customHook/useLogin';
import useRegister from '../customHook/useRegister';
import { baseLink, clientId, apiLink, coreApi } from '../constants/DataPublic';
import { useGlobalState } from '../global'
import { getCartSession } from '../component/Helpers/BaseHelper.jsx';
import { CheckUserExistsService, GuestLoginService, VisitorRegisterService, getCountryService } from '../Services/AccountService';
import { GuestForgotModel, UserLoginModel, VisitorRegisterModel } from '../Models/AccountModel';
import { hashStringWithSHA256 } from '../component/Helpers/EncryptHelper';
import { getPackageTicket } from '../Services/PackageService.jsx';
import SorasoButton from '../component/buttons/SorasoButton.jsx';
import { CheckUserExists } from '../constants/BaseApi.jsx';
import AlertModal from '../component/modal/AlertModal.jsx';
import SorasoSuccessModel from '../component/modal/SorasoSuccessModal.jsx';

const GuestCheckout = (props) => {
  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);
  const { language } = props;

  let { getUserCart } = useLogin({ lang: language });
  let { countryPhone, getCountryPhone } = useRegister({ language: language });
  let { open, subject, terms, agree, setAgree, onOpenModal, onCloseModal, getTerms, onConfirm } = showTerm();

  const [routePath, setRoutePath] = useGlobalState('route');
  const [packageCode, setPackageCode] = useState('');
  const [currentStep, setCurrentStep] = useState(5);
  const [itemGroup, setItemGroup] = useState([]);
  const [gMessage, setGMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [uMessage, setUMessage] = useState('');

  const [country, setCountry] = useState([]);

  const [guestInfo, setGuestInfo] = useState({
    fName: '',
    lName: '',
    idCardNo: '',
    email: '',
    confirmEmail: '',
    countryId: '',
    photo: '',
    telCode: '',
    phoneNo: '',
    userId: '',
    userCode: '',
    type: 'guest',
    isLogin: false,
    confirmationNo: '',
    channelCode: '',
    guestDone: false,
  });

  const [requireFname, setRequireFname] = useState('');
  const [requireLname, setRequireLname] = useState('');
  const [requireID, setRequireID] = useState('');
  const [requireEmail, setRequireEmail] = useState('');
  const [requireConfirmEmail, setRequireConfirmEmail] = useState('');
  const [requireTel, setRequireTel] = useState('');

  const [countryId, setCountryId] = useState(66);

  const navigate = useNavigate();

  // ภาษา
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (myCart !== null && myCart.length > 0) {
      getPackageCard();
      getCountryPhone();
      getCountry();
      guestInfo.telCode = 66;
    } else navigate('/');
  }, []);

  useEffect(() => {
    guestInfo.telCode = countryId;
    guestInfo.countryId = countryId;
  }, [countryId]);

  const getPackageCard = async () => {
    await getPackageTicket(language)
      .then((res) => {
        let packageTypeList = res['Result']['PackageSteps'];
        packageTypeList = packageTypeList.sort((a, b) => (a.StepId > b.StepId ? 1 : -1));
        setItemGroup(packageTypeList);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/Ticket/ ', error)
      });
  };

  const getCountry = async () => {
    await getCountryService(language)
      .then((res) => {
        setCountry(res['Result']);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Account/country ', error)
      });
  }

  const confirmGuest = (e) => {
    e.preventDefault();

    if (guestInfo.fName === '') {
      setRequireFname('warn_firstname');
    } else setRequireFname('');

    if (guestInfo.lName === '') {
      setRequireLname('warn_lastname');
    } else setRequireLname('');

    // if (guestInfo.idCardNo === '') {
    //   setRequireID('warn_id');
    // } else setRequireID('');

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const validMail = regex.test(guestInfo.email);
    if (guestInfo.email === '') {
      setRequireEmail('warn_email');
    } else if (!validMail) {
      setRequireEmail('invalid_email');
    } else setRequireEmail('');

    if (guestInfo.confirmEmail === '') {
      setRequireConfirmEmail('warn_confirm_email');
    } else {
      if (guestInfo.email === guestInfo.confirmEmail) {
        setRequireConfirmEmail('');
      } else {
        setRequireConfirmEmail('email did not match');
      }
    }



    if (guestInfo.phoneNo === '') {
      setRequireTel('warn_phone');
    } else setRequireTel('');

    // && guestInfo.idCardNo
    if (guestInfo.fName != '' && guestInfo.lName != '' != '' && guestInfo.email != '' && guestInfo.confirmEmail != '' && validMail && guestInfo.phoneNo != '') {
      if (guestInfo.email === guestInfo.confirmEmail) {
        checkUserExist();
      }
    }
  };


  async function visitorRegister() {
    const payload = new VisitorRegisterModel(
      guestInfo.email,
      guestInfo.fName,
      guestInfo.lName,
      guestInfo.phoneNo,
      guestInfo.idCardNo,
      guestInfo.countryId,
    )


    await VisitorRegisterService(payload, language)
      .then(function (data) {
        const items = data;
        if (items['Success'] === true) {
          guestInfo.userCode = items['Result']['User'].UserCode;
          guestInfo.userId = items['Result']['User'].UserId;
          guestInfo.channelCode = items['Result']['ChannelCode'];
          sessionStorage.setItem('UserLogin', JSON.stringify(guestInfo));
          myCart = getCartSession();
          // navigate('/ticket/' + myCart[0].Package[0].PackageCode + '/4');
          window.location.href = '/package/' + myCart[0].Package[0].PackageCode + '/4';
        } else {
          setGMessage(items['Message']);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        // alert('Error: API /afcl/Account/visitor/register ', error)
      });
  }

  const cursorFocus = function (elem) {
    var x = window.scrollX,
      y = window.scrollY;
    setTimeout(function () {
      window.scrollTo(x, y);
    }, 100);
  };

  const checkpassword = async () => {
    console.log('sigin account');
    // if (email === '' || password === '') {
    //   setUMessage('warn_email_pass');
    //   if (email === '') {
    //     cursorFocus(document.getElementById('signinEmail'));
    //   } else if (password === '') {
    //     cursorFocus(document.getElementById('signinPass'));
    //   }
    // } else {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json', 'accept-language': language },
    //   body: JSON.stringify({
    //     Email: email,
    //     Password: password,
    //   }),
    // };
    // console.log(requestOptions);
    // await fetch(coreApi + '/Ecommerce/Account/guest/login', requestOptions)
    //   .then((response) => {
    //     return response.json();
    //   })
    const payload = new UserLoginModel(email, hashStringWithSHA256(password));

    await GuestLoginService(payload, language)
      .then((data) => {
        const items = data;
        console.log(items);
        if (items['Success']) {
          let user = {
            fName: items['Result']['User'].FirstName,
            lName: items['Result']['User'].LastName,
            email: items['Result']['User'].Email,
            userId: items['Result']['User'].UserId,
            userCode: items['Result']['User'].UserCode,
            photo: items['Result']['User'].UserPicture,
            isLogin: true,
            channelCode: items['Result']['ChannelCode'],
            confirmationNo: '',
            idCardNo: '',
            phoneNo: '',
            type: 'user',
            guestDone: false,
          };
          sessionStorage.setItem('UserLogin', JSON.stringify(user));
          // getUserCart(items['Result']['User'].UserCode, language).then(function () {
          //   // navigate('/ticket/' + myCart[0].PackageCode + '/5');
          //   navigate('/');
          // });
          navigate('/package/' + myCart[0].PackageCode + '/4')
        } else {
          setUMessage(items['Message']);
          cursorFocus(document.getElementById('signinEmail'));
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        alert('Error: API /afcl/Account/checkpassword ', error)
      });
    // }
  };

  async function checkUserExist() {
    const payload = new GuestForgotModel(guestInfo.email);
    await CheckUserExistsService(payload, language)
      .then((data) => {
        const items = data;
        if (items['Success']) {
          console.log('Success = true');
          visitorRegister();
        } else {
          console.log('Success = false');
          setShowModal(true);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        alert('Error: API ' + CheckUserExists, error)
      });
  }

  const updateInfo = () => {
    visitorRegister();
    setShowModal(false);
  };

  // Login

  const [ggUser, setGGUser] = useState({});
  const [fbUser, setFBUser] = useState({});
  const [needRegist, setNeedRegist] = useState(false);

  const getGGUser = (ggUser) => {
    setGGUser(ggUser);
    props.getGGUser(ggUser);
  };

  const getFBUser = (fbUser) => {
    setFBUser(fbUser);
    props.getFBUser(fbUser);
  };

  const getNeedRegist = (needRegist) => {
    setNeedRegist(needRegist);
  };

  useEffect(() => {
    if (needRegist) {
      navigate('/signup');
    }
  }, [needRegist]);

  // Login with Google
  useEffect(async () => {
    await loadAuth2(gapi, clientId, "");
    // console.log("gapi", gapi)
    // function start() {
    //   gapi.client.init({
    //     clientId: clientId,
    //     scope: '',
    //   });
    // }
    // gapi.load('client:auth2', start);
  }, []);

  useEffect(() => {
    if (currentStep != 5 && myCart.length > 0) {
      navigate('/package/' + myCart[0].PackageCode + '/' + currentStep);
    }
  }, [currentStep]);

  const setUserCode = (userCode) => {
    props.setUserCode(userCode);
  };

  const onNumerPWKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && event.key != 'Backspace') {
      event.preventDefault();
    }
  };

  //keydown login
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      passwordInputRef.current.focus();
    }
  };

  const handleKeyDownLogin = (e) => {
    if (e.keyCode === 13) {
      checkpassword();
    }
  };

  return (
    <div className="page page-steps" style={{ minHeight: 'calc(100vh - 183px)' }}>
      {/* {itemGroup.length > 0 && (
        <header className="sticky-top">
          <StepBar itemGroup={itemGroup} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
        </header>
      )} */}
      <div className="main ">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 py-5 px-1" style={{ margin: '0 auto' }}>
              <div className="row" style={{ justifyContent: 'center' }}>
                {/* Start form lefr */}

                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12">
                  <div className="border-right-separator py-4 box-body box-border" style={{ borderRadius: '12px', paddingLeft: '30px', paddingRight: '30px' }}>
                    <h2 className="font-30 text-uppercase mb-1 lh-1">{t('guest_checkout')}</h2>
                    <p className="color-grey mb-4">{t('proceed_checkout')}</p>

                    <form>
                      <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="exampleFormControlInput1" className="">
                          {t('firstname')} *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          minLength="3"
                          name="booking_guest_fname"
                          placeholder={t('first_name_here')}
                          onChange={(e) => (guestInfo.fName = e.target.value)}
                          style={{ borderRadius: "10px" }} />
                        <span className="text-danger">{t(requireFname)}</span>
                      </div>

                      <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="exampleFormControlInput1" className="">
                          {t('lastname')}*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          minLength="3"
                          name="booking_guest_lname"
                          placeholder={t('last_name_here')}
                          onChange={(e) => (guestInfo.lName = e.target.value)}
                          style={{ borderRadius: "10px" }} />
                        <span className="text-danger">{t(requireLname)}</span>
                      </div>

                      {/* <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                        <input
                          maxLength="20"
                          type="text"
                          id="idCard"
                          className="form-control"
                          pattern="[0-9]*"
                          title="ID Should contain numbers only"
                          name="booking_guest_identifier"
                          placeholder={t('id_number') + '*'}
                          onChange={(e) => (guestInfo.idCardNo = e.target.value)}
                        />
                        <span className="text-danger">{t(requireID)}</span>
                      </div> */}

                      <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="exampleFormControlInput1" className="form-label ">
                          {' '}
                          {t('email')}*{' '}
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          name="booking_guest_email"
                          placeholder={t('email_here')}
                          onChange={(e) => (guestInfo.email = e.target.value)}
                          style={{ borderRadius: "10px" }} />
                        <span className="text-danger">{t(requireEmail)}</span>
                      </div>

                      <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="exampleFormControlInput1" className="form-label ">
                          {' '}
                          {t('confirm_email')}*{' '}
                        </label>
                        <input
                          type="email"
                          id="confirmEmail"
                          className="form-control"
                          name="booking_guest_confirm_email"
                          placeholder={t('email_here')}
                          onChange={(e) => (guestInfo.confirmEmail = e.target.value)}
                          style={{ borderRadius: "10px" }} />
                        <span className="text-danger">{t(requireConfirmEmail)}</span>
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="exampleFormControlSelect1" className="">
                          {t('country_of_residence')}
                        </label>
                        <select className="form-select" id="country" style={{ borderRadius: "10px" }} onChange={(e) => (setCountryId(e.target.value))}>
                          {/* <option key="0" value="0">
                            -
                          </option> */}
                          {country
                            .map((e, key) => {
                              return (
                                <option key={e.CountryId} value={e.CountryId} selected={e.CountryId == countryId}>
                                  {e.CountryName}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="exampleFormControlInput1" className="">
                          {t('telephone')}*
                        </label>
                        <div className="row">
                          <div className="col-3 pb-3 pb-lg-0 reg-phoneselect">
                            <select className="form-control" id="telCode" defaultValue={guestInfo.telCode} onChange={(e) => (setCountryId(e.target.value))} style={{ borderRadius: "10px" }}>
                              <option key="0" value="0">
                                -
                              </option>
                              {countryPhone.map((e, key) => {
                                return (
                                  <option key={e.CountryId} value={e.CountryId} selected={e.CountryId == countryId}>
                                    {e.PhoneCode}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-9 reg-phoneno">
                            <input
                              type="tel"
                              id="phoneNo"
                              className="form-control"
                              title="Mobile Number should contain numbers only"
                              name="booking_guest_phone"
                              style={{ borderRadius: "10px" }}
                              placeholder={t('phone_number') + '*'}
                              maxLength="10"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => (guestInfo.phoneNo = e.target.value)}
                            />
                          </div>
                          <span className="text-danger">{t(requireTel)}</span>
                        </div>
                        <span className="text-danger">{gMessage}</span>
                      </div>

                      <div className="row col-xl-12 col-lg-12 col-md-12 col-sm-12 align-items-center">
                        <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 pb-3 pb-lg-0">
                          <p className="color-grey font-10 lh-1 mb-1">{'*' + t('indicate_required')}</p>
                          {/* <p className="color-grey font-10 lh-1 mb-0">{t('real_email')}</p> */}
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                        {/* <button type="button" className="btn btn-link btn-lb text-capitalize gradient-background rounded-pill" onClick={confirmGuest}>
                          {t('continue_guest')}
                        </button> */}
                        <SorasoButton
                          onClick={confirmGuest}
                          style={'primary'}
                          text={t('continue_guest')}
                        />
                      </div>


                    </form>
                  </div>
                </div>

                {/* End form lefr */}

                <div className="d-block d-lg-none">
                  <div className="mb-3 row align-items-center">
                    <div className="col-lg-6 relative">
                      <hr />
                      <span className="orbig bg-white">or</span>
                    </div>
                  </div>
                </div>

                {/* Start form right */}
                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12">
                  <div className="align-items-center py-4 box-body box-border" style={{ borderRadius: '12px', paddingLeft: '50px', paddingRight: '50px' }}>
                    <div className="ms-auto returncustomer">
                      <h2 className="font-30 text-uppercase mb-4 lh-1">{t('sign_in_to_your_account')}</h2>
                      {/* <p className="color-grey mb-4">{t('welcome_back')}</p> */}
                      <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="exampleFormControlInput1" className="form-label ">
                          {' '}
                          {t('email')}{' '}
                        </label>
                        <input
                          id="signinEmail"
                          type="email"
                          className="form-control"
                          name="email" placeholder={t('email_here')}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={handleKeyDown}
                          ref={emailInputRef}
                          style={{ borderRadius: "10px" }} />
                      </div>

                      <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <label htmlFor="exampleFormControlInput1" className="form-label ">
                          {' '}
                          {t('password')}{' '}
                        </label>
                        <input
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          style={{ borderRadius: "10px" }}
                          placeholder={t('password')}
                          maxLength={14}
                          ref={passwordInputRef}
                          onKeyDown={handleKeyDownLogin}
                        />
                        <span className="text-danger">{t(uMessage)}</span>
                      </div>

                      <div className="mb-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 align-items-center">
                        <a href="#">
                          {/* <button type="button" className="mb-3 btn btn-link btn-lb text-capitalize gradient-background rounded-pill" onClick={checkpassword}>
                            {t('signin')}
                          </button> */}
                          <SorasoButton
                            onClick={checkpassword}
                            style={'primary'}
                            text={t('signin')}
                          />
                        </a>
                        <div style={{ display: 'flex' }}>
                          <p className="mb-0" style={{ width: '50%' }}>
                            <a href={baseLink + routePath + '/forget'} className="font-10 weight-600 text-79 text-fotgot-boarder" style={{ textDecoration: 'underline' }}>
                              {t('forgot_password')}
                            </a>
                          </p>
                          <p className="mb-0" style={{ width: '50%', textAlign: 'right' }} >
                            <a href='#' className="font-10 weight-600 text-79 text-fotgot-boarder" onClick={() => getTerms(language)} style={{ textDecoration: 'underline' }}>
                              {t('account_create')}
                            </a>
                          </p>
                          <Modal show={open} className="col-12 signinpage popupterm">
                            <div style={{ textAlign: 'right' }}>
                              <Close className="popup-close" onClick={onCloseModal} />
                            </div>
                            <div className="logosignin">
                              <img src={logo} id="logosignin" />
                            </div>

                            <h5 className="text-center mb-5">{subject}</h5>
                            <Markup content={" " + terms} />
                            <div className="text-center mb-5">
                              <input type="checkbox" className="form-check-input" id="vehicle1" name="vehicle1" onChange={(e) => setAgree(e.target.checked)} />
                              <label hntmlFor="vehicle1" className="form-check-label" style={{ padding: '.2rem 0 0 1rem' }}>
                                <u>{t('i_agree')}</u>
                              </label>
                            </div>
                            <div className="col-5" style={{ margin: '0 auto', paddingTop: '1em' }}>
                              <Button type="button" className="btn btn-link btn-db text-capitalize" onClick={onConfirm} disabled={!agree}>
                                {t('confirm')}
                              </Button>
                            </div>
                          </Modal>
                        </div>

                      </div>

                      <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 row align-items-center">
                        <div className="relative">
                          <hr />
                          <span className="or bg-white">{t('or_use')}</span>
                        </div>
                      </div>

                      <div className="mb-3 row g-3 align-items-center">
                        <div className='col-6 d-flex justify-content-center'>
                          <div className="gustloginFB">
                            <FBLogin language={language} fbtext={t('facebook')} getFBUser={getFBUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode} />
                          </div>
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                          <div className="gustloginGG">
                            <GGLogin language={language} ggtext={t('google')}
                              getGGUser={getGGUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode} />{/*ggtext={t('google')}*/}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="d-none d-lg-block">
                    <hr />
                    <h6 className="font-20 weight-800 mb-0">{t('need_help')}</h6>
                    <p className="font-14 weight-600">
                      {t('send_us')}
                      {/* <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&online@columbiapicturesaquaverse.com.com&body=I would like learn more about check out . Could you please send me more details at the earliest?"> */}
                      {/* <a href="mailto:online@columbiapicturesaquaverse.com&subject=test subject&body=Dear Sir/Madam %0A%0A I would like learn more about check out . Could you please send me more details at the earliest?" target="_blank"> */}
                      <a className='text-color' href="mailto:seen.samui@seenbeachclubsamui.com?subject=Guest checkout inquiry letter&body=Dear Sir/Madam %0A%0A I would like learn more about check out . Could you please send me more details at the earliest? %0D%0A Thank you for your prompt response. %0A%0A Yours sincerely," target="_blank">
                        {/* <a href="mailto:online@columbiapicturesaquaverse.com"> */}
                        {t('email')}
                      </a>
                      &nbsp;{t('or_callus')}
                      <a className='text-color' href="tel:+6677956800">+66(0)77956800</a>
                    </p>
                  </div>
                </div>
                {/* End form right */}
                <SorasoSuccessModel
                  showModal={showModal}
                  onCloseModal={() => setShowModal(false)}
                  routePath={routePath}
                  path={''}
                  textDetail={'alert_update_info'}
                  pic={SorasoRefresh}
                  isBackToSignIn={false}
                  isBackToHome={false}
                  showButton={true}
                  onConfirm={() => updateInfo()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GuestCheckout;
