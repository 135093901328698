import { useState } from "react";
import { useNavigate } from 'react-router-dom';

import { apiLink, coreApi } from '../constants/DataPublic'
import { getTermsService } from "../Services/AccountService";

const ShowTerm = () => {

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const [agree, setAgree] = useState(false)

  const onOpenModal = () => {
    setOpen(true)
  };

  const onCloseModal = () => {
    setOpen(false)
  };

  const onConfirm = () => {
    setOpen(false)
    navigate('/signup')
  }

  ////////////////////
  const [subject, setSubject] = useState("");
  const [terms, setTerms] = useState("");

  async function getTerms(lang) {
    await getTermsService(lang)
      .then((res) => {
        setSubject(res["Result"].EmailTemplateSubject);
        setTerms(res["Result"].EmailTemplateBody);
        onOpenModal();
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Account/agreement ', error)
      });
  }

  return { open, subject, terms, agree, setAgree, onOpenModal, onCloseModal, getTerms, onConfirm }
}

export default ShowTerm