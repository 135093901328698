import React from "react";

import iconPinVC from '../img/pin_vc.png';
import iconPinCR from '../img/pin_cr.png';
import iconPinOO from '../img/pin_OO.png';
import iconPinRR from '../img/pin_rr.png';
import iconPinOC from '../img/pin_oc.png';
import iconPinBL from '../img/pin_bl.png';
import Icon_AddToCart from '../img/icon_addtocart.png';
import Icon_Available from '../img/icon_table.png';
import Icon_Occupied from '../img/icon_occupied.png';
import Icon_000 from '../img/icon_000.png';
import Icon_BL from '../img/icon_000.png';

const iconMap = {
    VC: iconPinVC,
    CRT: iconPinCR,
    OO: iconPinOO,
    RR: iconPinRR,
    OC: iconPinOC,
    BL: iconPinBL
};

// const statusIconMap = {
//     VC: Icon_Available,
//     CRT: Icon_AddToCart,
//     OO: Icon_000,
//     RR: Icon_Available,
//     OC: Icon_Occupied,
// };

const Table = (props) => {
    const { isaddCart, status } = props;

    const pinImage = iconMap[status] || iconPinOC;
    // const statusIcon = statusIconMap[status] || Icon_Occupied;

    return (
        <div className="text-center" style={{ width: '100%', height: '100%', display: 'flex' }}>
            <img src={pinImage} style={{ width: '100%', height: '100%' }} alt="Table Pin" />
            {/* <div className="table-icon text-center">
                <img className="image-overlay" src={statusIcon} width={20} height={10} alt="Table Status Icon" />
            </div> */}
        </div>
    );
};

export default Table;
