const dateFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
};

export const getEventStart = (event) => {
    var formatDate = new Date(event.EventDate).toLocaleDateString("en-GB", dateFormatOptions);
    var startTime = event.EventStartTime.split(":").slice(0, 2).join(":");
    var endTime = event.EventEndTime.split(":").slice(0, 2).join(":");
    var formatted = formatDate + " " + startTime + " - " + endTime;
    return (formatted);
}

export const checkEventDisable = (event) => {
    const eventDate = new Date(event.EventDate.split('T')[0]); // Parse event date
    const currentDate = new Date(); // Get current date

    const eventDateWithoutTime = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
    const currentDateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    return eventDateWithoutTime < currentDateWithoutTime;
}
